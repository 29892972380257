import React, {Component, Fragment} from 'react';
import NewLabel from './images/new-label.svg'

import './OfferSelector.scss';

export default class OfferSelector extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	select = (offer, event) => {
		event.preventDefault();
		this.props.onSelect(offer);
	};

	render() {
		let {current, choices} = this.props;

		const tickets = choices.filter( c => !c.is_promo && !!c.bookingsMax);
		const subs = choices.filter( c => !c.is_promo && !c.bookingsMax && !c.student_only);
		const students = choices.filter( c => !c.is_promo && !c.bookingsMax && !!c.student_only);
		const promos = choices.filter( c => c.is_promo );

		const build = [];

		for(let i=0;i<Math.max(tickets.length, subs.length, students.length, promos.length); i++) {
			const newLine = {promo:null, ticket:null, sub:null, student:null};
			build.push(newLine);
			if( i < promos.length ){
				newLine.promo = promos[i];
			} else if( promos.length ) {
				newLine.promo = true
			}
			if( i < students.length){
				newLine.student = students[i];
			} else if( students.length ) {
				newLine.student = true
			}
			if( i < tickets.length) {
				newLine.ticket=tickets[i];
			} else if( tickets.length ) {
				newLine.ticket = true
			} 
			if( i < subs.length) {
				newLine.sub = subs[i]
			} else if( subs.length ) {
				newLine.sub = true
			}
		}
		if( current ) {
			return <OfferPreview onSelect={ this.props.onSelect && this.select.bind(this, null)} offer={current} current={true} promo={this.props.promo} applyPromo={this.props.applyPromo}/>
		}

		const makeList = ({key, promo, ticket, sub, student}) => {
			let onSelectSub = sub && this.props.onSelect && this.select.bind(this, sub);
			let onSelectTicket = ticket && this.props.onSelect && this.select.bind(this, ticket);
			let onSelectedStudent = student && this.props.onSelect && this.select.bind(this, student);
			let onSelectedPromo = promo && this.props.onSelect && this.select.bind(this, promo);
			return (
				<React.Fragment key={key}>
					{(!promo)
						? null
						: ( promo === true ? <div/> : <OfferPreview style={{margin:'0.8rem'}}onSelect={onSelectedPromo} offer={promo} promo={this.props.promo} applyPromo={this.props.applyPromo}/>)
					}
					{(!sub)
						? null
						: ( sub === true ? <div/> : <OfferPreview style={{margin:'0.8rem'}}onSelect={onSelectSub} offer={sub} promo={this.props.promo} applyPromo={this.props.applyPromo}/>)
					}
					{(!ticket)
						? null
						: ( ticket === true ? <div/> : <OfferPreview style={{margin:'0.8rem'}}onSelect={onSelectTicket} offer={ticket} promo={this.props.promo} applyPromo={this.props.applyPromo}/>)
					}
					{(!student)
						? null
						: ( student === true ? <div/> : <OfferPreview style={{margin:'0.8rem'}}onSelect={onSelectedStudent} offer={student} promo={this.props.promo} applyPromo={this.props.applyPromo}/>)
					}
					<div/>
				</React.Fragment>
			)
		}

		let nb = 0;
		if( promos.length ) {
			nb++;
		}
		if( subs.length ) {
			nb++;
		}
		if( tickets.length ) {
			nb++;
		}
		if( students.length ) {
			nb++;
		}
		return (
				<>
				<h5 style={{margin:'auto', padding:'2rem'}}>Nos formules vous donnent accès à tous nos cours collectifs & nos cours en live.</h5>
				<div className='offerTabParent'>
					<div className={`offerTab grid-${nb}`}>
						{!!promos.length && (
							<h1 style={{backgroundColor: ' #EECA62', position:'relative'}}>
								<img src ={NewLabel} alt='new' className='newLabel'/>
								La promo du moment
							</h1>
						)}
						{!!subs.length && <h1 style={{backgroundColor: '#60AD80'}}>Les abonnements</h1>}
						{!!tickets.length && <h1 style={{backgroundColor:'#63EDA2'}}>Les cartes</h1>}
						{!!students.length && <h1 style={{backgroundColor: '#60AD80'}}>Etudiants</h1>}
						<div/>
						{build.map( (e, i) => { 
							return makeList({key:i, ...e});
						})}
					</div>
				</div>
				</>
		);
	}
}

class OfferPreview extends Component {

	render() {
		let {offer, current, promo, divStyle={}} = this.props;
		let price = this.props.applyPromo(offer.price, promo);

		let duration = '';
		if(promo && !!promo.duration){
			if (duration === 1) duration = "le premier mois";
			else duration = `les ${promo.duration} premiers mois`;
		}
		const style = {};

		//XXX Special request from urban
		if( offer.id === 111 ) {
			style.background = '#C74C36';
			style.borderColor = '#C74C36';
		}

		return (
			<article className={"OfferPreview" + (current ? " current" : "")} style={divStyle}>
				<header>
					<p style={{display:'none'}}># {offer.id}</p>
					{current &&
						<span>actuelle</span>
					}
					<h2>{offer.name}</h2>
					{!!offer.commitment && <em style={{color:"black"}}>Engagement {offer.commitment} mois</em>}
					{!!offer.bookingsMax && !!offer.durationInMonth && <em style={{color:"black"}}>Validité {offer.durationInMonth} mois</em>}
				</header>
				<section>
					<p>
						{price.initial !== price.after ?
							<Fragment>
								<b>
									{price.after/100}€
									{offer.monthly &&
										<span>/mois</span>
									}
								</b>
								<span>au lieu de {price.initial/100}€</span>
								<span>{duration}</span>
							</Fragment>
							:
							<b>
								{price.initial/100}€
								{offer.monthly &&
								<span>/mois</span>
								}
							</b>
						}
					</p>
					{this.props.onSelect && <button onClick={this.props.onSelect} className={current ? "cancel-renew-button" : ""} style={style}>
						{current ? "Arrêter le renouvellement" : "Sélectionner"}
					</button>}
				</section>
			</article>
		);
	}

}
