import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import MsgPage from "./utils/MsgPage";
import Planning from "./Planning";
import Img from './utils/Img';

import './Coach.scss';

import appIcon from './images/app-logo.svg';
import coachHero from './images/coach-hero.jpg';

class Coach extends React.Component {

	static contextTypes = {
		API: PropTypes.object,
		repo : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: "LOADING"
		};
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		this.setState({step : "LOADING"});
		this.context.API.coachGet(this.props.match.params.coachId)
		.then( coach => {
			coach = this.context.repo.put(coach);
			if(coach.length)
				this.setState({ step : "OK", coach:coach[0]});
			else
				this.setState({ step : "OK", coach:null});
		})
		.catch( e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {

		if (this.state.step === "LOADING") {
			return (
				<MsgPage type="LOADER" title="Chargement des données..."/>
			);
		}

		if (this.state.step === "ERROR") {
			return (
				<MsgPage type="ERROR" title="Erreur au chargement"/>
			);
		}

		let {coach} = this.state;

		if (coach === null) {
			return (
				<MsgPage type="WARNING" title="Absence de données" msg="Ce coach n'existe pas, ou alors n'est plus en activité chez Urban Challenge."/>
			);
		}

		return (
			<article className="Coach">
				<Helmet>
					<title>Coach : {coach.firstname}{coach.lastname && ` ${coach.lastname.slice(0,2)}.`} | Urban Challenge</title>
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<header>
					<img src={coachHero} alt="Coach background"/>
					<button onClick={this.goBack}>Retour</button>
					<div>
						<Img src={coach.avatar || appIcon} alt={coach.firstname} lazySrc='TODO'/>
						<h1>{coach.firstname}{coach.lastname && ` ${coach.lastname.slice(0,2)}.`}</h1>
					</div>
				</header>
				<section className="coach-description">
					<h2>Biographie</h2>
					<div className="richText" dangerouslySetInnerHTML={{__html: coach.description}}/>
				</section>
				<section>
					<h2>Prochaines séances</h2>
					<Planning coach={coach} displayAll={true}/>
				</section>
			</article>
		);
	}
}

export default withRouter(Coach);
