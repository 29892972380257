import React, {Component} from 'react';
import Helmet from 'react-helmet';

import './Legal.scss';

class Legal extends Component {
	render() {
		return (
			<div className="Legal">
				<Helmet key='helmet'>
					<link rel="canonical" href="https://www.urban-challenge.fr/legal" />
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<header>
					<h1>Mentions légales</h1>
				</header>
				<section>
					<div>
						<p>
							Le présent site est édité par l'association Urban Running par Actions Simplifiées,
							dont le numéro SIRET est le 52287351200035 et dont le siège social est au 4 rue Severo, 75004 Paris (Tél. : <a href="tel:+33145209347">+33 1 45 20 93 47</a> – <a href="mailto:info@urban-challenge.fr">info@urban-challenge.fr</a>)
						</p>
						<p>
							L'hébergeur du site est la société Amazon AWS.
						</p>
						<p>
							Le site est d'accès libre et gratuit à tout internaute.
						</p>
					</div>
				</section>
			</div>
		);
	}
}

export default Legal;
