import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import MsgPage from './utils/MsgPage';

class ActivateSub extends Component {
	static contextTypes = {
		API: PropTypes.object,
		refreshUser : PropTypes.func
	};

	constructor(props){
		super(props);
		this.state = {
			step: 'INIT'
		};
	}

	componentDidMount() {
		const sp = new URLSearchParams(window.location.search);
		if(!sp.get('token')) {
			this.setState({
				step:'NO_TOKEN'
			})
			return;
		}

		this.context.API.subscriptionActivate(sp.get('token'))
		.then( () => {
			window.location.href = 'https://www.urban-challenge.fr/account/bookings';
		})
		.catch( e => {
			this.setState({ step:'ERROR'});
		})
	}


	render() {
		if (this.state.step === 'INIT') {
			return <MsgPage type='LOADER' title='Activation de votre souscription'/>
		}

		if (this.state.step === 'NO_TOKEN') {
			return <MsgPage type='ERROR' title="Il semble que vous n'avez pas de souscription à activer"/>
		}

		if (this.state.step === 'ERROR') {
			return <MsgPage type='ERROR' title='Une erreur est survenue'/>
		}

		if (this.state.step === 'OK') {
			return <Redirect to='/activateSub-success' />
		}


		return <MsgPage type='ERROR' title="Une erreur est survenue (4f3)"/>
	}
}

export default ActivateSub;
