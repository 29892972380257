import React, {Component, Fragment} from 'react';
import moment from "moment";
import 'moment/locale/fr';

import './DaySelector.scss';
import Img from './utils/Img';

import prevIcon from './icons/chevron-left.svg';
import nextIcon from './icons/chevron-right.svg';

class DaySelector extends Component {

	onClick = (day, event) => {
		event.preventDefault();
		if (event.currentTarget.classList.contains('disabled')) {
			return false;
		} else {
			return (this.props.onSelect(day));
		}
	};

	changeWeek = (event) => {
		event.preventDefault();
		this.props.onSelect(event.currentTarget.dataset.week);
	};

	render() {
		let {selected} = this.props;
		let period = 7;
		let start = moment(selected).subtract(moment(selected).day() === 0 ? 6 : moment(selected).day() - 1, 'days');
		let nextWeek = start.clone().add(1, 'w').format('YYYY-MM-DD');
		let prevWeek = start.clone().subtract(1, 'w');
		prevWeek = prevWeek.isBefore(moment().subtract(2, 'w').hour(0).minute(0)) ? moment().subtract(2, 'w') : prevWeek;
		prevWeek = prevWeek.format('YYYY-MM-DD');

		return (
			<Fragment>
				<p className="DaySelector-month">{start.format('MMMM')}</p>
				<ol className="DaySelector">
					<li>
						<button disabled={false}
								onClick={this.changeWeek}
								data-week={prevWeek}
						>
							<Img src={prevIcon} alt="Préc." lazySrc='TODO'/>
						</button>
					</li>
					{[...Array(period).keys()].map(d => {
						let date = start.clone().add(d, 'days');
						return (
							<li key={date}>
								<time dateTime={date.format('YYYY-MM-DD')}
									  className={
										  (selected === date.format('YYYY-MM-DD') ? "active" : "")
									  }
									  onClick={this.onClick.bind(this, date.format('YYYY-MM-DD'))}
								>
									<span className={(date.hour(23).isBefore(moment().hour(0)) ? " pastDate" : "")}>{moment(date).locale('fr').format('dd')}</span>
									<strong className={(date.hour(23).isBefore(moment().hour(0)) ? " pastDate" : "")}>{moment(date).format('DD')}<span>{moment(date).format('/MM')}</span></strong>
								</time>
							</li>
						);
					})}
					<li>
						<button onClick={this.changeWeek} data-week={nextWeek}>
							<Img src={nextIcon} alt="Suiv." lazySrc='todo' />
						</button>
					</li>
				</ol>
			</Fragment>
		);
	}

}

export default DaySelector;