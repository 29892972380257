import React, {Component} from 'react';
import {Link} from "react-router-dom";

import Img from "./utils/Img";

import './GMapsPlaceDisplay.scss';

import distanceImage from "./icons/geoloc--plain.svg";

class GMapsPlaceDisplay extends Component {
	render() {
		const p = this.props.selected.place || {};
		return (
			<aside className={"GMapsPlaceDisplay" + (p.searchImage ? " has-image" : "") + ` ${this.props.selected.display}`}>
				{p.searchImage &&
				<div className="place-img" onClick={this.props.changeDisplay}>
					<Img lazySrc='todo' src={p.searchImage.path} alt={p.name}/>
				</div>
				}
				{p.name &&
				<section>
					<h1 onClick={this.props.changeDisplay}>{p.name}</h1>
					<div className="place-location">
						<div className="richText" dangerouslySetInnerHTML={{__html: p.practical}}/>
						{p.distance &&
						<span>
							<Img lazySrc='todo' src={distanceImage} alt="à"/>&nbsp;
							{p.distance > 1000 ? Math.round(p.distance / 100) / 10 + "km" : Math.round(p.distance) + "m"}
						</span>
						}
					</div>
					<Link to={`/place/${p.id}`}>Voir le lieu</Link>
				</section>
				}
			</aside>
		)
	}
}

export default GMapsPlaceDisplay;
