import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import moment from 'moment';

import MsgPage from "./utils/MsgPage";
import Pagination from "./utils/Pagination";
import FormField from "./FormField";
import PaymentForm from "./PaymentForm";
import ImageModifiable from './ImageModifiable';

import './Profile.scss';

import editIcon from "./icons/edit.svg";
import downloadIcon from "./icons/download.svg";
class Profile extends Component {

	static contextTypes = {
		API   : PropTypes.object,
		signOut: PropTypes.func,
		user   : PropTypes.object,
		refreshUser : PropTypes.func
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			readOnly: true,
			form: [{
				name: 'firstname',
				label: "Prénom",
				placeholder: "Ex: Matthieu",
				value: this.context.user.firstname
			}, {
				name: 'lastname',
				label: "Nom",
				placeholder: "Ex: Dupont",
				value: this.context.user.lastname
			}, {
				name: 'email',
				label: "Email",
				placeholder: "Ex: matthieu.dupont@domaine.fr",
				value: this.context.user.email
			}, {
				name: 'phone',
				label: "Téléphone",
				placeholder: "Ex: 06 XX XX XX XX",
				value: this.context.user.phone
			}]
		}
	};

	signOut = (event) => {
		event.preventDefault();
		this.context.signOut();
	};

	editInfos = () => {
		this.setState({readOnly: false});
	};

	onSubmit = () => {
		let userData = this.state.form.reduce((acc,f) => { acc[f.name] = f.value; return acc;}, {});
		this.context.API.patchCurrentUser(userData)
		.then(res => {
			let newState = Object.assign({}, this.state);
			Object.keys(newState.form).forEach( k => delete newState.form[k].error);
			newState.error='';
			newState.readOnly = true;

			this.setState(newState);
		})
		.catch(e=>{
			if(e.statusCode === 422) {
				let newState = Object.assign({}, this.state);
				newState.form['2'].error = true;
				newState.error = e.userMsg;
				this.setState(newState);
			}

			console.error(e)
		});
	};

	refreshUser = () => {
		this.context.refreshUser();
	};

	render() {
		let defaultPaymentOpened = this.props.history.location.hash  === '#UPDATE_PAYMENT';

		return (
			<div className="ProfilePage">
				<section className='contact-infos'>
					<div style={{"flexDirection": "row"}}>
						<h2>Mes coordonnées</h2>
						{ this.state.readOnly &&
							<button className="edit-userInfo-button" onClick={this.editInfos}>
								<img src={editIcon} alt="Modifier"/>
							</button>
						}
					</div>
					{this.state.error && <p style={{color: 'red'}}>{this.state.error}</p>}
					<UserInfos onSubmit={this.onSubmit} fields={this.state.form} readOnly={this.state.readOnly}/>
				</section>
				<section>
					<h2>Avatar</h2>
					<Avatar value={this.context.user.avatar} onChange={this.refreshUser}/>
				</section>
				<section>
					<h2>Facturation</h2>
					<PaymentInfos defaultOpened = {defaultPaymentOpened}/>
					<Invoices/>
				</section>
				{this.state.readOnly &&
				<button onClick={this.signOut}>
					Déconnexion
				</button>
				}
			</div>
		);
	}
}

class Avatar extends React.Component {

	static contextTypes = {
		API   : PropTypes.object,
		user   : PropTypes.object
	};

	onChange = (image) => {
		let avatar = image ? {id: image.id} : null;

		this.context.API.patchCurrentUser({avatar})
		.then( () => {
			this.props.onChange();
		});
	};

	render() {
		return (
			<ImageModifiable image={this.props.value} imageFor={{type: 'User', id: this.context.user.id}} onCreated={this.onChange} onDelete={this.onChange}/>
		);
	}
}
class PaymentInfos extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step: 'LOADING'
		};
	}

	componentDidMount(){
		this.reload(this.props.defaultOpened);
		const searchParams = new URLSearchParams( window.location.search);
		if( searchParams.get('3ds') === 'decline') {
			this.setState({message: "La procédure 3dSecure semble avoir refusée", messageColor:'red'})
		} else if( searchParams.get('3ds') === 'success') {
			this.setState({message: "Votre carte a bien été validée"})
		} else if( searchParams.get('3ds') === 'exception') {
			this.setState({message: "La procédure 3dSecure semble avoir échouée", messageColor:'red'})
		}
	}

	reload = (defaultOpened) => {
		this.setState({
			step: 'LOADING'
		});
		//XXX In fact, we only list the current user paymentMethod...
		//So we can have no cards or only one card
		this.context.API.paymentMethodList()
		.then(res => {
			this.setState({
				step: !defaultOpened ? 'OK' : 'UPDATE',
				paymentMethod: !!res.length && res[0]
			})
		})
		.catch( e => {
			this.setState({ step: "ERROR" });
		});
	};

	onChange = (event) => {
		event && event.preventDefault();
		this.setState({ step: "UPDATE" });
	};

	cancelUpdate = (event) => {
		event && event.preventDefault();
		this.setState({
			step: 'OK'
		});
	};

	cardUpdated = (id) => {
		this.reload();
	};

	render(){
		if(this.state.step === 'LOADING')
			return <MsgPage type='LOADER' title="Chargement de votre profile" />;

		if(this.state.step === 'ERROR')
			return <MsgPage type='ERROR' title="Une erreur est survenue" />;

		if(this.state.step === 'UPDATE')
			return <PaymentForm back={this.cancelUpdate} backLabel="Annuler" setPaymentCard={this.cardUpdated} submitLabel="Mettre à jour"/>

		return (
			<div className="PaymentInfos">
				{this.state.message && <div style={{fontSize:'20px', fontWeight:'bold', color:this.state.messageColor || 'green'}}>{this.state.message}</div>}
				<span>Moyen de paiement</span>
				<p>
					{this.state.paymentMethod ?
						<strong>CB nº {this.state.paymentMethod.number}</strong>
						:
						<em>(non renseigné)</em>
					}
					<button className="edit-payment-button" onClick={this.onChange}>
						<img src={editIcon} alt="Modifier"/>
					</button>
				</p>
			</div>
		);
	}
}

class UserInfos extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fields: this.props.fields
		}
	}

	onChange = (event) => {
		const {name, value} = event.target;
		let newState = Object.assign({}, this.state);

		newState.fields.find(f => f.name === name).value = value;
		this.setState(newState);
	};

	onSubmit = (event) => {
		event.preventDefault();
		this.props.onSubmit(this.state.fields);
	};

	render() {
		return (
			<form className="UserInfos">
				<fieldset disabled={this.props.readOnly}>
					{this.state.fields.map(f =>
						<FormField key={f.name} field={f} name={f.name} onChange={this.onChange}/>
					)}
				</fieldset>
				{!this.props.readOnly &&
				<button onClick={this.onSubmit}>
					Sauvegarder
				</button>
				}
			</form>
		)
	}
}

class Invoices extends Component {

	static contextTypes = {
		API   : PropTypes.object
	};

	constructor(props){
		super(props);
		this.state = {step:'LOADING', page :1};
	}

	componentDidMount(){
		this.load()
	}

	load = () => {
		this.setState({step:'LOADING'});
		this.context.API.paymentList()
		.then(payments => {
			this.setState({step:'OK', payments});
		})
		.catch(e=>{
			console.error(e);
			this.setState({step:'ERROR'});
		})
	};

	onPageChanged = (page) => {
		this.setState({page:page});
	};

	render() {

		if (this.state.step === 'LOADING')
			return <p>Chargement</p> //TODO

		if (this.state.step === 'ERROR')
			return <p>Une erreur est survemue</p>;

		let nbPerPage = 5;
		let first = (this.state.page - 1) * nbPerPage;
		let last = first + nbPerPage;
		let pageMax = Math.ceil(this.state.payments.length / nbPerPage);

		return (
			<div className="UserInvoices">
				{this.state.payments.length ?
					<Fragment>
						<ul className="invoices-list">
							{this.state.payments.slice(first, last).map(p =>
								<li key={p.id}>
									<p>
										<span>Facture du {moment(p.creation.date).format('DD/MM/YYYY')}</span>
										<strong>{p.price}€</strong>
									</p>
									{/*Keep the target="_blank" attribute to handle some inferior browsers*/}
									{p.url &&
									<a href={p.url} download={"UrbanChallenge_Facture_"+ p.id} target="_blank" rel="noopener noreferrer">
										<img src={downloadIcon} alt="Télécharger"/>
									</a>
									}
								</li>
							)}
						</ul>
						<Pagination current={this.state.page} max={pageMax} onPageChanged={this.onPageChanged}/>
					</Fragment>
					:
					<p>Vos factures apparaîtront ici à la fin de chaque période</p>
				}
			</div>
		);
	}
}

export default Profile;
