import hermes from "./images/extern/hermes@2x.png";
import lorealGroupe from "./images/extern/l-oreal@2x.png";
import orangina from "./images/extern/orangina@2x.png";
import societeGenerale from "./images/extern/societe-generale@2x.png";
import edf from "./images/extern/edf@2x.png";
import indeed from "./images/extern/indeed@2x.png";
import redBull from "./images/extern/red-bull@2x.png";
import marionnaud from "./images/extern/marionnaud@2x.png";
import total from "./images/extern/total@2x.png";
import bnpParibas from "./images/extern/bnp-paribas@2x.png";
import backmarket from "./images/extern/back-market@2x.png";
import uggcAvocats from "./images/extern/uggc-avocats@2x.png";

export default [{
	name: "Hermès",
	logoUrl: hermes
}, {
	name: "L'Oréal",
	logoUrl: lorealGroupe
}, {
	name: "Orangina",
	logoUrl: orangina
}, {
	name: "Red Bull",
	logoUrl: redBull
}, {
	name: "Société Générale",
	logoUrl: societeGenerale
}, {
	name: "Marionnaud",
	logoUrl: marionnaud
}, {
	name: "EDF",
	logoUrl: edf
}, {
	name: "Total",
	logoUrl: total
}, {
	name: "Indeed",
	logoUrl: indeed
}, {
	name: "BNP Paribas",
	logoUrl: bnpParibas
}, {
	name: "BackMarket",
	logoUrl: backmarket
}, {
	name: "UGGC",
	logoUrl: uggcAvocats
}];
