import React, {Component} from 'react';
import Helmet from 'react-helmet';

import './Contact.scss';

export default class Contact extends Component {

	constructor(props){
		super(props);
		this.state = {
			display:null
		};
	}

	componentDidMount() {
		window.scroll(0, 0);
		window.hbspt.forms.create({
			region: "na1",
			portalId: "9030020",
			formId: "1d5dbd49-8d11-4491-89da-838eaccdb706",
			target:'#hb-form-parti'
		});
		window.hbspt.forms.create({region: "na1",portalId: "9030020",formId: "69818ff9-5a1a-4ab5-a76e-f4bb8332babb", target:'#hb-form'});
	}

	render() {

		const displayB2B = this.state.display === 'b2b' ? 'block' : 'none';
		const displayB2C = this.state.display === 'b2c' ? 'block' : 'none';

		return (
			<div className='Contact'>
				<Helmet>
					<title>Nous contacter | Urban Challenge</title>
				</Helmet>
				<div className="main">
					<div className="section">
						<h1 style={{textAlign:'center'}}>Vous êtes un adhérent?</h1>
						<p style={{fontSize:'1.2rem', paddingTop:'1rem'}}>Vous êtes adhérent et rencontrez une difficulté sur notre site ? Vous ne l’êtes pas encore, mais vous souhaitez avoir plus de renseignements ? Notre équipe support reviendra vers vous dans les meilleurs délais.</p>
						<button onClick={()=>this.setState({display:'b2b'})} className='button-primary'>Contactez-nous</button>
					</div>
					<div className="section">
						<h1 style={{textAlign:'center'}}>Vous êtes une entreprise?</h1>
						<p style={{fontSize:'1.2rem', paddingTop:'1rem'}}>Vous avez une idée ou même un projet sport et bien-être pour prendre soin de vos salariés ? Notre équipe reprendra contact avec vous très rapidement.</p>
						<button onClick={()=>this.setState({display:'b2c'})} className='button-primary'>Contactez-nous</button>
					</div>
				</div>
				<section id='hb-form-parti' style={{marginTop:'2rem', backgroundColor:'white', padding:'2rem', display:displayB2B}}></section>
				<section id='hb-form' style={{marginTop:'2rem', backgroundColor:'white', padding:'2rem', display:displayB2C}}></section>
			</div>
		);
	}
}
