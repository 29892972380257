import FunctionnalAPI from './FunctionnalAPI';

const API = FunctionnalAPI;

function withCache(ttlInSec, work){
	let cache = {};

	return function () {
		let arg = '';
		
		if (arguments.length === 1) {
			arg = JSON.stringify(arguments[0]);
		} else if (arguments.length > 1) {
			console.error("Cannot work with more than one arg");
			return work.apply(null, arguments);
		}

		if (cache[arg]) {
			let date = cache[arg].date;
			let delay = new Date().getTime() - date;
			if (delay < ttlInSec * 1000) {
				return Promise.resolve(cache[arg].values);
			}
		}
		return work.apply(null, arguments)
		.then(res => {
			cache[arg] = {
				date: new Date().getTime(),
				values: res
			};
			return res;
		});
	};
}

let CacheAPI = {};

Object.keys(API).forEach(m => {
	if (m === 'seanceSearch') {
		CacheAPI[m] = withCache(600, API.seanceSearch);
		return;
	}
	if (m === 'placeSearch') {
		CacheAPI[m] = withCache(600, API.placeSearch);
		return;
	}
	CacheAPI[m] = API[m];
});

export default CacheAPI;