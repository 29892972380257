import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import Img from './utils/Img';
import MsgPage from "./utils/MsgPage";

import './CoachList.scss';

import coachsHero from './images/coachs-hero.jpg';
import appIcon from './images/app-logo.svg';

class CoachList extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: "LOADING"
		};
	}

	componentDidMount(){
		this.load();
	}

	load = () => {
		this.setState({
			step: 'LOADING'
		});
		this.context.API.coachSearch({_actif:true})
		.then( coaches => {
			coaches = this.context.repo.put(coaches);
			coaches.sort( (a,b) => {
				if(a.avatar && b.avatar) return 0;
				if(a.avatar) return -1;
				if(b.avatar) return 1;
				return 0;
			});
			this.setState({ step : "OK", coaches});
		})
		.catch( e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		})
	};

	render(){
		if (this.state.step === 'LOADING') {
			return (
				<MsgPage type="LOADER" title="Chargement des coachs"/>
			);
		}
		
		if (this.state.step === 'ERROR') {
			return (
				<MsgPage type="ERROR" />
			)
		}

		let {coaches} = this.state;

		return (
			<div className="CoachList">
				<Helmet>
					<title>Les instructeurs | Urban Challenge</title>
					<meta name="description" content="Ce sont tous des professionnels du sport, éducateurs sportifs issus du corps militaire, pompiers, ex-sportifs de haut niveau ou coachs professionnels. Grâce à leurs expériences incontestables dans l'encadrement, ils amènent les membres à relever des défis sportifs et créent des séances variées et surprenantes."/>
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<header>
					<Img src={coachsHero} alt="Coachs" lazySrc='TODO' />
					<h1>Nos coachs</h1>
				</header>
				<aside>
					<p>
						L'équipe d'Urban Challenge se compose de préparateurs physiques professionnels.<br/>
						Grâce à cet encadrement d'élite, vous pourrez atteindre vos objectifs et dépassez vos limites.
					</p>
					<p>
						Nous recherchons des personnes qui partagent nos valeurs et souhaitent allier le sport avec
						le plaisir tout au long de l’année. Si vous êtes dynamique et enthousiaste, envoyez-nous
						votre CV à <a href="mailto:emploi@urban-challenge.fr">emploi@urban-challenge.fr</a> pour exercer
						votre passion en extérieur à Paris.
						<em>Brevet d’Etat ou Licence STAPS indispensables</em>
					</p>
				</aside>
				<section>
					{!coaches.length && <p>Il n'y a pas de coach :/</p>}
					{!!coaches.length && 
						<ul>
							{coaches.map(c =>
							<li key={c.id}>
								<CoachPreview coach={c}/>
							</li>
							)}
						</ul>
					}
				</section>
			</div>
		);
	}
}

class CoachPreview extends React.Component {
	render(){
		let {coach} = this.props;
		return (
			<article className="CoachPreview">
				<header>
					<Img src={coach.avatar  || appIcon} alt={coach.firstname} lazySrc='todo' />
					<h2>{coach.firstname}</h2>
				</header>
				<Link to={`/coach/${coach.id}`} aria-label={`En savoir plus sur ${coach.firstname}`}/>
			</article>
		);
	}
}

export default CoachList;