import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

import Img from './utils/Img';
import menuLogo from './images/logo-menu.svg';

import './Footer.scss';

import mainMenu from './mainMenuItems';
import socialMenu from './socialMenu';
import footerMenu from "./footerMenu";

import fullLogoFooter from './images/logo-full--light.svg';
import PropTypes from "prop-types";

const coachItem = {
	label : "Espace coach",
	url   : '/coaching'
};

class Footer extends Component {
	static contextTypes = {
		user          : PropTypes.object
	};
	checkStandaloneMode = () => {
		return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
	};

	render() {
		const year = new Date().getFullYear();
		let displayWebApp = !this.checkStandaloneMode();

		return (
			<footer className="Footer">
				<section>
					<nav className="main-nav">
						<p>Urban Challenge</p>
						<ul>
							<li>
								<NavLink to='/account' onClick={this.props.toggleMenu}>
									<strong>
										{!this.context.user ? "Se connecter" : "Mon compte"}
									</strong>
								</NavLink>
							</li>
							<div style={{display:'flex', flexDirection:'row', gap:'2rem'}}>
								<div>
									<li>
										<NavLink to='/concept' onClick={this.props.toggleMenu}>Le concept</NavLink>
									</li>
									<li>
										<NavLink to='/plans' onClick={this.props.toggleMenu}>Nos formules</NavLink>
									</li>
									<li>
										<a href="https://blog.urban-challenge.fr/" target="_blank" rel="noopener noreferrer">Blog</a>
									</li>
									<li>
										<NavLink to='/coach' onClick={this.props.toggleMenu}>Nos coachs</NavLink>
									</li>
									<li>
										<NavLink to='/contact' onClick={this.props.toggleMenu}>Nous contacter</NavLink>
									</li>
								</div>
								<div>
									<li>
										<NavLink to='/enterprise' onClick={this.props.toggleMenu}>Entreprises</NavLink>
									</li>
									<li>
										<NavLink to='/challenge' onClick={this.props.toggleMenu}>Team building & Event</NavLink>
									</li>
									<li>
										<NavLink to='/partneroffer' onClick={this.props.toggleMenu}>Offres partenaires</NavLink>
									</li>
									<li>
										<NavLink to='/feedbacks' onClick={this.props.toggleMenu}>Avis</NavLink>
									</li>
									<li>
										<NavLink to='/webapp' onClick={this.props.toggleMenu}>Application Web</NavLink>
									</li>
								</div>
								
							</div>
						</ul>
					</nav>
					<nav className="secondary-nav">
						<p>Info</p>
						<ul>
							<br/>
							<li>
								<NavLink to='/contact' onClick={this.props.toggleMenu}>Nous contacter</NavLink>
							</li>
							<li>
								<NavLink to='/legal' onClick={this.props.toggleMenu}>Mentions légales</NavLink>
							</li>
							<li>
								<NavLink to='/terms' onClick={this.props.toggleMenu}>Conditions générales</NavLink>
							</li>
							<li>
								<NavLink to='/confidentialite' onClick={this.props.toggleMenu}>Politique de confidentialité</NavLink>
							</li>
						</ul>
					</nav>
					<div>
						<img src={fullLogoFooter} alt="Urban Challenge"/>
						<nav className="social-nav">
							<ul>
								{socialMenu.map((m, i) =>
									<li key={`social_${i}`}>
										<a href={m.url} target="_blank" rel="noopener noreferrer" className={m.name}>
											<Img lazySrc='todo' src={m.icon} alt={m.name}/>
										</a>
									</li>
								)}
							</ul>
						</nav>
					</div>
				</section>
				<footer>
					<p>{year} &copy; Urban Challenge</p>
				</footer>
			</footer>
		);
	}
}

export default Footer;
