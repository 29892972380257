import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from "./Modal";
import Img from "./utils/Img";

import './AttendeesModal.scss';

import defaultMaleAvatar from './images/avatar-male--colored.png';
import defaultFemaleAvatar from './images/avatar-female--colored.png';

export default class AttendeesModal extends Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING' };
	}

	componentDidMount() {
		this.setState({ step: 'LOADING' });
		this.context.API.seanceAttendeeList(this.props.seance.id)
		.then( users => {
				this.setState({ step: 'OK', users });
		})
		.catch( e => {
			console.error(e);
				this.setState({ step: 'ERROR'});
		})
		;
	
	}

	render() {
		return (
			<Modal className="attendees-modal"
			   show={true}
			   title="Participants"
			   confirmFn={this.props.onClose}
			   confirmLb="Fermer">
			   {this[this.state.step]()}
			</Modal>
		);
	}

	LOADING = () => {
		return <p>LOADING</p>
	};

	ERROR = () => {
		return <p>ERROR</p>
	};

	OK = () => {
		return (
			<ul>
				{this.state.users.map( (u,i) => {
					let avatar = u.avatar;
					if (avatar) {
						avatar = avatar.path;
					} else {
						avatar = u.gender === "M" ? defaultMaleAvatar : defaultFemaleAvatar;
					}
					let level = u.level;
					switch (level) {
						case 'white' :
							level = 'RankOne';
							break;
						case 'green' :
							level = 'RankTwo';
							break;
						case 'black' :
							level = 'RankThird';
							break;
						default :
							level = '';
							break;
					}

					return (
						<li key={i}>
							<article className="AttendeePreview">
								<Img className={level} src={avatar} alt={u.firstname} lazySrc='todo' />
								<strong>{u.firstname} {u.lastname.charAt(0)}.</strong>
								{u.offer.id === 11 &&
									<span>New</span>
								}
							</article>
						</li>
					);
				})}
			</ul>
		)
	};

}
