export default {
	main: [{
		label: "Nos formules",
		url: "/plans",
		// forGuest: true
	}, {
		label: "Le concept",
		url: "/concept"
	}, {
		label: "Planning",
		url: "/seance"
	},
	// 	{
	// 	label: "Coach Individuel",
	// 	url: "/teacher",
	// 	forCorporate: true
	// },
		{
		label: "Lieux",
		url: "/place"
	}],
	secondary: [{
		label: "Entreprises",
		url: "/enterprise"
	}, {
		label: "Nous contacter",
		url: "/contact"
	}, {
		label: "Blog",
		url: "https://blog.urban-challenge.fr",
		external: true
	}, {
		label: "Nos Coachs",
		url: "/coach"
	}, {
		label: "Avis",
		url: "/feedbacks"
	}, {
		label: "Presse",
		url: "/press"
	}, {
		label: "Offres Partenaires",
		url: "/partneroffer"
	}]
};
