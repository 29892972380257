import React from 'react';

import Modal from "./Modal";
import './LiveMoreInfo.scss';

const LiveMoreInfo = ({...props}) => {
	return (
		<div >
			<Modal
				className="LiveMoreInfo"
				title="URBAN CHALLENGE X ZOOM"
				cancelLb="Fermer"
				cancelFn={ props.close }
				show={props.show} >
				<p>Réservez votre séance live, un lien visio - Zoom vous sera envoyé par mail.
					Vous pouvez également retrouver ce lien en revenant sur la séance.</p>

				<span>Vous pouvez assister à la séance en mode ‘caché’ ou ‘visible’ à votre convenance et humeur du moment.</span>
			</Modal>
		</div>
	);
};

export default LiveMoreInfo;
