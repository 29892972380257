import API from './API';

var api = {
	 onSessionLost			: (listener) => API.onSessionLost(listener)
	,passwordRecoveryCreate : ({email}) => API.POST('/public/passwordRecovery', {email})
	,passwordRecoveryUse    : (code, password) => API.PUT(`/public/passwordRecovery/${code}`, {password})
	,adminLoginAs           : (loginAs) => API.PATCH(`/public/session/loginAs`, {loginAs:loginAs})
	,signIn					: ({email, password}) => API.POST('/public/session', {email,password})
	,signOut				: () => API.DELETE('/public/session/mine')
	,signUp 				: (params) => API.POST('/public/user', params)
	,seanceSearch			: (params) => API.GET(`/public/seance?${serialize(params)}`)
	,seanceGet				: (ids) => API.GET(`/public/seance/${[].concat(ids).join(',')}`)
	,seanceAttendeeList		: (id) => API.GET(`/public/seance/${id}/attendee`)
	,getCurrentUser			: () => API.GET('/public/user/current')
	,patchCurrentUser		: (params) => API.PATCH('/public/user/current', params)
	,userGet				: (ids) => API.GET(`/public/user/${[].concat(ids).join(',')}`)
	,placeSearch			: (params) => API.GET(`/public/place?${serialize(params)}`)
	,placeGet				: (ids) => {return API.GET(`/public/place/${[].concat(ids).join(',')}`);}
	,subscriptionList		: () => API.GET('/public/subscription')
	,paymentMethodList   	: () => API.GET('/public/paymentMethod')
	,paymentMethodCreate	: ({card, secure, creation_url}) => API.POST('/public/paymentMethod', {card, secure, creation_url})
	,subscriptionCreate		: ({card,offer, promo}) => API.POST('/public/subscription', {card,offer, promo})
	,subscriptionUpdate		: (id, params) => API.PATCH(`/public/subscription/${id}`, params)
	,subscriptionCancel		: (id) => API.PATCH(`/public/subscription/${id}`, {cancelation:true})
	,subscriptionUncancel	: (id) => API.PATCH(`/public/subscription/${id}`, {cancelation:false})
	,offerGet				: (ids) => API.GET(`/public/offer?${[].concat(ids).join(',')}`)
	,offerList				: () => API.GET('/public/offer')
	,bookingUpdate			: (id, params) => API.PATCH(`/public/booking/${id}`, params)
	,bookingCreate			: (params) => API.POST(`/public/booking`, params)
	,bookingCancel			: (id) => API.DELETE(`/public/booking/${id}`)
	,bookingList			: (params) => API.GET(`/public/booking?${serialize(params)}`)
	,coachGet								: (id) => API.GET(`/public/coach/${id}`)
	,coachSearch						: (params) => API.GET('/public/coach?'+serialize(params))
	,promoGet								: (code) => API.GET(`/public/promo/${code}`)
	,paymentList            : () => API.GET(`/public/payment`)
	,feedbackGet						: (ids) => API.GET(`/public/feedback?${[].concat(ids).join(',')}`)
	,feedbackSearch         : (params) => API.GET('/public/feedback?'+serialize(params))
	,coachInvoiceSearch     : (id) => API.GET(`/public/coachInvoice/${id}`)
	,coachConversionSearch  : (params) => API.GET('/public/coachConversion?'+serialize(params))
	,feedbackCreate         : (params) => API.POST('/public/feedback', params)
	,eventSearch            : (params) => API.GET(`/public/event?${serialize(params)}`)
	,corporateContactCreate : (params) => API.POST('/public/corporate/contact', params)
	,corporateGet						: (ids) => API.GET(`/public/corporate/${ids.join(',')}`)
	,referralGetCode				: (id) => API.GET(`/public/referral/${id}`)
	,referralsList					: () => API.GET('/public/referral')
	,messageCreate					: (params) => API.POST('/public/message', params)
	,messageSearch					: (params) => API.GET(`/public/message?${serialize(params)}`)
	,healthCheckupCreate		: (params) => API.POST('/public/healthCheckup', params)
	,healthCheckupSearch		: (params) => API.GET(`/public/healthCheckup?${serialize(params)}`)
	,getUrl									: (path, params) => API.getUrl(`/public${path}?${serialize(params)}`)
	,imageSearch            : (params) => API.GET(`/public/image?${serialize(params)}`)
	,imageCreate            : (body) => API.POST(`/public/image`, body)
	,imageDelete            : (id) => API.DELETE(`/public/image?${id}`)
	,watcherSearch					: (params) => API.GET(`/public/watcher?${serialize(params)}`)
	,watcherCreate					: (body) => API.POST(`/public/watcher`, body)
	,watcherDelete					: (id) => API.DELETE(`/public/watcher/${id}`)
	,getTeacher							: (id) => API.GET(`/public/teacher/${id}`)
	,searchTeacher					: (params) => API.GET(`/public/teacher?${serialize(params)}`)
	,teacherStudentGet			: (params) => API.GET(`/public/teacher/student?${serialize(params)}`)
	,getPersonnalMessage		: (params) => API.GET(`/public/message/teacher?${serialize(params)}`)
	,sendPersonnalMessage		: (body) => API.POST('/public/message/teacher', body)
	,subscriptionActivate   : (token) => API.PUT(`/public/subscriptionActivate/${token}`)
};

function serialize(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export default api;
