import React, {Component} from 'react';
import MsgPage from "./utils/MsgPage";
import Img from "./utils/Img";
import {Helmet} from 'react-helmet';

import challenges from "./challenges.js";

import './Challenge.scss';

import usersIcon from "./icons/users.svg";

class Challenge extends Component {

	constructor(props) {
		super(props);
		this.state = {
			step: 'LOADING'
		}
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.load();
	}

	load = () => {
		this.setState({step:'LOADING'});
		let challenge = challenges.find(c => c.id === +this.props.match.params.challengeId);
		if (!challenge) 
			return this.setState({ step: 'ERROR' });
		
		this.setState({ step: 'OK', challenge});
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {

		if (this.state.step === 'LOADING')
			return <MsgPage type="LOADER" title="Chargement du challenge"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR" title="Ce team building/challenge n'existe pas"/>;

		let challenge = this.state.challenge;

		return (
			<div className="Challenge">
				<Helmet>
					<title>Challenge et Team Building : {challenge.name} | Urban Challenge</title>
					<link rel="canonical" href={`https://www.urban-challenge.fr${challenge.oldPath}`} />
					<meta name="description" content={challenge.metaDescription}/>
				</Helmet>
				<header>
					<button onClick={this.goBack}>Retour</button>
					<Img src={challenge.coverPic} alt={challenge.name} lazySrc="TODO"/>
					<h1>
						{challenge.name}
					</h1>
				</header>
				<aside>
					<strong>Objectifs</strong>
					<p>{challenge.goalsTxt}</p>
					<section>
						<div>
							<ul>
								{challenge.goalsList.map((g, i) =>
									<li key={i}>{g}</li>
								)}
							</ul>
							<p>
								<Img src={usersIcon} alt="Participants"/>
								à partir de {challenge.minimumAttendees} participant{challenge.minimumAttendees > 1 ? "s" : ""}
							</p>
						</div>
						<a href="mailto:info@urban-challenge.fr">Contactez-nous</a>
					</section>
				</aside>
				<section>
					<h2>Brief</h2>
					<p>{challenge.brief}</p>
				</section>
				<section>
					<h2>Description</h2>
					<p>{challenge.description}</p>
				</section>
				{!!challenge.pics.length &&
				<section>
					{/* TODO - inutile pour le moment car on n'a pas de photos...*/}
				</section>
				}
			</div>
		)
	}
}

export default Challenge;