import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment/locale/fr';

import Modal from "./Modal";
import MsgPage from "./utils/MsgPage";
import Feedback from "./Feedback";

import './BookModal.scss';

export default class BookModal extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object,
		user: PropTypes.object,
		analytics: PropTypes.func,
		signInNeeded: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING' };
	}

	componentDidMount() {
		this.load();
	}

	cancelFeedbackToDo = (event) => {
		event && event.preventDefault();
		this.setState({feedbackTodo : null});
	};

	load = () => {
		let {API, repo, user} = this.context;

		if ( !user ) return this.setState({ step: 'OK' });

		this.setState({step: 'LOADING'});

		API.bookingList({ presence: true, user_id: user.id, feedback:null })
		.then( bookingWaitingForFeedback => {
			bookingWaitingForFeedback = repo.put(bookingWaitingForFeedback);
			let feedbackTodo = bookingWaitingForFeedback
			.sort( (a,b) => b.creation.date - a.creation.date )[0];
			this.setState({ step: 'OK', feedbackTodo});
		})
		.catch( e => {
			this.setState({ step: "OK", feedbackTodo:null});
		})
		;
	};

	cancel = (event) => {
		event && event.preventDefault();
		this.props.onCancel();
	};

	book = (event) => {
		event.preventDefault();
		this.setState({ step: 'PENDING' });
		if (!this.context.user)
			return this.context.signInNeeded();

		this.context.API.bookingCreate({
			seance: {
				id: this.props.seance.id
			}
		})
		.then(resp => {
			this.context.analytics('book');
			this.setState({step : "SUCCESS", feedbackTodo:null});
		})
		.catch(e => {
			let error = e.userMsg || "Une erreur est survenue";
			this.setState({
				step: 'FAILURE',
				error,
				errorType: e.restCode || 'unknow'
			})
		});
	};

	render() {
		let {seance, booking} = this.props;
		let {step, error, feedbackTodo, errorType} = this.state;

		if ( step === 'LOADING') return <MsgPage type='LOADER'/>;

		if ( step === 'PENDING') {
			return (
				<div className='BookModal'>
					<Modal
						title="Réservation"
						show={true}
					>
						<MsgPage type='LOADER' title={"En cours..."}/>
					</Modal>
				</div>
			);
		}

		if ( step === 'FAILURE') {
			if( errorType === 'unpaid' ) {
				return <div className='BookModal BookModal_Failure'>
					<Modal
						title="Réservation"
						show={true}
						className="booking-failure-modal"
					>
						<MsgPage type='ERROR' title="Réservation impossible"
								msg="Vous êtes en impayé"
								confirmFunc={ () => this.props.history.push('/account/profile#UPDATE_PAYMENT') }
								confirmLb="Je mets à jour mon moyen de paiement"
						/>
					</Modal>
				</div>;
			}
			return (
				<div className='BookModal BookModal_Failure'>
					<Modal
						title="Réservation"
						show={true}
						className="booking-failure-modal"
					>
						<MsgPage type='ERROR' title="Réservation impossible"
								msg={error}
								confirmFunc={ () => this.props.history.push('/account/profile#UPDATE_PAYMENT') }
								confirmLb="Je mets à jour mon moyen de paiement"
						/>
					</Modal>
				</div>
			);
		}

		if ( step === 'SUCCESS') {
			return (
				<div className='BookModal BookModal_Success'>
					<Modal
						className="booking-modal-success"
						show={true}
						title="Réservation"
						cancelFn={this.props.onSuccess}
						cancelLb="Fermer"
					>
						<div>
							<MsgPage type="SUCCESS" title="Réservation effectuée !" msg="Votre réservation a été effectuée avec succès"/>
							<Link to='/account'>Voir mes réservations</Link>
						</div>
					</Modal>
				</div>
			);
		}

		let askFeedback = !booking && feedbackTodo;

		return (
			<div className='BookModal'>
				{ askFeedback &&
					<Feedback booking={feedbackTodo}
						onCreated	= {this.cancelFeedbackToDo}
						onClose		= {this.cancelFeedbackToDo}
						onInvalid	= {this.cancelFeedbackToDo}
					  	otherSeance = {true}
					/>
				}

				{ !askFeedback &&
					<Modal
						className="booking-modal"
						show={true}
						title="Je réserve"
						confirmFn={this.book}
						confirmLb="Confirmer"
						cancelFn={this.cancel}
						cancelLb="Annuler"
					>
						<p>
							<strong>{seance.name}</strong>
							<span>{seance.place.name}</span>
							<time dateTime={seance.starts}>{moment(seance.starts).locale('fr').format("dddd DD/MM - HH:mm")}</time>
						</p>
					</Modal>
				}
			</div>
		);
	}
}
