import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import MsgPage from "./utils/MsgPage";
import Modal from "./Modal";
import OfferSelector from './OfferSelector';
import PaymentFormCompo from './PaymentFormCompo';
import PaymentUseCurrentCardModal from './PaymentUseCurrentCardModal';
import FormField from "./FormField";
import MedicalCheck from "./MedicalCheck";

import "./Plan.scss";

import closeIcon from './icons/x-circle.svg';

export default class Subscribe extends Component {

	static contextTypes = {
		API: PropTypes.object,
		user : PropTypes.object,
		analytics : PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		const sp = new URLSearchParams( window.location.search );
		const selected = this.props.offers.find( o => o.id == sp.get('offer') );

		this.state = {
			step: 'LOADING',
			paymentMethod: null,
			offer : selected,
			promo: null,
			card : null
		};
	}

	componentDidMount(){
		this.setState({ step: 'LOADING'});
		//XXX In fact, we only list the current user paymentMethod...
		//So we can have no cards or only one card
		this.context.API.paymentMethodList()
		.then(res => {
			//Only 3dSecure here
			const paymentMethod = res.length && res[0].is3dSecure && res[0]
			this.setState({
				step: !this.state.offer ? "DISPLAY" : "CONFIRMATION",
				paymentMethod: paymentMethod
			});
		})
		.catch( e => {
			this.setState({ step: "ERROR" });
		});
	};

	offerSelected = (offer) => {
		this.context.analytics('offerSelected', {offer});
		this.setState({offer, step:'CONFIRMATION'})
	};

	setPromoCode = (promo) => {
		this.setState({promo});
	};

	backToOffers = (event) => {
		event && event.preventDefault();
		this.fixModal();
		this.setState({ step : 'DISPLAY' });
	};

	goToPayment = (event) => {
		event && event.preventDefault();

		if(this.state.offer.price === 0)
			return this.proceedPayment(null);

		if(!this.state.promo) return this.setState({step : "PAYMENT"});

		let price = this.props.applyPromo(this.state.offer.price, this.state.promo);
		if( price.after !== 0 )  return this.setState({step : "PAYMENT"});
		if( !this.state.promo.duration ) return this.proceedPayment(null);

		this.setState({step : "PAYMENT"});
	};

	changePaymentMethod = (event) => {
		event && event.preventDefault();
		this.setState({paymentMethod:null})
	};

	proceedPayment = (card) => {
		console.error('proceedPayment', card)
		if( card ) {
			card.secure = {
				BROWSERCOLORDEPTH: window.screen.colorDepth,
				BROWSERJAVAENABLED: navigator.javaEnabled(),
				BROWSERLANGUAGE: navigator.language,
				BROWSERSCREENHEIGHT: window.screen.height,
				BROWSERSCREENWIDTH: window.screen.width,
				BROWSERTIMEZONE: new Date().getTimezoneOffset(),
				BROWSERUSERAGENT: navigator.userAgent,
			};

		}

		this.setState({ step:'PASS_CREATION', card});
		let subscriptionToCreate = {
			offer : {
				id : this.state.offer.id
			},
			promo : !this.state.promo ? null : {
				code : this.state.promo.code
			},
			card : card,
		};

		this.context.API.subscriptionCreate(subscriptionToCreate)
		.then( res => {
			if(this.state.offer.price === 0) {
				this.context.analytics('trial');

			} else {
				this.context.analytics('subscribe', {
					transaction: {
						id: Date.now(),
						value: this.state.offer.price/100
					}
				});
			}
			let body = document.getElementsByTagName('body')[0];
			body.style.overflow = "visible";
			let header = document.getElementsByClassName('Header')[0];
			header.style.zIndex = 1000;
			window.location.href = res.location;
		})
		.catch( e => {
			if( e.restCode === '3dSecureRequired') {
				this.setState({step:'3dSecure', html3dSecure: e.redirectURL})
				return;
			}
			console.error(e);
			this.setState({step:'ERROR', errorMsg: e.userMsg });
		})
		;
	};

	filterOffers = () => {
		let firstTimeEver = !this.context.user.subscriptions.all.length;
		let choices = this.props.offers
		.filter(o => !o.restrictions.firstSubOnly || firstTimeEver)
		.filter(o => {
			if (!o.restrictions.corporate) return true;
			return this.state.promo && this.state.promo.corporate && this.state.promo.corporate.id === o.restrictions.corporate.id;
		})
		.filter(o => {
			if ( !this.state.promo || !this.state.promo.sponsor ) return true;
			return this.state.promo && this.state.promo.sponsor && this.state.promo.sponsor.id && o.price >= 3500;
		})
		.filter(o => {
			if ( !this.state.promo || !this.state.promo.restrictions.offers ) return true;
			return this.state.promo.restrictions.offers.some(r => +r.id === +o.id )
		});
		return choices;
	};

	fixModal = () => {
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "visible";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1000;
	};

	goToMedical = () => {
		this.setState({
			step: 'MEDICAL'
		});
	};

	render(){
		if (this.state.step === 'LOADING')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR" msg={this.state.errorMsg}/>;

		if (this.state.step === 'DISPLAY') {
			let choices = this.filterOffers();

			return (
				<div className="Subscribe">
					<PromoCodeForm type='entreprise' promo = {this.state.promo} onSelect={this.setPromoCode} />
					<div className="SelectPlan">
						<h1>Formules</h1>
						<OfferSelector applyPromo={this.props.applyPromo} promo={this.state.promo} choices={choices} onSelect = {this.offerSelected}/>
					</div>
				</div>
			);
		}

		if(this.state.step === 'CONFIRMATION')
			return <Confirmation applyPromo={this.props.applyPromo} offer={this.state.offer} promo={this.state.promo} setPromoCode = {this.setPromoCode} confirm={this.goToMedical} back={this.backToOffers}/>;

		if(this.state.step === 'MEDICAL')
			return <MedicalCheck after={this.goToPayment} />;

		if (this.state.step === 'PAYMENT') {
			const pm = this.state.paymentMethod;
			if( pm && pm.id) {
				return <PaymentUseCurrentCardModal
					paymentMethod={pm}
					confirm={this.proceedPayment}
					changeOffer={this.backToOffers}
					changeCard={this.changePaymentMethod}
				/>
			} else {
				return <PaymentFormCompo
					back={this.backToOffers}
					showCGV={true}
					onSubmit={this.proceedPayment}
					offer = {this.state.offer}
					paymentMethod={this.state.paymentMethod}
				/>
			}
		}

		if (this.state.step === '3dSecure') {
			return (
				<Modal
					title="Vérification de votre carte"
					className="PaymentForm"
					show={true}
				>
					<p><strong>Vous allez être redirigé vers l'authentification 3d</strong></p>
					<a className='callToAction' href={this.state.html3dSecure}>Poursuivre la transaction</a>
				</Modal>
			);
		}
		if (this.state.step === 'PASS_CREATION')
			return <MsgPage type="LOADER" title="Création du pass..."/>;

		if (this.state.step === 'PASS_CREATED') {
			return <Redirect to='/activateSub-success' />
		}

		return null;
	}
}

class Confirmation extends Component {

	constructor({offer}) {
		super();
		this.state = {
			checked: false,
			checked_student: !offer.student_only
		};
	}

	componentDidMount() {
		this.fixModal();
	}

	fixModal = () => {
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;
	};

	render() {
		let {offer, promo, applyPromo} = this.props;
		return (
			<Modal title="Récapitulatif"
				   cancelFn={this.props.back}
				   cancelLb="Changer d'offre"
				   confirmFn={this.props.confirm}
				   confirmDisabled={!this.state.checked || !this.state.checked_student}
				   confirmLb="Suivant"
				   show={true}
				   className="OfferConfirmationModal"
			>
				<OfferSelector applyPromo={applyPromo} promo={promo} current={offer} choices={[]}/>
				{offer.price !== 0 && <PromoCodeForm type='promo' promo={promo} offer={offer} onSelect={this.props.setPromoCode} />}
				<label style={{display:'flex', flexDirection:'row', marginTop:'1rem', alignItems:'baseline', color: this.state.checked ? 'inherit' : 'red'}}>
					<input type='checkbox' checked={this.state.checked} onChange={()=>this.setState({checked: !this.state.checked})} />
					<span>En cochant cette case j'atteste sur l'honneur que je ne présente aucune contre-indication médicale et physique à la pratique d'activités sportives.</span>
				</label>
				{offer.student_only && <label style={{display:'flex', flexDirection:'row', marginTop:'1rem', alignItems:'baseline', color: this.state.checked_student ? 'inherit' : 'red'}}>
					<input type='checkbox' checked={this.state.checked_student} onChange={()=>this.setState({checked_student: !this.state.checked_student})} />
					<span>En cochant cette case j'atteste sur l'honneur que je suis bien étudiant et je m'engage à envoyer les pièces justificatives le prouvant, juste après mon inscription.</span>
				</label>}
			</Modal>
		);
	}
}


class PromoCodeForm extends Component {

	static contextTypes = {
		API: PropTypes.object,
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			field: {
				name: 'code',
				placeholder: "XXXX",
				label: 'Code promo',
				value: ''
			},
			pending: false,
			error : false
		};
	}

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.field.value = event.target.value;
		this.setState(newState);
	};

	cancel = (event) => {
		event.preventDefault();
		this.props.onSelect(null);
	};

	check = (event) => {
		event.preventDefault();
		this.setState({error:false, pending:true});
		this.context.API.promoGet(this.state.field.value)
		.then(promo=> {
			if(promo.corporate && this.props.type === 'promo'){
				return Promise.reject({ userMsg: "Ce code est un code entreprise" });
			}
			if (!promo.corporate && this.props.type === 'entreprise'){
				return Promise.reject({ userMsg: "Ce code n'est pas un code entreprise" });
			}
			if (promo.sponsor && this.props.offer.price < 3500) {
				return Promise.reject({ userMsg: "Ce code n'est pas valable avec cette offre" });
			}
			if( this.props.offer && promo?.restrictions?.offers?.length && promo.restrictions.offers.every( o => o.id != this.props.offer.id) ) {
				return Promise.reject({ userMsg: "Ce code n'est pas valable avec cette offre" });
			}
			this.setState({pending:false});
			this.props.onSelect(promo);
		})
		.catch(e => {
			var errorMsg = e.userMsg || 'Une erreur est survenue';
			this.setState({
				pending: false,
				error: errorMsg
			});
		});
	};

	render(){
		let {promo} = this.props;
		if(promo){
			return (
				<section className="promo-code-confirm">
					<div>
						<p>
							Code <strong>{promo.code}</strong>
							{promo.sponsor && <span> - parrainage</span>}
							{promo.corporate && <span> - entreprise : {promo.corporate.name}</span>}
						</p>
						<p>{promo.description}</p>
					</div>
					{!promo.corporate &&
					<button onClick={this.cancel}>
						<img src={closeIcon} alt="x"/>
					</button>
					}
				</section>
			);
		}

		let {pending, error} = this.state;

		return (
			<form onSubmit={this.check} className="PromoCodeForm">
				<strong>Vous avez un code {this.props.type} ?</strong>
				<div>
					<FormField name="code" onChange={this.onChange} field={this.state.field} disabled={pending}/>
					{!pending &&
					<input type='submit' onClick={this.check} value='Vérifier' disabled={pending}/>
					}
				</div>
				{pending &&
				<p>Vérification en cours...</p>
				}
				{error &&
				<p className="promo-code-error">{error}</p>
				}
			</form>
		);
	}
}
