import React from 'react';

import './Feedbacks.scss';
import Img from "./utils/Img";
import moment from "moment";
import starIcon from './icons/picto-star.svg';
import starEmptyIcon from './icons/picto-star-empty.svg';

export default ({ feedback }) => {
	const getScoreText = (score) => {
		switch (score) {
			case 1:
				return <StarRating txt={"Vraiment bof"} score={score}/>;
			case 2:
				return <StarRating txt={"C'était bien"} score={score}/>;
			case 3:
				return <StarRating txt={"Au TOP !"} score={score}/>;
			default:
				return "";
		}
	};

	return (
		<article className={"feedbacks-details"}>
			<h5>{getScoreText(feedback.score)}</h5>
			<q>« {feedback.other} »</q>
			<p>
				<strong>{feedback.seance.name}</strong> à <strong>{feedback.seance.place.name}</strong>
			</p>
			<em>{moment(feedback.seance.start_date).format('ddd DD MMM YYYY HH:mm')}</em>
		</article>
	);
};

const StarRating = ({txt, score}) => {
	const starMap = {
		"star": starIcon,
		"starEmpty": starEmptyIcon
	};

	const rating = [];
	for (let i = 0; i < score; i++) {
		rating.push('star');
	}
	while (rating.length < 3) rating.push("starEmpty");

	return (
		<ul className={"review-stars-rating"}>
			{!!txt && <li>{txt}</li> }
			{
				rating.map((s, i) => (
					<li key={i}>
						<Img src={starMap[s]} alt={s + "_" + i}/>
					</li>
				))
			}
		</ul>
	);
}
