import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {Link} from 'react-router-dom';
import FormField from "./FormField";
import MsgPage from "./utils/MsgPage";

import './SignIn.scss';


export default class SignIn extends React.Component {

	static contextTypes = {
		signIn: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		let params = queryString.parse(window.location.search);

		this.state = {
			pending: false,
			error: null,
			email: {
				type: 'email',
				autoFocus:  !params.email,
				placeholder: 'adresse@domaine.fr',
				label: 'E-mail',
				value: params.email || '',
				autoComplete: 'username email'
			},
			password: {
				type: 'password',
				autoFocus:  !!params.email,
				label: 'Mot de passe',
				placeholder: '*****',
				value: '',
				autoComplete: 'current-password'
			}
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.invalid = false;
		this.setState({[event.target.name] : field});
	};

	submit = (event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		let error = false;

		['email','password'].forEach(k=>{
			newState[k].value = newState[k].value.trim();
			if (newState[k].value === '') {
				newState[k].invalid = true;
				error = true;
			}
		});

		if (error) {
			this.setState(newState);
			return;
		}

		newState.pending = true;
		newState.error = null;
		this.setState(newState);

		this.context.signIn(this.state.email.value, this.state.password.value)
		.then(() => {
			this.props.onSuccess && this.props.onSuccess();
		})
		.catch(e => {
			if (e && e.restCode === 'authFailed') {
				return this.setState({
					pending: false,
					error: e
				});
			}
			this.setState({
				pending: false,
				error: e
			});
		})
		;
	};

	render() {
		if (this.state.pending) {
			return <MsgPage type='LOADER'/>;
		}
		return (
			<div className="SignIn">
				<h1>Connexion</h1>
				<form className='sign-in' onSubmit={this.submit}>
					{/** Not Ready <FacebookCoButton/>
					 <div className="separator" data-label="ou"/>
					 */}
					{['email', 'password'].map(f => {
						return <FormField key={f} field={this.state[f]} name={f} onChange={this.onChange}/>
					})}
					<Link to={`/forgotPassword?email=${this.state.email.value}`} className="pwd-forgot" onClick={this.props.forgot}>Oublié ?</Link>
					<DisplayError e={this.state.error} email={this.state.email.value} signUp={this.props.signUp}/>
					<input type='submit' className="submit-user-signin" value="Me connecter" />
					<Link to={`/signUp?email=${this.state.email.value}`} className="signup-user-link" onClick={this.props.signUp}>Je n'ai pas encore de compte</Link>
				</form>
			</div>
		);
	}
}

const DisplayError = ({e, email, signUp}) => {
	if (!e) return null;

	if (e.restCode === 'authFailed') {
		if (e.reason === 'badPassword')
			return <p className="form-error">Mot de passe incorrect</p>;
		if (e.reason === 'noSuchLogin')
			return <p className="form-error"><span>Cet e-mail n'est associé à aucun compte</span><span>Voulez-vous&nbsp;<Link to={`/signUp?email=${email}`} onClick={signUp}>créer un compte</Link> ?</span></p>;
		if (e.reason === 'blockedAccount')
			return <p className="form-error">Ce compte a été désactivé</p>;
	}

	return <p className="form-error">Erreur inattendue. Veuillez réessayer ultérieurement.</p>;
};
