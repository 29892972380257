import React, {Component} from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment/locale/fr';

import Modal from "./Modal";
import MsgPage from "./utils/MsgPage";

import './UnbookModal.scss';


export default class UnbookModal extends Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING' };
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		return this.setState({ step: 'OK' });
	};

	cancel = (event) => {
		event && event.preventDefault();
		this.props.onCancel();
	};

	unbook = (event) => {
		event.preventDefault();
		this.setState({
			step: 'PENDING',
			error: null
		});
		this.context.API.bookingCancel(this.props.booking.id)
		.then(resp => {
			this.props.onSuccess();
		})
		.catch(e => {
			let error = e.userMsg || "Une erreur est survenue";
			this.setState({
				step: 'FAILURE',
				error
			});
		});
	};

	render() {
		let {seance} = this.props;
		let {step, error} = this.state;

		if ( step === 'LOADING') return <MsgPage type='LOADER'/>;

		if ( step === 'PENDING') {
			return (
				<div className='UnbookModal'>
					<button disabled={true}>Annulation</button>
					<Modal
						title="Annulation"
						show={true}
					>
						<MsgPage type='LOADER' title={"En cours..."}/>
					</Modal>
				</div>
			);
		}

		if ( step === 'FAILURE') {
			return (
				<div className='UnbookModal'>
					<Modal
						title="Annulation"
						show={true}
					>
						<MsgPage type='ERROR' title="Annulation impossible"
								 msg={error}
								 confirmFunc={this.cancel} confirmLb="Fermer"
						/>
					</Modal>
				</div>
			);
		}

		return (
			<div className='UnbookModal UnbookModal_Failure '>
				<Modal
					show={true}
					title="J'annule"
					confirmFn={this.unbook}
					confirmLb="Confirmer"
					cancelFn={this.cancel}
					cancelLb="Annuler"
				>
					<p>
						<strong>{seance.name}</strong>
						<span>{seance.place.name}</span>
						<time dateTime={seance.starts}>{moment(seance.starts).locale('fr').format("dddd DD/MM - HH:mm")}</time>
					</p>
				</Modal>
			</div>
		);
	}
}
