import React, {Component, Fragment} from 'react';
import {Route, Redirect} from 'react-router-dom';

import SeanceLoader from './SeanceLoader';
import SeanceDisplay from './SeanceDisplay';
import Feedback from './Feedback';
import BookModal from './BookModal';
import PayBookModal from "./PayBookModal";
import UnbookModal from './UnbookModal';
import MessageModal from './MessageModal';
import AttendeesModal from './AttendeesModal';
import SeanceWatcher from "./SeanceWatcher";
import PropTypes from "prop-types";

export default class Seance extends Component {

	render() {
		let {match} = this.props;
		return (
			<SeanceLoader id={match.params.seanceId}>
				{ ({seance, booking, messages, reload, messagesReload, watcher}) => {
					return <Fragment>
						<Route render = { (props) => <SeanceDisplay {...props} seance={seance} booking={booking} messages={messages} watcher={watcher} reload={reload}/> }/>
						<Route path={`${match.path}/feedback`} render = { (props) => {
							if (!booking)
								return <Redirect to={match.url} />;

							return <FeedbackPage booking={booking} reload={reload} redirectPage={match.url} />
						}}/>
						<Route path={`${match.path}/book`} render = { (props) => {
							if (booking) return <Redirect to={match.url} />;
							return <BookingPage seance={seance} reload={reload} redirectPage={match.url} history ={this.props.history}/>
						}}/>
						<Route path={`${match.path}/paybook`} render = { (props) => {
							if (booking) return <Redirect to={match.url} />;
							return <PayBookingPage seance={seance} reload={reload} redirectPage={match.url}/>
						}}/>
						<Route path={`${match.path}/attendees`} render = { (props) => {
							return <AttendeesPage seance={seance} redirectPage={match.url}/>
						}}/>
						<Route path={`${match.path}/unbook`} render = { (props) => {
							if (!booking) return <Redirect to={match.url} />;
							return <UnbookingPage booking={booking} seance={seance} reload={reload} redirectPage={match.url}/>
						}}/>
						<Route path={`${match.path}/watcher`} render = { (props) => {
							return <WatcherPage seance={seance} reload={reload} redirectPage={match.url}/>
						}}/>
						<Route path={`${match.path}/messages`} render = { (props) => {
							return <MessagePage messages={messages} messagesReload = {messagesReload} seance={seance} redirectPage={match.url} />
						}}/>
					</Fragment>
				}}
			</SeanceLoader>
		);
	}
}

class MessagePage  extends Component {

	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;
		return <MessageModal {...this.props} onClose={this.redirect}/>
	}
}

class BookingPage  extends Component {

	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {seance, reload} = this.props;
		return <BookModal seance={seance} onSuccess={reload} onCancel={this.redirect} history={this.props.history}/>
	}
}

class PayBookingPage  extends Component {

	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {seance, reload} = this.props;
		return <PayBookModal seance={seance} onSuccess={reload} onCancel={this.redirect}/>
	}
}

class WatcherPage  extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {redirect:false};
	}

	static contextTypes = {
		user: PropTypes.object,
		signInNeeded: PropTypes.func
	};

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {seance, reload} = this.props;
		let {user, signInNeeded} = this.context;

		return (<SeanceWatcher
			seance={seance}
			user={user} signInNeeded={signInNeeded}
			onSuccess={reload} onCancel={this.redirect}
		/>);
	}
}

class AttendeesPage  extends Component {
	
	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {seance} = this.props;
		return <AttendeesModal seance={seance} onClose={this.redirect}/>
	}
}

class UnbookingPage  extends Component {
	
	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {seance, booking, reload} = this.props;
		return <UnbookModal booking = {booking} seance={seance} onSuccess={reload} onCancel={this.redirect}/>
	}
}

class FeedbackPage extends Component {
	
	constructor(props) {
		super(props);
		this.state = {redirect:false};
	}

	componentDidMount(){
		this.setState({redirect:false});
	}

	redirect = () => {
		this.setState({ redirect: this.props.redirectPage});
	};

	render() {
		if(this.state.redirect) return <Redirect to={this.state.redirect} />;

		let {booking,reload} = this.props;

		return <Feedback 
				booking={booking}
				onCreated ={reload}
				onClose  ={ this.redirect }
				onInvalid={ this.redirect }
			/>
	}
}
