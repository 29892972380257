import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';

import Modal from "./Modal";

import sendIcon from "./icons/send--light.svg";

import './MessageModal.scss';
import MessageList from "./MessageList";

export default class MessageModal extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props){
		super(props);
		this.state = { text : '' };
	}

	componentDidMount() {
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;
	}

	onClose = (event) => {
		event && event.preventDefault();
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "visible";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1000;
		this.props.onClose();
	};

	onChange = (event) => {
		this.setState({ text: event.target.value });
	};

	submit = (event) => {
		event.preventDefault();
		if ( !this.state.text.trim() ) return;

		this.setState({ sending: true });

		this.context.API.messageCreate({
			seance: {
				id: this.props.seance.id
			},
			message: this.state.text
		})
		.then(() => {
			this.setState({
				sending: false,
				text: ''
			});
			this.props.messagesReload();
		})
		.catch(e=>{
			console.error(e);
			this.setState({sending: false});
		});
	};

	render() {
		let {messages} = this.props;
		let {user} = this.context;
		return (
			<div className="MessageModal">
				<Modal className="messages-modal"
					   show={true}
					   title="Messages"
					   confirmFn={this.onClose}
					   confirmLb="Fermer"
				>
					<div>
						{!messages.length &&
						<p>Aucun message pour l'instant</p>
						}
						<MessageList messages={messages} />
						{user &&
						<div className="NewMessage">
							<textarea placeholder='Votre message' value={this.state.text} onChange={this.onChange}/>
							{!this.state.sending ?
							<button onClick={this.submit} disabled={this.state.text === ''}>
								<img src={sendIcon} alt="Envoyer"/>
							</button>
								:
							<span>...</span>
							}
						</div>
						}
					</div>
				</Modal>
			</div>
		);
	}
}
