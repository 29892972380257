import React, {Component} from 'react';
import Helmet from 'react-helmet';


import './Confidentialite.scss';

class Confidentialite extends Component {
	render() {
		return (
			<div className="Confidentialite">
				<Helmet>
					<title>Politique de confidentialité et cookies | Urban Challenge</title>
				</Helmet>
				<header>
					<h1>Politique de confidentialité et cookies</h1>
				</header>
				<section>
					<p>URBAN CHALLENGE est soucieuse de la protection des données personnelles des internautes (ci-après désignés l’« Utilisateur ») de son site internet https://urban-challenge.fr/ (ci-après désigné le « Site »).</p>
					<p>Dans le cadre de la navigation et de l’utilisation du Site, l’Utilisateur est susceptible de communiquer des données personnelles. URBAN CHALLENGE, en sa qualité de responsable de traitement, s’engage à protéger les données collectées et traitées sur le Site en conformité avec la règlementation applicable et notamment le Règlement (UE) n°2016/679 du 27 avril 2016 dit « Règlement Général sur la Protection des Données » ou « RGPD », et la loi n°78-17 du 6 janvier 1978 dite « Loi Informatique et Libertés » modifiée (ci-après désignée la « Règlementation applicable »).</p>
					<p>Pour toute question, l’Utilisateur peut contacter URBAN CHALLENGE :</p>
					<ol>
						<li> Par courriel : rgpd@urban-challenge.fr</li>
						<li> Par courrier postal : URBAN CHALLENGE, 88 avenue du Général Leclerc, 92100 BOULOGNE BILLANCOURT</li>
					</ol>
				</section>
				<section>
					<h1>1. Définitions</h1>
					<p>Les mots et expressions utilisés dans la présente politique ont le sens qui leur est donné par la Règlementation applicable, qu’ils soient utilisés au singulier ou au pluriel :</p>
					<ol>
						<li> <strong>Donnée Personnelle</strong> : désigne toute information se rapportant à une personne physique directement ou indirectement identifiée ou identifiable ;</li>
						<li> <strong>Personne concernée</strong> : désigne une personne physique identifiable qui peut être identifiée, directement ou indirectement, notamment par référence à un </li>identifiant, tel qu’un nom, un numéro d’identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou social.
						<li> <strong>Responsable de Traitement </strong>: désigne la personne physique ou morale, l’autorité publique, le service ou tout autre organisme qui, seul ou conjointement avec </li>d’autres, détermine les finalités et moyens du Traitement ;
						<li> <strong>d-traitant</strong> : désigne la personne physique ou morale, l’autorité publique, le service ou un autre organisme qui traite des Données Personnelles pour le </li>compte du Responsable de Traitement ;
						<li> <strong>Traitement</strong> : désigne toute opération ou tout ensemble d’opérations effectuées ou non à l’aide de procédés automatisés et appliquées à des données ou des </li>ensembles de Données Personnelles, telles que la collecte, l’enregistrement, l’organisation, la structuration, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, la diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, la limitation, l’effacement ou la destruction.
					</ol>
				</section>

				<section>
					<h1>Données Personnelles collectées et finalités du Traitement</h1>
					<p>URBAN CHALLENGE est susceptible de traiter les Données Personnelles suivantes sur la base du consentement de l’Utilisateur (art. 6.1.a RGPD), pour les besoins de son activité et l’exécution d’un contrat (art. 6.1.b RGPD), le respect d’une obligation légale ou règlementaire (art. 6.1.c RGPD) et l’intérêt légitime (art 6.1.f RGPD):</p>
					<ol>
						<li><strong>Aux fins de contact de l’Utilisateur adhérent d’une salle de sport</strong>
							<ol>
								<li>Entreprise ;</li>
								<li>Texte libre</li>
							</ol>
						</li>
						<li><strong>Aux fins de prise de contact de l’Utilisateur dans le cadre d’un projet d’offre de sport</strong>
							<ol>
								<li>Données identifiantes (nom et prénom) ;</li>
								<li>Données professionnelles (intitulé du poste, nom et code postal de l’entreprise) ;</li>
								<li>Données de contact (adresse email professionnelle, numéro de téléphone) ;</li>
								<li>Nombre de collaborateurs et surface disponible pour le projet ;</li>
								<li>Texte libre.</li>
							</ol>
						</li>
						<li><strong>Aux fins de gestion d’une candidature spontanée ou à un poste à pourvoir</strong>
							<ol>
								<li>Données identifiantes (nom et prénom) ;</li>
								<li>Données de contact (adresse email et numéro de téléphone) ;</li>
								<li>Poste recherché ;</li>
								<li>Type de poste (salarié, stage, freelance…) ;</li>
								<li>Texte libre ;</li>
								<li>Curriculum Vitae ;</li>
								<li>Lettre de motivation.</li>
							</ol>
						</li>
						<li><strong>Aux fins d’envoi de la newsletter</strong>
							<ol>
								<li>Adresse email</li>
							</ol>
						</li>
						<p>Par ailleurs, certains des formulaires contiennent une rubrique spéciale permettant à l’Utilisateur de choisir s’il souhaite être inscrit à la newsletter du Site. L’Utilisateur peut se désabonner de la newsletter à tout moment en cliquant sur le lien « Se désinscrire » qui se trouve en bas de toutes nos communications par e-mail.</p>
						<li><strong>Aux fins d’élaboration de statistiques de performance du Site</strong>
							<ol>
								<li>Données de navigation et de connexion ;</li>
								<li>Adresse IP.</li>
							</ol>
						</li>

						<p>Chaque formulaire limite la collecte des Données Personnelles au strict nécessaire et indique le caractère facultatif ou obligatoire des réponses par le biais d’un astérisque.</p>
						<p>Les informations renseignées par l’Utilisateur doivent être exactes et à jour. L’Utilisateur est invité à informer URBAN CHALLENGE dans le cas où ses Données Personnelles doivent être mises à jour.</p>
					</ol>
				</section>

				<section>
					<h1>Durée de conservation</h1>
					<p>URBAN CHALLENGE ne conserve les Données Personnelles des Utilisateurs que pendant le temps nécessaire aux opérations pour lesquelles elles ont été collectées et dans le respect de la Règlementation applicable.</p>
					<p>Les Données Personnelles collectées dans le cadre d’une candidature sont conservées pendant le temps nécessaire au recrutement. Les Données Personnelles des candidats non retenus à l’embauche sont archivées pendant une durée de deux (2) ans afin de permettre à URBAN CHALLENGE, le cas échéant, de recontacter le candidat.</p>
					<p>Les Données Personnelles collectées par le biais des formulaires de contact seront conservées pendant un délai de trois (3) ans.</p>
					<p>Dans l’hypothèse où URBAN CHALLENGE et l’Utilisateur entreraient dans une relation contractuelle, les Données Personnelles seraient conservées pendant la durée nécessaire à la bonne gestion de cette relation contractuelle puis, à l’issue de ladite relation contractuelle elles seront  archivées pendant la durée légale de prescription, soit cinq (5) ans, aux fins de preuve pour la constatation, l’exercice ou la défense d’un droit en justice.</p>
				</section>
				<section>
					<h1>Destinataires</h1>
					Dans le cadre d’une politique stricte de gestion des accès et de la confidentialité, seuls les destinataires dûment habilités par URBAN CHALLENGE peuvent accéder aux informations que l’Utilisateur a pu communiquer.
				</section>
				<section>
					<h1>Destinataires internes</h1>
					Les Données Personnelles collectées pourront être traitées par les services internes suivants : Direction, Marketing, Ressources Humaines, Service Informatique, Service des Opérations.
				</section>
				<section>
					<h1>Sous-traitants et prestataires externes</h1>
					<p>Les Données Personnelles collectées peuvent également être transmises à des Sous-traitants et prestataires de URBAN CHALLENGE, dans les limites prévues par la Règlementation applicable et conformément à la présente Politique, notamment aux fins de garantir une expérience optimale aux Utilisateurs dans le cadre de leur visite sur le Site.</p>
					<p>Ces Sous-traitants peuvent être amenés à traiter ces Données Personnelles pour le compte de URBAN CHALLENGE, selon ses instructions, notamment dans le cadre du paiement en ligne, de la publicité, de la sécurité, ou encore dans le cadre de statistiques et d’enquêtes.</p>
					<table>
						<thead>
							<tr><th>Identié du sous-traitant</th><th>Qualité</th></tr>
						</thead>
						<tbody>
							<tr><td>AWS</td><td>Hébergeur du site</td></tr>
							<tr><td>E-Audiences</td><td>Gestionnaire du site</td></tr>
							<tr><td>Mailjet</td><td>Gestionnaire de l'emailing (newsletter)</td></tr>
							<tr><td>E-Audiences</td><td>Logiciel de gestion de club</td></tr>
							<tr><td>Ogone</td><td>Paiement en ligne</td></tr>
						</tbody>
					</table>
				</section>
				<section>
					<h1>Tiers autorisés par la loi, telles que les autorités judiciaires ou administratives</h1>
					<ol>
						<li>
							<strong>Transmission anonymisée</strong>
							<p>URBAN CHALLENGE pourra partager avec des tiers autres que ceux identifiés des données anonymisées ou agrégées, à des fins statistiques, sans qu’il soit possible pour ces tiers d’identifier la personne concernée de quelque façon que ce soit.</p>
						</li>
						<li>
							<strong>Transfert et hébergement des Données Personnelles</strong>
							<p>Afin de délivrer et garantir une qualité de service optimale sur le Site, URBAN CHALLENGE peut être amenée à procéder à des transferts de Données Personnelles hors du territoire de l’Union Européenne.</p>
						</li>
					</ol>
					<p>Dans ce cas, URBAN CHALLENGE garantit que lesdits transferts sont exécutés vers des Etats qui font l’objet d’une décision d’adéquation par la Commission européenne, justifiant d’un niveau de protection adéquat au sens de l’article 45 du RGPD.</p>
					<p>A défaut de décision d’adéquation, URBAN CHALLENGE peut transférer des Données Personnelles hors de l’Union Européenne à des Sous-traitants dans les conditions prévues à l’article 46 du RGPD, notamment par application des clauses contractuelles types approuvées par la Commission européenne.</p>
				</section>
				
				<section>
					<h1>Mesures de sécurité mises en œuvre</h1>
					<p>URBAN CHALLENGE s’engage à faire ses meilleurs efforts pour assurer la confidentialité, la sécurité et l’intégrité des Données Personnelles de l’Utilisateur.</p>
					<p>A ce titre, URBAN CHALLENGE met en œuvre et maintien des mesures techniques et organisationnelles de sécurité du Site et de son système d’information adaptées au regard de la nature des Données Personnelles et des risques présentés par leur Traitement.</p>
					<p>Ces mesures visent à :</p>
					<ol>
						<li>Protéger les Données Personnelles de l’Utilisateur contre leur destruction, perte, altération, divulgation à des tiers non autorisés,</li>
						<li>Assurer le rétablissement de la disponibilité des Données Personnelles de l’Utilisateur et l’accès à celles-ci dans des délais appropriés en cas d’incident physique ou technique.</li>
					</ol>
					<p>Plus particulièrement, URBAN CHALLENGE met en place les mesures de sécurité adéquates et conformes aux règles de l’art, et notamment : une gestion des habilitations et des accès aux Données Personnelles, une protection accrue du réseau informatique et des serveurs…</p>
					<p>Ces mesures, testées régulièrement, font l’objet d’une documentation interne mise à jour de manière périodique.</p>
				</section>
				<setion>
					<h1>Droits de l’Utilisateur</h1>
					<p>Conformément à la Règlementation applicable, l’Utilisateur peut exercer à tout moment ses droits d’accès, de rectification, de portabilité et de suppression des Données Personnelles le concernant, mais également des droits de limitation ou d’opposition au Traitement, en s’adressant à URBAN CHALLENGE via l’adresse email suivante : rgpd@urban-challenge.fr.  </p>
					<p>L’Utilisateur dispose également du droit de saisir toute autorité de contrôle compétente, telle que la CNIL, s’il estime qu’un Traitement de ses Données Personnelles enfreint les exigences de la Règlementation applicable.</p>
					<p>URBAN CHALLENGE se réserve la possibilité de solliciter toute information de la part de l’Utilisateur avant de fournir les éléments relatifs à sa demande, et notamment : ses nom et prénom, son adresse email, son numéro de téléphone, un justificatif d’identité, l’objet de sa demande.</p>
					<p>URBAN CHALLENGE est tenue de répondre à l’Utilisateur dans un délai de trente (30) jours maximum, sauf dans le cas où un nombre important de demandes est simultanément opéré, ou lorsque les recherches des informations nécessitent un délai supplémentaire.</p>
				</setion>

				<section>
					<h1>Gestion des cookies</h1>
					<p>Un cookie est un fichier texte susceptible d’être enregistré sur l’ordinateur, la tablette ou le smartphone d’un internaute, à l’occasion de la consultation et de l’utilisation d’un site internet. Le stockage de cookies sur le terminal d’un internaute peut nécessiter, ou non, son consentement 
					préalable. Les cookies exemptés de consentement préalable sont ceux qui sont strictement nécessaires au bon fonctionnement du Site.</p>
					<ol>
						<li>Les cookies qui nécessitent une demande de consentement sont, par exemple  :</li>
						<li>Les cookies liés aux opérations relatives à la publicité  ;</li>
						<li>Les cookies des réseaux sociaux générés par les boutons de partage  ;</li>
						<li>Certains cookies de mesure d’audience.</li>
					</ol>
					<p>A partir de l’onglet « Réglages » présent sur le bandeau cookies du Site, l’Utilisateur peut bloquer le  dépôt de certains cookies, tout en sachant que certaines fonctionnalités du Site ne seront plus  accessibles ou altérées. Les cookies utilisés sur le Site, sont conservés pendant six mois maximum. L’Utilisateur est informé de la possibilité de désactiver et/ou effacer les cookies de son terminal grâce à la gestion des paramètres de son navigateur.</p>
				</section>
				<section>
					<h1>Modification de la Politique</h1>
					<p>La présente Politique est susceptible d’être modifiée en fonction du développement du Site, des  services de URBAN CHALLENGE, mais également en cas d’évolution légale, jurisprudentielle, des  décisions et recommandations de la CNIL ou des usages.</p>
				</section>
				<section>
					<p>La version de la Politique de confidentialité est celle applicable au jour de l’utilisation du Site, et plus  généralement de l’utilisation des services par l’Utilisateur.</p>
					<p>Version à jour au 13/04 2023.</p>
				</section>
			</div>
		);
	}
}

export default Confidentialite;
