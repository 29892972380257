import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import { NavLink } from 'react-router-dom';

import BasicSearch from './utils/BasicSearch';
import MsgPage from "./utils/MsgPage";
import SeanceList from "./SeanceList";
import DaySelector from "./DaySelector";
import loadOnlyPartials from './loadOnlyPartials';
import XCircle from "./icons/x-circle.svg";
import Img from "./utils/Img";

import './Planning.scss';
import './SearchTools.scss';

export default class Planning extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			step: 'LOADING',
			seances: [],
			showFilters: false,
			displayAll: this.props.displayAll,
			filters: {
				selectedDay: moment().format('YYYY-MM-DD'),
				showSeances: true,
				showEvents: true,
				showLive: true,
				coach: null,
				place: null
			}
		}
	};

	componentDidMount() {
		this.load();
	}

	load = () => {
		this.setState({step: 'LOADING'});

		let {API, repo} = this.context;
		let fromDate = moment().subtract(2, 'w').toDate().getTime();
		API.seanceSearch({from: fromDate})
		.then(seances => {
			seances = repo.put(seances);
			seances = seances.sort((a, b) => a.starts - b.starts);

			let placeLoad = loadOnlyPartials(API.placeGet, repo, seances.map(s => s.place));
			let corporateLoad = loadOnlyPartials(API.corporateGet, repo, seances.map(s => [s.corporate, s.restriction.corporate]).flat());
			let coachesLoad = loadOnlyPartials(API.coachGet, repo, seances.map(s => s.coaches).flat());

			let match = seances.filter(s => {
				if ( this.props.coach && !s.coaches.some( c => +c.id === this.props.coach.id) ) return false;
				if ( this.props.places && !this.props.places.some(p => s.place.id === p.id)) return false;
				if ( this.props.corporate && (!s.corporate || s.corporate.id !== this.props.corporate.id)) return false;
				if ( !this.props.corporate && s.restriction.corporate ) return !!this.props.skipSeanceCorporateRestriction;
				return true;
			});

			let displayed = match;

			if (!this.props.displayAll)
				displayed = displayed.filter(s => moment(s.starts).format('YYYY-MM-DD') === this.state.filters.selectedDay);

			return Promise.all([placeLoad, corporateLoad, coachesLoad])
			.then(() => {
				this.setState({
					step: 'OK',
					seances,
					match,
					displayed
				});
			});
		})
		.catch(e => {
			console.error('Planning error', e);
			this.setState({step: "ERROR" });
		});
	};

	selectedDay = (day) => {
		if (day !== this.state.filters.selectedDay) {
			let newState =  this.state;
			newState.filters.selectedDay = day;
			newState.displayed = this.applyFilters(newState.filters);
			if(this)
				this.setState(newState);
		}
	};

	applyFilters = (filters) => {
		let match = this.state.match.filter(s => {

			if (filters.course && !(s.name.toLowerCase() === filters.course.name.toLowerCase()) ) return false;
			if (filters.selectedDay && !(moment(s.starts).format('YYYY-MM-DD') === filters.selectedDay)) return false;
			if (!filters.selectedDay && moment(s.starts).format('YYYY-MM-DD') < moment().subtract(1, 'days').format('YYYY-MM-DD')) return false;
			if (filters.place && !(s.place.id === filters.place.id)) return false;
			if (filters.coach) {
				let coachDisplaySeance = false;
				s.coaches.forEach(c => {
					if(c.id === filters.coach.id)
						coachDisplaySeance =  true;
				});
				if(!coachDisplaySeance) return false;
			}
			if (!filters.showSeances && filters.showLive && !(!!s.place.is_live)) return false;
			if (filters.showSeances && !filters.showLive && (!!s.place.is_live)) return false;
			if (!filters.showLive && !filters.showSeances && !(!!s.event)) return false;
			else if (!filters.showLive && !filters.showEvents && (!!s.event)) return false;

			return true;
		});
		return match;
	};

	unSelectCoach = (e) => {
		e.preventDefault();
		let newState =  this.state;
		newState.displayAll = false;
		newState.filters.selectedDay = moment().format('YYYY-MM-DD');
		newState.filters.coach =  null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	unSelectPlace = (e) => {
		e.preventDefault();
		let newState =  this.state;
		newState.displayAll = false;
		newState.filters.selectedDay = moment().format('YYYY-MM-DD');
		newState.filters.place =  null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	unSelectCourse = (e) => {
		e.preventDefault();
		let newState =  this.state;
		newState.displayAll = false;
		newState.filters.selectedDay = moment().format('YYYY-MM-DD');
		newState.filters.course =  null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	coachSearch = (searchValue) => {
		return this.context.API.coachSearch()
		.then(res => {
			return res.filter(e => e.firstname.toLowerCase().includes( searchValue.toLowerCase() ) )
			.map(r => ({ ...r, display: `${r.firstname} ${r.lastname.slice(0,2)}.` }) );
		});
	};

	placeSearch = (searchValue) => {
		return this.context.API.placeSearch()
		.then(res=> {
			return res.filter(e => e.name.toLowerCase().includes( searchValue.toLowerCase() ))
			.map(r => ({ ...r, display: `${r.name}`}) );
		});
	};

	courseSearch = (searchValue) => {
		return this.context.API.seanceSearch()
		.then(res => {
			return res.filter(e => e.name.toLowerCase().includes( searchValue.toLowerCase() ))
			.map(r => ({ ...r, display: r.name }) );
		})
		.then(ret => {
			let uniqu = [];
			ret.forEach(el => {
				if (!uniqu.find(e => e.name === el.name)) uniqu.push(el);
			});
			return uniqu;
		});
	};

	coachSelectedSorting = (selectedCoach) => {
		let newState = this.state;
		newState.filters.coach = selectedCoach;
		newState.displayAll = true;
		newState.filters.selectedDay = null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	placeSelectedSorting = (selectedPlace) => {
		let newState = this.state;
		newState.filters.place = selectedPlace;
		newState.displayAll = true;
		newState.filters.selectedDay = null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	courseSelectedSorting = (selectedCourse) => {
		let newState = this.state;
		newState.filters.course = selectedCourse;
		newState.displayAll = true;
		newState.filters.selectedDay = null;
		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	updateSeanceType = (e) => {
		e.preventDefault();
		let newState = this.state;
		if(e.target.value === 'seance') {
			newState.filters.showSeances = true;
			newState.filters.showEvents = false;
			newState.filters.showLive = false;
			newState.displayAll = false;
			newState.filters.selectedDay = moment().format('YYYY-MM-DD');
		} else if (e.target.value === 'live') {
			newState.displayAll = true;
			newState.filters.showSeances = false;
			newState.filters.showLive = true;
			newState.filters.showEvents = false;
			newState.filters.selectedDay = null;
		} else {
			newState.displayAll = true;
			newState.filters.showSeances = false;
			newState.filters.showLive = false;
			newState.filters.showEvents = true;
			newState.filters.selectedDay = null;
		}

		newState.displayed = this.applyFilters(newState.filters);
		this.setState(newState);
	};

	showFilters = (e) => {
		e.preventDefault();
		this.setState({showFilters: !this.state.showFilters});
	};

	render() {
		let noResultMsg = this.state.displayAll ? 'Pas de séance à venir.' : 'Pas de séance à venir ce jour-là.';
		return (
			<div className="Planning">
				<div className="planningActions">
					<button className='showSeanceFilters' onClick={this.showFilters}>Filtres</button>
					<NavLink className='mapLink' to='/place'>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
								 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
							<polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
							<line x1="8" y1="2" x2="8" y2="18"></line>
							<line x1="16" y1="6" x2="16" y2="22"></line>
						</svg>
					</NavLink>
				</div>
				{this.state.showFilters && <div className='SeanceFilters'>
					<div className='SeanceTypeFilter'>
						<label>Type: </label>
						<button className = {this.state.filters.showSeances === true ? 'showSeanceType' : null} value='seance' onClick={this.updateSeanceType}>Séance</button>
						<button className = {this.state.filters.showEvents === true ? 'showSeanceType' : null} value= 'event' onClick={this.updateSeanceType}>Event</button>
						<button className = {this.state.filters.showLive === true ? 'showSeanceType' : null} value= 'live' onClick={this.updateSeanceType}>Visio</button>
					</div>
					<div className='SeanceCoachFilter'>
						<label>Coach: </label>
						{this.state.filters.coach ?
							<button onClick={this.unSelectCoach} className='removeSelectedCoachBtn'>
								{this.state.filters.coach.firstname} {this.state.filters.coach.lastname.slice(0,2)}.
								<Img className='removeCoachImg' src={XCircle} alt="Supprimer" lazySrc='TODO'/>

							</button>
							: <BasicSearch className='coachSearchFilter' autocomplete="off" search={this.coachSearch} onSelect={this.coachSelectedSorting}  searchOffset="2"/>}
					</div>
					<div className='SeancePlaceFilter'>
						<label>Lieu: </label>
						{this.state.filters.place ?
							<button onClick={this.unSelectPlace} className='removeSelectedPlaceBtn'>
								{this.state.filters.place.name}
									<Img className='removePlaceImg' src={XCircle} alt="Supprimer" lazySrc='TODO'/>
							</button>
							: <BasicSearch className='placeSearchFilter' autocomplete="off" search={this.placeSearch} onSelect={this.placeSelectedSorting} searchOffset="2"/>}
					</div>
					<div className='SeanceCourseFilter'>
						<label>Cours:</label>
						{this.state.filters.course ?
							<button onClick={this.unSelectCourse} className='removeSelectedPlaceBtn'>
								{this.state.filters.course.name}
								<Img className='removePlaceImg' src={XCircle} alt="Supprimer" lazySrc='TODO'/>
							</button>
							: <BasicSearch className='placeSearchFilter' autocomplete="off" search={this.courseSearch} onSelect={this.courseSelectedSorting} searchOffset="2"/>}
					</div>
				</div>}
				{!this.state.displayAll &&
				<SearchTools selected={this.state.filters.selectedDay} onSelect={this.selectedDay} />
				}
				{this.props.switch &&
				<SwitchTools switch={this.props.switch} label ={this.props.switchLabel} restricted={this.props.restricted}/>
				}
				{this.state.step === 'LOADING' &&
				<MsgPage type='LOADER' title="Chargement des cours"/>
				}
				{this.state.step === 'OK' &&
				<Fragment>
				{!!this.state.displayed.length ?
					<SeanceList key='seanceList' seances={this.state.displayed} displayDay={this.state.displayAll} />
					:
					<MsgPage type="EMPTY" title={noResultMsg} />
				}
				</Fragment>
				}
				{this.state.step === 'ERROR' &&
					<MsgPage type="ERROR" title="Une erreur est survenue."/>
				}
			</div>
		);
	}
}

class SearchTools extends Component {
	render = () => {
		return (
			<aside className='SearchTools'>
				<section>
					<DaySelector onSelect = {this.props.onSelect} selected = {this.props.selected}/>
				</section>
			</aside>
		);
	}
}

class SwitchTools extends Component {
	render = () => {
		let buttonMsg = this.props.restricted ? 'Voir les cours publics' : "Voir uniquement les cours de mon entreprise";
		return (

			<aside className="SwitchTools">
				<section>
					<p>{this.props.label}</p>
					<button onClick={this.props.switch}>{buttonMsg}</button>
				</section>
			</aside>
		);
	}
}
