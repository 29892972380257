import React, {Component, Fragment} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';

import MsgPage from "./utils/MsgPage";
import FormField from './FormField';

import './PasswordChange.scss';

export default class PasswordChange extends Component {

	static contextTypes = {
		API: PropTypes.object
	};


	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'INIT'
			,password: {
				type: 'password',
				label: 'Nouveau mot de passe',
				placeholder: '*****',
				value: '',
				autoComplete: 'new-password'
			}
		};
	}

	componentDidMount() {
		var code = queryString.parse(this.props.location.search).code;
		if(!code)
			this.setState({step:'NO_CODE'});
		else
			this.setState({step:'FORM', code:code});

	}

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.invalid = false;
		this.setState({[event.target.name] : field});
	};

	submit = (event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		let error = false;

		['password'].forEach(k=>{
			newState[k].value = newState[k].value.trim();
			if (newState[k].value === '') {
				newState[k].invalid = true;
				error = true;
			}
		});

		if (error) {
			this.setState(newState);
			return;
		}
		let code = this.state.code

		this.setState({step:'PENDING', error:null});
		this.context.API.passwordRecoveryUse(code, this.state.password.value)
		.then(res=>{
			this.setState({step:'OK'});
		})
		.catch(e=>{
			if(e.restCode === 'resourceNotFound'){
				return this.setState({step:'ERROR', userMsg:"Ce code est invalide"});
			}
			var userMsg = e.userMsg || 'Une erreur est survenue';
			this.setState({step:'ERROR', userMsg:userMsg});
			console.error(e);
		});
	};

	retry = (event) => {
		event.preventDefault();
		this.setState({step:'FORM'});
	};

	goToDashboard = (event) => {
		event.preventDefault();
		this.setState({step: 'DASHBOARD'});
	};

	render(){
		return (
			<Fragment>
				<Helmet key='helmet'>
					<meta name="robots" content='noindex,follow'/>
					<link rel="canonical" href="https://www.urban-challenge.fr/passwordChange" />
				</Helmet>
				{this.renderBase()}
			</Fragment>
		);
	}

	renderBase = () => {
		if (this.state.step ==='INIT')
			return <MsgPage type='LOADER' title="Chargement du code" />;

		if (this.state.step ==='NO_CODE')
			return <MsgPage type='WARNING' title="Le lien suivi n'est pas valide" />;

		if (this.state.step ==='ERROR')
			return <MsgPage type='ERROR' msg={this.state.userMsg} /> ;

		if (this.state.step ==='PENDING')
			return <MsgPage type='LOADER' title="Modification en cours" />;


		if(this.state.step === 'FORM'){
			return (
				<div className='PasswordChange'>
					<h1>Changement de mot de passe</h1>
					<form onSubmit={this.submit}>
						<p>Définissez votre nouveau mot de passe</p>
						<FormField field={this.state.password} name='password' onChange={this.onChange} />
						<button onClick={this.submit} className="submit-password-button">Valider</button>
					</form>
				</div>
			);
		}

		if (this.state.step === 'OK'){
			return <MsgPage type="SUCCESS" title="Changement effectué avec succès"
							msg="Votre changement de mot de passe a bien été pris en compte"
							confirmFunc={this.goToDashboard}
							confirmLb="Connexion"
			/>;
		}

		if (this.state.step === 'DASHBOARD') {
			return <Redirect to="/account"/>
		}
	};
}

