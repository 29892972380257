import React from 'react';

export default class HeapAnalytics extends React.Component {

	componentDidMount(){
		if(!this.props.config)
			return console.warn('[HeapAnalytics]', 'no config');
		
		if(!this.props.config.activated)
			return console.debug('[HeapAnalytics]', 'not activated');

		if(!this.props.config.siteId)
			return console.warn('[HeapAnalytics]', 'no siteId config');
		

		console.debug('[HeapAnalytics]', 'is mounting');

		var ref = window.document.getElementsByTagName("script")[0];
		var script = window.document.createElement("script");

		var text = 'window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};';
		text += 'heap.load("' + this.props.config.siteId + '");';

		script.text = text;
		ref.parentNode.insertBefore(script, ref);
	}

	render() {
		return this.props.children || null;
	}
}
