let listeners = [];



const ORIGIN = (
	window.location.origin
	|| `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
);

function request(path, method, _body) {
	const url = `${ORIGIN}/api${path}`;
	const body = JSON.stringify(_body);
	const headers = {
		'content-type': 'application/json',
	};

	return fetch(url, {body, headers, method, credentials: 'same-origin', })
	.then(response => {
		if (!response) Promise.reject({ statusCode: 0 });

		if (!response.ok) {
			if (response.status === 401) {
				listeners.forEach(l => l());
			}
			if (!response.headers.get('content-type').startsWith('application/json')) {
				return Promise.reject({
					statusCode: response.status,
					statusText: response.statusText
				});
			}
			
			return response.json()
			.then(e =>Promise.reject(e))
			;
		}

		if (response.status === 204 || response.status === 302 || response.status === 301) {
			return null;
		}

		return response.json();
	});
}


let API = {
	GET				: (path) 		=> request(path, "GET"),
	DELETE			: (path, body)	=> request(path, "DELETE", body),
	PATCH			: (path, body)  => request(path, "PATCH", body),
	POST			: (path, body)  => request(path, "POST", body),
	PUT				: (path, body)  => request(path, "PUT", body),
	onSessionLost	: (listener) 	=> listeners.push(listener),
	getUrl			: (path)		=> `${ORIGIN}/api${path}`
};


export default API;
