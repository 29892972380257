import React, {Component} from "react";
import Img from "./utils/Img";

import './ClientsList.scss';

import clients from './clients';

class ClientsList extends Component {

	render() {
		return (
			<div className="ClientsList">
				{!!clients.length ?
					<ul>
						{clients.map((c, i) =>
							<li key={`c${i}`}>
								<ClientPreview client={c}/>
							</li>
						)}
					</ul>
					:
					<p>Pas de clients</p>
				}
			</div>
		);
	}
}

export default ClientsList;

class ClientPreview extends Component {
	render() {
		let {client} = this.props;
		return (
			<article className="ClientPreview">
				<Img src={client.logoUrl} alt={client.name}/>
			</article>
		);
	}
}