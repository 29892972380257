import fb from './icons/facebook.svg';
import ig from './icons/instagram.svg';
import tw from './icons/twitter.svg';
import yt from './icons/youtube.svg';
import li from './icons/linkedin.svg';

export default [{
	name: "facebook",
	icon: fb,
	url: "https://www.facebook.com/UrbanChallengeFrance/"
}, {
	name: "linkedin",
	icon: li,
	url: "https://www.linkedin.com/company/1352266"
}, {
	name: "instagram",
	icon: ig,
	url: "https://instagr.am/urbanchallenge/"
}, {
	name: "twitter",
	icon: tw,
	url: "https://twitter.com/Urban_Challenge"
}, {
	name: "youtube",
	icon: yt,
	url: "https://www.youtube.com/user/UrbanChallengeTV/"
}];