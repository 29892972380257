import cryoBoost from './images/extern/cryoboost.png';
import rebook from './images/extern/rebook.jpg';
import chiroprateur from './images/extern/chiropracteur.jpg';
import powerbar from './images/extern/powerbar.png';
import novela from './images/extern/novela.png';
import tesstea from './images/extern/tesstea.png';

export default [{
		partner: 'Boutique Reebok Madeleine',
		txt: 'Bénéficiez de -15% sur présentation de votre compte Urban Challenge via votre mobile.\n'
			+'Retrouvez les meilleurs produits des gammes performance, les éditions limitées et les produits Reebok classics.\n'
			+'Partagez des moments privilégiés avec les athlètes et ambassadeurs de la marque.',
		promo: {
			value: 15,
			type: 'PERCENT'
		},
		logoUrl: rebook
	},
	{
		partner: 'Séance Chiropracteur',
		txt: 'Cécilia Naman pratique la Chiropraxie : une médecine manuelle de référence pour les soins du dos et des articulations. '
			+'La chiropraxie a pour objet la détection, le traitement et la prévention des dysfonctionnements du squelette et de ses conséquences, notamment au niveau de la colonne vertébrale et des membres.\n'
			+'Présentez votre abonnement via votre mobile.\n'
			+'Prenez rendez-vous à son cabinet 64 Rue Lhomond 75005 Paris. Son contact : cecilianaman@hotmail.com  / 06 82 10 68 72',
		promo: {
			value: 20,
			type: 'AMOUNT'
		},
		logoUrl: chiroprateur
	},
	{
		partner: 'Powerbar',
		txt: "-20% sur tous les produits en passant commande sur leur site : https://www.powerbar.eu/fr_FR/ avec le code URBANC20. "
			+"Depuis 1986, Powerbar développons des produits élaborés par des sportifs pour des sportifs.\n"
			+"Powerbar n'utilise que des ingrédients de haute qualité et attache une grande importance au goût.",
		promo: {
			value: 20,
			type: 'PERCENT'
		},
		logoUrl: powerbar
	},
	{
	partner: "SO TESSTEA - DELICIEUSEMENT EFFICACE !",
	txt: "C'est une gamme de thé 100% biologique spécialement pensée pour reprendre le"+
		"pouvoir sur votre corps.\n Minceur, détox, drainant, boost et énergie, notre priorité est de vous donner accès aux"+
		"bienfaits et multiples vertus des plantes et thés de notre gamme.\n"+
		"Avec le code CHALLENGEDETOX achetez votre pack pour 11€ au lieu de 13.90€.\n"+
		"Un programme sportif est aussi proposé : qui prend en compte le corps et son "+
		"bien-être. A faible ou forte intensité, en fonction de votre niveau.\nwww.sotesstea.fr",
	promo: {
		value: 15,
		type: 'PERCENT'
	},
	logoUrl: tesstea
	}, {
		partner: "Cryoboost",
		txt: "Cryoboost accompagne les adhérents Urban Challenge dans leurs entrainements. Les séances de cryothérapie permettent l'accélération de la récupération musculaire et soulage les blessures.\n" +
			"30 euros la séance au lieu de 45 euros sur présentation de votre carte membre Urban Challenge via votre smartphone.",
		promo: {
			value: 15,
			type: 'AMOUNT'
		},
		logoUrl: cryoBoost
	}, {
		partner: "Novela",
		txt: "La société Novela a été fondée par Thomas Boin, régulièrement présent au bootcamp de Martin Luther King. Pour consommer et vivre mieux, elle propose des produits écologiques, économiques et favorisant le bien-être.\n"+
			"Avec ses différents univers représentant votre quotidien, Novela propose entre autres, des pommeaux de douche réduisant la consommation d'eau et prenant soin de votre peau, des pailles en marc de café home compostables ou des tapis d'acupression.\n" +
			"De quoi être au top après une séance d'Urban tout en prenant soin de la planète.\n\n"+
			"Avec le code URBANC20, bénéficiez de 20% de remise immédiate sans minimum d'achat et sans frais de port.",
		promo: {
			value: 15,
			type: 'PERCENT'
		},
		logoUrl: novela
	}
];
