import React, { Component } from 'react';
import PropTypes from "prop-types";
import moment from "moment";

import './MessageList.scss';

export default class MessageList extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render() {
		let { messages } = this.props;
		let { user } = this.context;
		return (
			<ol className="MessageList">
				{messages.map(m => {
					let mine = user && user.id === m.creation.user.id;
					let coach = m.creation.user.roles.coach;
					let admin = m.creation.user.roles.admin;
					let className = '';

					if (mine) className = 'mine';
					else if (admin) className = 'admin';
					else if (coach) className = 'coach';

					return (
						<li key={m.id} className={className}>
							<article>
								<section>
									{!mine && (
										<span>
														{m.creation.user.firstname}
											{admin ? <em>Admin</em> : (coach ? <em>Coach</em> : '')}
													</span>
									)}
									<p>{m.text}</p>
									<time>{moment(m.creation.date).format('DD/MM/YYYY HH:mm')}</time>
								</section>
							</article>
						</li>
					);
				})}
			</ol>
		);
	}
}
