const MapType = [
	{
		"featureType": "administrative",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "landscape.man_made",
		"elementType": "all",
		"stylers": [
			{
				"saturation": "-100"
			}
		]
	},
	{
		"featureType": "landscape.man_made",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"lightness": "65"
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#00ea6f"
			}
		]
	},
	{
		"featureType": "poi.sports_complex",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#00ea6f"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#c9c8ca"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "off"
			},
			{
				"lightness": "12"
			}
		]
	}
];

export default MapType;