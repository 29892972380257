import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Helmet from "react-helmet";

import Planning from './Planning';

export default class SeanceSearch extends Component {

	static contextTypes = {
		user: PropTypes.object,
		analytics: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			restricted: true
		};
	}

	switch = (event) => {
		event.preventDefault();
		this.setState({restricted: !this.state.restricted});
	};

	render(){
		this.context.analytics('seances');

		let {user} = this.context;
		let current = user && user.subscriptions.current;
		let skipSeanceCorporateRestriction = user && user.admin;
		if ( !current || !current.corporate )
			return (
				<Fragment>
					<Helmet>
						<title>Planning des séances | Urban Challenge</title>
					</Helmet>
					<Planning skipSeanceCorporateRestriction={skipSeanceCorporateRestriction}/>
				</Fragment>
			);

		let switchLabel = '';
		if(current.restriction.corporate){
			switchLabel = 'Votre formule actuelle est limitée par votre entreprise'
		}

		return (
			<Fragment>
				<Helmet>
					<title>Planning des séances | Urban Challenge</title>
				</Helmet>
				{this.state.restricted &&
				<Planning switch={this.switch} switchLabel={switchLabel} restricted={this.state.restricted} displayAll={true}
						  places={current.restriction.places} corporate={current.restriction.corporate || current.corporate}
				/>
				}

				{!this.state.restricted &&
				<Planning switch={this.switch} switchLabel={switchLabel} restricted={this.state.restricted} />
				}
			</Fragment>
		);
	}
}
