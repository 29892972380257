import React from 'react';
import PropTypes from 'prop-types';

import MsgPage from "./utils/MsgPage";
import GoogleMapsDisplay from './GoogleMapsDisplay';
import GMapsPlaceDisplay from "./GMapsPlaceDisplay";

import "./PlaceMap.scss";

class PlaceMap extends React.Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING'
		};
	}

	componentDidMount(){
		this.load();
	}

	selectPlace = (place) => {
		this.setState({
			selected: {
				place: place,
				display: 'reduced'
			}
		});
	};

	reset = () => {
		this.setState({
			selected: {
				place: null,
				display: ''
			}
		});
	};

	changePlaceDisplay = () => {
		const viewSwitch = {
			reduced: 'full',
			full: 'reduced'
		};
		let newState = Object.assign({}, this.state);

		newState.selected.display = viewSwitch[this.state.selected.display];
		this.setState(newState);
	};

	reducePlace = () => {
		if (this.state.selected && this.state.selected.display !== '') {
			this.setState({
				selected: {
					place: this.state.selected.place,
					display: 'reduced'
				}
			});
		}
	};

	load = () => {
		this.setState({step : "LOADING"});
		this.context.API.placeSearch()
		.then( places => {
			places = this.context.repo.put(places);
			this.setState({step:'OK', places});
		})
		.catch( e => {
			this.setState({step : "ERROR"});
		});
	};

	render = () => {
		if (this.state.step === 'LOADING') {
			return <MsgPage type='LOADER' title="Chargement des lieux"/>;
		}

		if(this.state.step === 'ERROR') {
			return <MsgPage type="ERROR"/>
		}
		if (this.state.step === 'OK') {
			return (
				<div className="PlaceMap">
					{!!this.state.places.length &&
					<GoogleMapsDisplay items={this.state.places} onSelectMarker={this.selectPlace} onDragMap={this.reducePlace} reset={this.reset}/>
					}
					{this.state.selected &&
					<GMapsPlaceDisplay selected={this.state.selected} changeDisplay={this.changePlaceDisplay} />
					}
				</div>
			);
		}
	};
}

export default PlaceMap;
