import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import MainMenu from './MainMenu';

import './Header.scss';

import menu from './icons/menu--light.svg';

class Header extends React.Component {

	static contextTypes = {
		user: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			showMenu: false
		};
	}

	toggleMenu = () => {
		this.setState({showMenu: !this.state.showMenu});

		// To prevent scrolling through
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = !this.state.showMenu ? "hidden" : "visible";
	};
	render() {
		let className = 'Header';

		if (this.context.user && this.context.user.adminViewer)
			className += ' adminViewer';

		let desktopMenu = [{
			label: "Le Concept",
			link: "/concept"
		}, {
			label: "Nos formules",
			link: "/plans"
		}, {
			label: "Planning",
			link: "/seance"
		}, {
			label: "Lieux",
			link: "/place"
		}, {
			label: "Entreprises",
			link: "/enterprise"
		}, {
			label: "Contact",
			link: "/contact"
		}, {
			label: 'Blog',
			link: 'https://blog.urban-challenge.fr',
			external: true
		}];

		if ( this.context.user ) {
			desktopMenu.push({
				label: "Mon compte",
				link: "/account"
			});
		} else {
			desktopMenu.push({
				label: "Se connecter",
				link: "/account"
			});
		}

		return (
			<header className={className}>
				<section>
					<Link className="main-logo" to="/">Urban Challenge</Link>
					<nav className="desktop-nav">
						<ul>
							{desktopMenu.map((m, i) =>
							<li key={`desktop_${i}`}>
							{ !m.external ?
								<Link to={m.link}>{m.label}</Link> : <a href={m.link} target="_blank" rel="noopener noreferrer">{m.label}</a>
							}
							</li>
							)}
						</ul>
					</nav>
					<button className="show-menu" onClick={this.toggleMenu}>
						<img src={menu} alt="Menu icon"/>
					</button>
				</section>
				{this.state.showMenu &&
					<MainMenu toggleMenu={this.toggleMenu}/>
				}
			</header>
		);
	}
}

export default Header;
