import React from 'react';
import PropTypes from 'prop-types';

let googleMaps = {
	ready : false
};

export default class GoogleMaps extends React.Component {

	static childContextTypes = {
		googleMaps: PropTypes.object
	};

	getChildContext = () => {
		return {
			googleMaps: googleMaps
		};
	};

	componentDidMount(){
		let browserKey = this.props.browserKey;

		if (!browserKey) {
			console.warn("GoogleMaps: missing browserKey");
			return null;
		}
		
		window.googleMapsReady = this.googleMapsReady;
		let ref = window.document.getElementsByTagName("script")[0];
		let script = window.document.createElement("script");
		const src = `https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&callback=googleMapsReady&key=${browserKey}`;
		script.src = src;
		script.async = true;
		script.defer = true;
		ref.parentNode.insertBefore(script, ref);
	}

	googleMapsReady = () => {
		googleMaps.ready = true;
		googleMaps.autocompleteService = new window.google.maps.places.AutocompleteService();
		googleMaps.placeService = new window.google.maps.places.PlacesService(document.getElementById("gmapHidden"));
		googleMaps.maps = window.google.maps;
	};

	render() {
		return this.props.children;
	}
}
