import React from 'react';
import PropTypes from "prop-types";
import HeapAnalytics from './HeapAnalytics';
import Facebook from './Facebook';
import GoogleTagManager from './GoogleTagManager';

export default class Analytics extends React.Component {

	static childContextTypes = {
		analytics : PropTypes.func
	};

	getChildContext = () => {
		return {
			analytics       : this.eventAsync
		};
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			log  : !!props.config.log,
			heap : !!props.config.heap,
			facebook  : !!props.config.facebook,
			googleTagManager  : !!props.config.googleTagManager
		};
	}

	eventAsync = (type, params) => {
		setTimeout(()=>this.event(type,params), 0);
	};

	event = (type, params) => {
		if(this.state.heap){
			window.heap && window.heap.track(type, params);
		}
		if(this.state.facebook){
			window.fbq && window.fbq('track', type, params);
		}
		if(this.state.log){
			console.debug('[Analytics]', '::event::', type, params);
		}
		if(this.state.googleTagManager){
			var obj = Object.assign({}, {event:type}, params);
			window.dataLayer && window.dataLayer.push(obj);
		}
	};

	componentDidMount(){
		console.debug('[Analytics]', 'is mounting');
	}

	render() {
		return [
			  !this.state.heap ? null : <HeapAnalytics key='HeapAnalytics' config={this.props.config.heap} />
			, !this.state.facebook ? null : <Facebook key='Facebook' config={this.props.config.facebook} />
			, !this.state.googleTagManager ? null : <GoogleTagManager key='GoogleTagManager' config={this.props.config.googleTagManager} />
			, this.props.children
		];
	}
}
