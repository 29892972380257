import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import OfferSelector from './OfferSelector';

import "./Plans.scss";

class Plans extends Component {
	constructor() {
		super();
		const params = new URLSearchParams(window.location.search);
		this.state = {
			selected: params.get('offer'),
		};
	}

	applyPromo = (initialPrice, promo) => {
		let price = {
			initial: initialPrice,
			after: initialPrice
		};
		if (!promo) return price;

		if (promo.percentage)
			price.after = price.initial * (1 - promo.percentage / 100);
		else if (promo.value)
			price.after = price.initial - promo.value;

		price.after = Math.max(0, price.after);

		return price;
	};

	onSelect = (o) => {
		this.props.history.push(`/account/plan?offer=${o.id}`);
	};


	render() {
		let choices = this.props.offers.filter( o => !o.restrictions.corporate);
		return (
			<div className="Plans">
				<h1>Formules</h1>
				<OfferSelector choices={choices} applyPromo={this.applyPromo} onSelect={this.onSelect} selected={ this.state.selected } />
			</div>
		);
	}
}

export default withRouter(Plans);
