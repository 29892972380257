import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';

import '../node_modules/cropperjs/dist/cropper.css';
import './ImageModifiable.scss';
import MsgPage from "./utils/MsgPage";

export default class ImageModifiable extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {step:'LOADING'};
	}

	componentDidMount(){
		this.load();
	}

	load = () => {
		if(!this.props.image){
			return this.setState({step:'OK', image:null});;
		}

		this.setState({step:'LOADING'});
		this.context.API.imageSearch({id:this.props.image.id})
		.then(images=>{
			if(images.length)
				this.setState({step:'OK', image:images[0]});
			else
				this.setState({step:'OK', image:null});
		})
		.catch(e=> {
			console.error(e);
			this.setState({step:'ERROR', error:e});
		})
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({step:"ADDING", fileType:files[0].type});
	};

	cancel =  (e) => {
		e.preventDefault();
		this.setState({step:'OK'});
	};

	saveImage = (e) => {
		e.preventDefault();

		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}
		this.setState({step: "UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);
		
		this.setState({ cropResult: img, });

		var body = { image : img, for : this.props.imageFor };


		this.context.API.imageCreate(body)
		.then(image =>{
			this.props.onCreated(image);
		})
		.catch(e => {
			console.error(e);
			this.setState({step:'ERROR', error:e});
		})
		;
	};

	delete = (e) => {
		e.preventDefault();
		this.props.onDelete();
	};


	render(){
		if(this.state.step === 'ERROR')
			return <MsgPage type="ERROR" retryFunc={this.load}/>;

		if(this.state.step === 'LOADING')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'UPLOADING')
			return <MsgPage type="LOADER" title="Téléchargement en cours..."/>;

		if(this.state.step === 'DELETING')
			return <MsgPage type="LOADER" title="Suppression en cours..."/>;

		return (
			<div className="ImageModifiable">
				{this.state.step === 'ADDING' ?
				<Fragment>
					<Cropper
						className="cropper"
						aspectRatio={1}
						guides={false}
						src={this.state.src}
						ref={cropper => {this.cropper = cropper;}}
					/>
					<footer>
						<button className="button-save" onClick={this.saveImage}>Valider</button>
						<button className="button-cancel" onClick={this.cancel}>Annuler</button>
					</footer>
				</Fragment>
				:
				!this.state.image ?
					<label className="ImageUpload">
						<em>Vous n'avez pas encore d'avatar</em>
						<span>Uploader une photo</span>
						<input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
					</label>
					:
					<div className="ImageDisplay">
						<img src={this.state.image.src} alt="user avatar"/>
						<button data-image-id={this.state.image.id} onClick={this.delete}>Supprimer</button>
					</div>
				}
			</div>
		);
	}
}
