import React from 'react';
import {NavLink, Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import Img from './utils/Img';
import close from './icons/close--light.svg';
import menuLogo from './images/logo-menu.svg';

import './MainMenu.scss';

import mainMenu from './mainMenuItems';
import socialMenu from './socialMenu';
import footerMenu from './footerMenu';

const coachItem = {
	label : "Espace coach",
	url   : '/coaching'
};

const SwitchLinkItem = ({ link, user, toggleMenu }) => {
	const hasCorporateOffer = !!user ? !!user.subscriptions.current && user.subscriptions.current.offer.restrictions.corporate : null;

	if (!!link.forCorporate && !hasCorporateOffer) return null;

	return (<li>
		{link.external ?
			<a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a>
			:
			<NavLink to={link.url} activeClassName="active" onClick={toggleMenu}>{link.label}</NavLink>
		}
	</li>);
};

class MainMenu extends React.Component {

	static contextTypes = {
		user          : PropTypes.object
	};

	checkStandaloneMode = () => {
		return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
	};


	render(){
		const year = new Date().getFullYear();
		let displayWebApp = !this.checkStandaloneMode();

		return (
			<aside className='MainMenu'>
				<header>
					<button title="Fermer" className="close-menu" onClick={this.props.toggleMenu}>
						<img src={close} alt="Close icon"/>
					</button>
				</header>
				<section>
					<NavLink className='connectLink' to='/account' onClick={this.props.toggleMenu}>{this.context.user ? "Mon compte" : "Se connecter"}</NavLink>
					<div>
						<nav className="main-nav">
							<ul>
								{this.context.user && !!(this.context.user.adminViewer || this.context.user.admin) &&
									<li key='loginAs'>
										<NavLink to='/loginAs' onClick={this.props.toggleMenu}>Login As</NavLink>
									</li>
								}
								{this.context.user && !!this.context.user.coach &&
									<li key='forCoach'>
										<NavLink to={coachItem.url} onClick={this.props.toggleMenu}>{coachItem.label}</NavLink>
									</li>
								}
								{mainMenu.main.map((m, i) => {
									if (!!m.forGuest && !!this.context?.user?.subscriptions.current) return null;
									return (
										<SwitchLinkItem key={`main_${i}`} link={m} user={this.context.user} toggleMenu={this.props.toggleMenu}/>
									);
								})}
								{!!this.context.user &&
								<li key='referral'>
									<SwitchLinkItem key={`main_referral`} link={{ label: 'Obtenez 10€', url: '/account/referral' }} user={ this.context.user } toggleMenu={this.props.toggleMenu} />
								</li>
								}
							</ul>
						</nav>
						<nav className="secondary-nav">
							<ul>
								{mainMenu.secondary.map((m, i) =>
									<SwitchLinkItem key={`footer_${i}`} link={m} user={ this.context.user } toggleMenu={this.props.toggleMenu} />
								)}
								{displayWebApp && <li key={`footer_webapp`}>
									<NavLink to='/webapp' activeClassName='active' onClick={this.props.toggleMenu}>Application web</NavLink>
								</li>}
							</ul>
						</nav>
						<nav className="contact-nav">
							<h4>Contact</h4>
							<ul>
								<li>
									<a href="mailto:info@urban-challenge.fr">info@urban-challenge.fr</a>
								</li>
								<li>
									<a href="tel:+33184743200">01 84 74 32 00</a>
								</li>
							</ul>
						</nav>
					</div>
					<nav className="social-nav">
						<ul>
							{socialMenu.map((m, i) =>
								<li key={`social_${i}`}>
									<a href={m.url} target="_blank" rel="noopener noreferrer" className={m.name}>
										<Img lazySrc='todo' src={m.icon} alt={m.name}/>
									</a>
								</li>
							)}
						</ul>
					</nav>
					{/* Do not use Img component for this file - it breaks its visual behavior */}
					<img src={menuLogo} alt="Menu logo" className="logo-menu"/>
				</section>
				<footer>
					<nav className="footer-nav">
						<ul>
							{footerMenu.map((m, i) =>
								<li key={`main_${i}`}>
									<Link to={m.url} onClick={this.props.toggleMenu}>{m.label}</Link>
								</li>
							)}
						</ul>
					</nav>
					<p>{year} &copy; Urban Challenge</p>
				</footer>
			</aside>
		);
	}
}

export default MainMenu;
