import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import loadOnlyPartials from './loadOnlyPartials';
import Modal from './Modal';
import MsgPage from "./utils/MsgPage";

import "./Feedback.scss";
import Img from "./utils/Img";
import appIcon from "./images/app-logo.svg";

const improvmentsList = {
	'+varied' : 'Variée',
	'+dynamic' : 'Dynamique',
	'+convivial' : 'Conviviale',
	'+hardway' : 'Difficile',
	'-hardway' : 'Facile',
	'other' : 'Autre'
};

const intensityList = {
	'-easy' : 'Trop facile',
	'=good' : 'Bonne intensité',
	'+hard' : 'Trop intense',
};

const scoreChoices = {
	1 : {display : "Vraiment bof", question: "La séance aurait dû être beaucoup plus"},
	2 : {display : "C'était bien", question: "La séance aurait pu être un peu plus"},
	3 : {display : "Au TOP !"}
};

export default class Feedback extends Component {
	
	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING' };
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		let {API, repo} = this.context;
		let {booking} = this.props;

		if ( !booking ) return this.setState({ step : "REDIRECT" });

		this.setState({ step: 'LOADING' });

		return Promise.all([
			loadOnlyPartials(API.feedbackGet, repo, [booking.feedback]),
			loadOnlyPartials(API.seanceGet, repo, [booking.seance])
		])
		.then(all => {
			let p = [];
			if (!!booking.seance.coaches)
				booking.seance.coaches.forEach(c => {
					p.push(loadOnlyPartials(API.coachGet, repo, [ c ]))
				});
			//Don't care if no coach
			return Promise.all(p).catch( () => {} );
		})
		.then( () => {
			return this.setState({ step: 'OK' });
		})
		.catch( e => {
			console.error(e);
			return this.setState({ step: 'ERROR' });
		});
	};


	render() {
		let {onCreated, onClose, onInvalid, booking} = this.props;
		let {step} = this.state;

		if ( step === "REDIRECT" ) return onInvalid() ;
		if ( step === "LOADING" )	return <MsgPage type="LOADER" title="Chargement du cours..."/> ;
		if ( step === "ERROR" ) return <MsgPage type="ERROR" title="Erreur au chargement..."/> ;

		let {feedback} = booking;
		if (feedback)
			return <ModalFeedbackShow feedback={feedback} onClose={onClose}/>;

		return <ModalFeedbackForm booking={booking} onSkip={onClose} onCreated={onCreated} otherSeance={this.props.otherSeance}/>;
	}
}

class ModalFeedbackShow extends Component {

	render() {
		let {other, score, improvments} = this.props.feedback;

		return (
			<Modal
				className="booking-feedback"
				show={true}
				title="Votre avis sur la séance"
				confirmFn={null}
				confirmLb={null}
				cancelFn={this.props.onClose}
				cancelLb='Fermer'
			>
				<div className='Feedback'>
					<Score value={score} choices={scoreChoices} onChange={null} />
					{scoreChoices[score] && scoreChoices[score].question && 
						<Improvment label={scoreChoices[score].question} selected={improvments} choices={improvmentsList} switch={null}/>
					}
					{ !!other && <div>{other}</div> }
				</div>
			</Modal>
		);
	}
}

class ModalFeedbackForm extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step : "ASKING" };
	}

	componentDidMount() {
		this.setState({ step : "ASKING" });
	}

	submit = (v) => {
		this.setState({ step : 'UPDATING' });
		v.booking = { id : this.props.booking.id };
		return this.context.API.feedbackCreate(v)
		.then( res => {
			this.props.onCreated();
		})
		.catch( e => {
			console.error(e);
			this.setState({ step : 'ERROR' })
		})
	};


	render() {
		let {step} = this.state;
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;

		return (
			<Modal
				className="booking-feedback"
				show={true}
				title="Donnez votre avis"
			>
				{step === "ERROR" &&
				<MsgPage type="ERROR" title="Erreur lors de l'enregistrement..."/>
				}
				{step === 'UPDATING' &&
				<MsgPage type='LOADER' title="En cours d'envoi"/>
				}
				{step === 'ASKING' &&
				<FeedbackForm booking={this.props.booking} submit={this.submit} cancel={this.props.onSkip} otherSeance={this.props.otherSeance}/>
				}
			</Modal>
		);
	}
}

class FeedbackForm extends Component {

	constructor(props) {
		super(props);
		this.state = {
			score : null,
			improvments : [],
			other: null,
			difficulty: '',
			error: {}
		};
	}

	difficultyChange = (difficulty) => {
		this.setState({
			difficulty: difficulty,
			score: this.state.score,
			improvments :  this.state.improvments,
			other : this.state.other,
			coach : this.state.coach
		});
	};
	scoreChange = (score) => {
		let {coaches} = this.props.booking.seance;
		this.setState({
			score,
			improvments : [],
			other : null,
			coach : coaches.length > 1 ? null : ((!!coaches[0] && coaches[0].id) || 'no_coach'),
			error: { score: !score }
		});
	};

	switchImprovment = (improvment) => {
		let improvments = this.state.improvments.indexOf(improvment) === -1
			? this.state.improvments.concat([improvment])
			: this.state.improvments.filter( c => c !== improvment )
		;
		let {other} = this.state;
		if( improvments.indexOf('other') === - 1 ) {
			other = null;
		} else if (improvment === 'other') {
			other = '';
		}
		this.setState({ improvments, other });
	};

	textChange = (event) => {
		this.setState({ other : event.target.value });
	};

	selectCoach = (event) => {
		this.setState({ coach : event });
	};

	submit = (e) => {
		e.preventDefault();
		if (!this.props.otherSeance) {
			let body = document.getElementsByTagName('body')[0];
			body.style.overflow = "visible";
			let header = document.getElementsByClassName('Header')[0];
			header.style.zIndex = 1000;
		}
		let {score, improvments, other, coach, difficulty} = this.state;
		if (!score) {
			this.setState({ error: { score: !score } });
			return;
		}
		this.props.submit({score, improvments, other, coach, difficulty});
	};

	cancel = (e) => {
		e.preventDefault();
		if (this.props.otherSeance) {
			return this.props.cancel();
		}
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "visible";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1000;
		this.props.cancel();
	};

	render() {
		let { score, coach, difficulty } = this.state;
		let { booking } = this.props;

		return (
			<div className='FeedbackForm'>
				{this.props.otherSeance && <div>
					<p>Qu'avez-vous pensé de votre dernière séance ?</p>
					<p><em className="InlineText">
						Votre note & commentaire sont <strong className="InlineText">anonymes</strong>, signez dans le commentaire si vous souhaitez vous faire connaitre par le coach
					</em></p>
				</div>
				}
				<SeancePreview seance={booking.seance} />

				<Score value={score} choices={scoreChoices} onChange={this.scoreChange} error={ this.state.error.score } />

				<Improvment label="Intensité :" choices={ intensityList } selected={ difficulty } switch={this.difficultyChange} />

				{booking.seance.coaches.length > 1 &&
					<CoachSelect value={ this.state.coach } seance={ booking.seance } onChange={ this.selectCoach } />
				}
				{!!coach &&
				<textarea value={this.state.other} onChange={this.textChange} placeholder="Laissez-nous un commentaire !"/>
				}
				<footer>
					<input type='submit' onClick={this.submit} value="Envoyer" className={ !score ? "disabled" : "" } />

					{this.props.cancel &&
					<button onClick={this.cancel} className="cancel-feedback-button">Plus tard</button>
					}
				</footer>
			</div>
		);
	}
}

class CoachSelect extends Component {

	onChange = (value, e) => {
		e.preventDefault();
		let val = +value.id;

		if (value.id === "no_coach")
			val = "no_coach";

		this.props.onChange && this.props.onChange(val);
	};

	render() {
		let {seance, value} = this.props;
		return (
			<article className='CoachSelect'>
				<p>C'était avec : </p>
				<ul>
					{ (!!seance.coaches && seance.coaches.length > 1) &&
						<li className={ value === "no_coach" ? 'active' : '' } onClick={ this.onChange.bind(this, { id: "no_coach" }) }>
							<Img src={ appIcon } alt={`Avatar_all`} lazySrc="todo"/>
							<span>Tous</span>
						</li>
					}
					{ !!seance.coaches && seance.coaches.map(c => {
						return (
							<li key={c.id} className={value === +c.id ? 'active' : ''} onClick={ this.onChange.bind(this, c) }>
								<Img key={c.firstname} src={c.avatar || appIcon} alt={`Avatar_${c.id}`} lazySrc="todo"/>
								<span>{c.firstname}</span>
							</li>
						);
					})}
				</ul>
			</article>
		);
	}
}

class Score extends Component {

	onChange = (e) => {
		e.preventDefault();
		let value = +e.target.dataset.value;
		this.props.onChange && this.props.onChange(value);
	};

	render() {
		let { value, choices} = this.props;

		return (
			<div className={`Score`}>
				<ul>
				{Object.keys(choices).map( s => {
					let display = choices[s].display;
					return <li key={s} data-value={s} className={ `${value === +s ? 'active' : ''} ${this.props.error ? "onError" : ""}` } onClick={this.onChange}>{display}</li>
				})}
				</ul>
			</div>
		);
	}
}

class Improvment extends Component {

	switch = (e) => {
		e.preventDefault();
		let code = e.target.dataset.code;
		this.props.switch && this.props.switch(code);
	};

	render() {
		let { label, selected, choices} = this.props;

		return (
			<div className='Improvment'>
				<p>{label}</p>
				<ul>
					{Object.keys(choices).map( k => {
						let display = choices[k];
						let className = '';
						if ( selected.indexOf(k) !== - 1 )
							className = 'active';

						return <li key={k} data-code={k} className={className} onClick={this.switch}>{display}</li>;
					})}
				</ul>
			</div>
		);
	}
}

class SeancePreview extends Component {

	render() {
		let {seance} = this.props;
		let dateFormat = 'DD/MM - HH:mm';
		return (
			<article className="SeancePreview">
				{seance.coaches &&
				<header className="multiple-coaches">
					{seance.coaches.map(c =>
						<Img key={c.firstname} src={c.avatar || appIcon} alt="Avatar" lazySrc="todo"/>
					)}
				</header>
				}
				<section>
					<div>
						<span>{moment(seance.starts).format(dateFormat)}</span>
						<h3>{seance.name}</h3>
					</div>
					<div>
						<strong>{seance.place.name}</strong>
						{seance.coaches && !!seance.coaches.length && 
							<span>
								{ seance.coaches.map( c => c.firstname ).join(' & ') }
							</span>
						}
					</div>
				</section>
			</article>
		);
	}
}
