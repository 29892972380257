import React, {Component} from 'react';
import Helmet from 'react-helmet';

import './SanitaryCrisis.scss';
import covid19Logo from "./images/challenges/yoga.jpg";

class SanitaryCrisis extends Component {
	render() {

		return (
			<div className="SanitaryCrisis">
				<Helmet>
					<title>Précautions COVID-19 | Urban Challenge</title>
				</Helmet>
				<header>

					<img src={covid19Logo} alt="UC COVID-19"/>
					<div>
						<h1>
							<small>
								Précautions COVID-19
							</small>
						</h1>
					</div>
				</header>

				<ul>
					<li>
						<h2>MESURES BARRIÈRES SUR LES COURS EN EXTÉRIEUR URBAN CHALLENGE</h2>
					</li>
					<li>
						<div>Concernent les séances Bootcamps, Running, Yoga</div>
						<div>
							<section>
								<div>
									<ol className="ListedContent">
										<li><p>
											<strong>=></strong> Distance barrière entre les participants :
										</p></li>
										<ul style={{ paddingLeft: '2rem', margin: '0rem .5rem 1rem .5rem' }}>
											<li>-  10m pour la pratique de la course à pied</li>
											<li>-  pour les autres activités, prévoir un espace de 4 m² minimum pour chaque pratiquant en statique ou 2m entre chaque pratiquant en dynamique.</li>
											<li>-  pas de contact entre le coach et les adhérents.</li>
										</ul>
										<li><strong>=></strong> Yoga : chacun vient avec son tapis</li>
									</ol>
								</div>
							</section>
						</div>
					</li>
					<li>
						<h2>Pas de vestiaires / Sac à dos recommandé :</h2>
						<div>
							<section>
								<p>
									Vestiaires fermés jusqu’à nouvel ordre, chacun arrive en tenue et est responsable de ses affaires. Chacun doit gérer ses affaires et ne pas toucher celles des autres.
									Un petit sac a dos par exemple est recommandé, que vous pourrez déplacer au cours de la séance et garder près de vous lors des exercices au sol.
								</p>
							</section>
						</div>
					</li>
					<li>
						<h2>A emporter pour votre séance</h2>
						<div>
							<section>
								<ol className="ListedContent">
									<li><strong>=></strong> Son tapis (si yoga)</li>
									<li><strong>=></strong> Une gourde individuelle</li>
									<li><strong>=></strong> Son gel hydroalcoolique</li>
									<li><strong>=></strong> Son sac à dos</li>
								</ol>
								<em>Merci de prévoir votre attestation de déplacement lorsqu’elle est nécessaire.</em>
							</section>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

export default SanitaryCrisis;
