import React from "react";
import PropTypes from "prop-types";

export default class Geolocation extends React.Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			current: null
		};
	}

	static childContextTypes = {
		geolocation       : PropTypes.object
	};

	getChildContext = () => {
		return {
			geolocation       : {
				current : this.state.current,
				set     : this.set
			}
		};
	};

	componentDidMount() {
		try {
			let location = localStorage.getItem('zp-location');
			if (location) {
				location = JSON.parse(location);
				this.setState({current: location});
			}
		}
		catch(e) {
			console.error('Local storage error', e, e.stack);
		}
	}

	set = (current) =>{
		this.setState({current});
		try {
			localStorage.setItem('zp-location', JSON.stringify(current))
		}
		catch(e) {
			console.error('Local storage error', e, e.stack);
		}
	};

	render () {
		return this.props.children;
	}
}