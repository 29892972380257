import React, {Component} from 'react';

import './Modal.scss';

class Modal extends Component {
	render() {
		const style = this.props.style  || {};
		return (
			<dialog className='Modal' open={this.props.show} style={style}>
				<div className="modal-mask"/>
				<div className={`modal-content${this.props.className ? ` ${this.props.className}` : ''}`}>
					<header>{this.props.title}</header>
					<section>
						{this.props.children}
						{(this.props.confirmFn || this.props.cancelFn) &&
						<footer>
							{this.props.confirmFn &&
							<button type='submit' className='modal-confirm-button'
									disabled={!!this.props.confirmDisabled}
									onClick={this.props.confirmFn}>{this.props.confirmLb || 'Valider'}</button>
							}
							{this.props.otherFn &&
							<button type='submit' className='modal-cancel-button'
									onClick={this.props.otherFn}>{this.props.otherLb}</button>
							}
							{this.props.cancelFn &&
							<button className='modal-cancel-button'
									onClick={this.props.cancelFn}>{this.props.cancelLb || 'Annuler'}</button>
							}
						</footer>
						}
					</section>
				</div>
			</dialog>
		)
	}
}

export default Modal;