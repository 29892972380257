import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import Helmet from "react-helmet";
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';

import Img from './utils/Img';
import MsgPage from "./utils/MsgPage";

import './SeanceDisplay.scss';

import messageIcon from './icons/message-square.svg';
import appIcon from './images/app-logo.svg';
import calendarIcon from './icons/calendar-add.svg';
import defaultImg from './images/hero.jpg';

class SeanceDisplay extends Component {

	static contextTypes = {
		API: PropTypes.object,
		user: PropTypes.object
	};

	codeFor = (cannotBook) => {
		switch(cannotBook.code){
			case 'past' : return 'Séance passée';
			case 'unavaible' : return 'Séance non disponible';
			case 'placeVacation' : return 'Club temporairement fermé';
			case 'full' : return 'Complet';
			case 'noMoreAvailable' : return 'Trop tard pour réserver';
			case 'notYetAvailable' : return 'Bientot réservable';
			default: return 'Réservation impossible';
		}
	};

	stopPropa = (e) => {
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
	};

	goBack = () => {
		this.props.history.goBack();
	};

	pastSession = (startDate) => {
		return moment() > moment(startDate);
	};

	render() {
		let {seance, booking, messages, watcher} = this.props;
		let img = defaultImg;
		if (seance.place.searchImage) img = seance.place.searchImage.path;
		if (seance.imageUrl) img = seance.imageUrl;

		let duration = moment.duration(moment(seance.ends).diff(moment(seance.starts)));

		let attendees = null;
		if (seance.bookings.zippy.count) {
			if (this.context.user) {
				attendees = <Link to={`/seance/${seance.id}/attendees`}>{seance.bookings.zippy.count} participants</Link>;
			} else {
				attendees = <strong>{seance.bookings.zippy.count} participants</strong>;
			}
		} else {
			attendees = <strong>Il n'y a aucun participant pour le moment</strong>;
		}

		return (
			<div className="SeanceDisplay">
				
				<Helmet>
					<title>Séance du {moment(seance.starts).locale('fr').format("DD/MM - HH:mm")} : {seance.name} | Urban Challenge</title>
					<link rel="canonical" href={`https://www.urban-challenge.fr/seance/${seance.id}`} />
				</Helmet>

				<header>
					<button onClick={this.goBack}>Retour</button>
					<Img src={img} lazySrc='TODO' alt="Workout"/>
					<h1>
						{seance.name}
						<small>{seance.place.name}</small>
					</h1>
				</header>
				<aside>
					<div className="seance-date">
						<time dateTime={seance.starts}>
							<span>{moment(seance.starts).locale('fr').format("dddd DD/MM")}</span>
							<span>{moment(seance.starts).locale('fr').format("HH:mm")} - {duration / (60 * 1000) + "min"}</span>
						</time>
						{booking &&
							<a href={this.context.API.getUrl(`/calendar/${seance.id}`, {appName:'uc'})} download data-seance-id={seance.id} className="add-seance-calendar" onClick={this.stopPropa}>
								<img src={calendarIcon} alt="Calendrier"/>
							</a>
						}
					</div>
					{booking && seance.booking_price <= 0 &&
					<Link to={`/seance/${seance.id}/unbook`} className='unBooking-button'>J'annule</Link>
					}
					{booking && seance.booking_price > 0 &&
					<button disabled={true} className='cannot-book-button'>Réservé</button>
					}
					{!!seance.public && !booking && !seance.cannotBook && seance.booking_price <= 0 &&
					<Link to={`/seance/${seance.id}/book`} className='booking-button'>Je réserve</Link>
					}
					{!!seance.public && !booking && !seance.cannotBook && seance.booking_price > 0 &&
					<Link to={`/seance/${seance.id}/paybook`} className='booking-button'>Je réserve pour {seance.booking_price / 100}€</Link>
					}
					{!!seance.public && !booking && seance.cannotBook && !seance.replay_video_link &&
					<button disabled={true} className='cannot-book-button'>{this.codeFor(seance.cannotBook)}</button>
					}
					{!!seance.public && !booking && seance.cannotBook && (seance.cannotBook.code === 'past' && !!seance.replay_video_link) &&
					<Link to={`/seance/${seance.id}/book`} className='booking-button'>J'accède au replay</Link>
					}

					{ watcher.length >= 1 &&
					<Link to={`/seance/${seance.id}/watcher`} className='watcher-book-link'>
						Je ne souhaite plus être informé·e si une place se libère
					</Link>
					}
					{ !!seance.public && seance.cannotBook && !booking && !this.pastSession(seance.starts) && watcher.length <= 0 &&
					<Link to={`/seance/${seance.id}/watcher`} className='watcher-book-link'>
						Je souhaite être informé·e si une place se libère
					</Link>
					}

					<section>
						{attendees}
						<div className='SeanceMessages'>
							<Link className='message-button' to={`/seance/${seance.id}/messages`}>
								<img src={messageIcon} alt="Messages"/>
								<span>{messages.length < 10 ? messages.length : '9+'}</span>
							</Link>
						</div>
					</section>

					{seance.restriction.corporate &&
					<strong>Réservé aux employés de {seance.restriction.corporate.name}</strong>
					}

					{!!seance.public && booking && seance.video_link && !seance.replay_video_link &&
					<a className='seance-video-link' href={seance.video_link} target={'_blank'} rel="noopener noreferrer">
						<em>Cliquez ici pour la séance visio en DIRECT</em>
					</a>
					}
					{!!seance.public && booking && seance.video_link && seance.replay_video_link &&
					<a className='seance-video-link' href={seance.replay_video_link} target={'_blank'} rel="noopener noreferrer">
						<em>Cliquez ici pour le REPLAY</em>
					</a>
					}

				</aside>
				<section>
					<h2>Déroulement</h2>
					<div className="richText" dangerouslySetInnerHTML={{__html: seance.description}}/>
				</section>
				<section>
					<h2>Infos pratiques</h2>
					<div className="richText" dangerouslySetInnerHTML={{__html: seance.practical ? seance.practical : seance.place.practical}}/>
				</section>
				{!!seance.coaches.length && <section>
					<h2>{seance.coaches.length > 1 ? "Vos coachs" : "Votre coach"}</h2>
					{seance.coaches && seance.coaches.map(c =>
						<CoachInfos coach={c} key={c.id}/>
					)}
				</section>
				}
				<section>
					<h2>Lieu d'entrainement</h2>
					<PlaceInfos place={seance.place}/>
				</section>
			</div>
		);
	}
}

class PlaceInfos extends Component {
	render = () => {
		let {place} = this.props;
		return (
			<article className="PlaceInfos">
				<header>
					<Img src={place.searchImage ? place.searchImage.path : defaultImg} alt={place.name} lazySrc='TODO'/>
				</header>
				<section>
					<h3>{place.name}</h3>
					<em>Voir le lieu</em>
					<Link to={`/place/${place.id}`} aria-label={`Voir le lieu ${place.name}`}/>
				</section>
			</article>
		);
	}
}

class CoachInfos extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step: "LOADING",
			coach: this.props.coach
		};
	}

	componentDidMount(){
		if (this.state.coach.__state === 'OK') {
			return this.setState({step:'OK'})
		}
		this.context.API.coachGet(this.props.coach.id)
		.then( coach => {
			coach = this.context.repo.put(coach);
			return this.setState({ step : "OK", coach });
		})
		.catch(e => {
			console.error(e);
			this.setState({step:"ERROR"});
		});
	}

	render = () => {
		let {step, coach} = this.state;
		
		if ( step === 'LOADING' )	return <MsgPage type="LOADER" title="Chargement du cours..."/>;
		if ( step === 'ERROR' )		return <MsgPage type="ERROR" title="Erreur au chargemnt..."/>;

		return (
			<article className="CoachInfos">
				<Img src={coach.avatar || appIcon} alt={coach.firstname} lazySrc='TODO'/>
				<div>
					<h3>{coach.firstname}</h3>
					<span>Voir son profil</span>
				</div>
				<Link to={`/coach/${coach.id}`} aria-label={`Voir le profil de ${coach.firstname}`}/>
			</article>
		);
	}
}

export default withRouter(SeanceDisplay);
