import React, {useState, useEffect, Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import MsgPage from "./utils/MsgPage";
import SeanceList from "./SeanceList";
import ClientsList from "./ClientsList";
import loadOnlyPartials from './loadOnlyPartials';
import GoogleMapsDisplay from "./GoogleMapsDisplay";
import LiveMoreInfo from "./LiveMoreInfo";
import FeedbacksDetails from "./FeedbacksDetails";

import './Landing.scss';

import hero from './images/landing-hero.jpg';
import API from "./api/FunctionnalAPI";

class Landing extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		let callToAction =  {
			url: '/plans',
			label: 'Séance découverte'
		};

		if (this.context.user && this.context.user.subscriptions.current) {
			callToAction = {
				url: '/seance',
				label: 'Choisir une séance'
			}
		}

		return (
			<div className="Landing">
				<Helmet key='helmet'>
					<link rel="canonical" href="https://www.urban-challenge.fr" />
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<header className="landing-hero">
					<video playsInline autoPlay muted loop>
						<source src={`${process.env.PUBLIC_URL}/home.mp4`} type='video/mp4'/>
					</video>
					{/* <img src={hero} alt="Hero"/> */}
					<div>
						<h1>
							<span>
								Coaching sportif<br/>
								en plein air
							</span>
							<small>
								par des sportifs de haut niveau & des sapeurs pompiers
							</small>
						</h1>
						<Link to={callToAction.url}>{callToAction.label}</Link>
					</div>
				</header>
				<section className="landing-marketing">
					<h2>Vivez une expérience unique</h2>
					<div>
						<p>
							<strong>Leader du coaching en extérieur</strong>, nous avons recruté des coachs issus du
							corps des <strong>Sapeurs-Pompiers</strong> et <strong>athlètes de haut niveau</strong>,
							pour vous offrir une expérience unique de <strong>dépassement de soi</strong> et de <strong>
							cohésion en groupe</strong>
						</p>
					</div>
				</section>

				<section className="landing-description">
					<h2>Découvrez nos sessions</h2>
					<ul>
						<LiveAndReplay />
						<li>
							<h3>BootCamp</h3>
							<p>
								Alternance de cardio intensif et de renforcement musculaire : bras, jambes, abdos, fessiers…
							</p>
						</li>
						<li>
							<h3>Cours à Thème</h3>
							<p>
								Corde à sauter, circuit training, cardio-boxing, yoga…
							</p>
						</li>
						<li>
							<h3>Urban Running</h3>
							<p>
								Entraînements dédiés au perfectionnement de la course à pied pour toutes vos courses, trails…
							</p>
						</li>
					</ul>
				</section>

				<section className="big-title landing-search">
					<h2>Prochaines séances</h2>
					<LandingMap />
				</section>

				<Feedbacks />

				<section className="landing-cta">
					<h2>Découvrez à prix avantageux</h2>
					<div>
						<p>
							Choisissez le créneau de votre choix et commencez votre Urban Challenge
						</p>
						<Link to={callToAction.url}>{callToAction.label}</Link>
					</div>
				</section>
				<section className="big-title landing-clients">
					<h2>
						<Link to={'/enterprise'}>
							Présents aussi en entreprise
						</Link>
					</h2>
					<ClientsList/>
					<div style={{width:'100%', display:'flex', justifyContent:'center'}}>
						<Link style={{margin:'auto'}} className='cta' to='/contact'>Parler avec un expert</Link>
					</div>
				</section>
			</div>
		);
	}
}

class LandingMap extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			step: 'LOADING',
			seances: []
		};
	};

	componentDidMount() {
		this.load();
	}

	load = () => {
		this.setState({step: 'LOADING'});

		let seancesLoad = this.context.API.seanceSearch()
		.then(seances => {
			seances = this.context.repo.put(seances);
			let partialPlacesLoad = loadOnlyPartials(this.context.API.placeGet, this.context.repo, seances.map(s => s.place));
			let partialCoachesLoad = loadOnlyPartials(this.context.API.coachGet, this.context.repo, seances.map(s => s.coaches).flat());

			return Promise.all([partialPlacesLoad, partialCoachesLoad])
			.then(() => seances);
		});
		let placesLoad = this.context.API.placeSearch();

		return Promise.all([placesLoad, seancesLoad])
		.then( results => {
			let places = results[0];
			let seances = results[1];
			this.setState({step : "OK", places, seances});
		})
		.catch( e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	selectPlace = (place) => {
		this.setState({ place })
	};

	reset = () => {
		this.setState({ place: null })
	};

	render() {
		if (this.state.step === 'LOADING')
			return <MsgPage type='LOADER' title="Chargement des séances"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type='ERROR' />;

		const {seances} = this.state;

		if (!seances.length) return null;

		let seancesDisplay = seances;
		
		let {user} = this.context;
		let sub = user && user.subscriptions.current;

		if( !sub ) {
			seancesDisplay = seances.filter( s => {
				if( s.restriction.corporate ) {
					return false;
				}
				return true;
			});
		} else if ( !sub.restriction.corporate) {
			seancesDisplay = seances.filter( s => {
				if( !s.restriction.corporate )
					return true;

				if( !sub.corporate ) {
					return false;
				}
				if ( s.restriction.corporate.id === sub.corporate.id ) {
					return true;
				}
				return false;
			});
		} else {
			seancesDisplay = seances.filter( s => {
				if ( !s.corporate ) {
					return false;
				}
				if ( s.corporate.id !== sub.restriction.corporate.id) {
					return false;
				}
				return true;
			});
		}

		if (this.state.place)
			seancesDisplay = seancesDisplay.filter(s => s.place.id === this.state.place.id);

		seancesDisplay = seancesDisplay.slice(0, 6);

		return (
			<div className="LandingMap">
				<div>
					{this.state.places && !!this.state.places.length &&
					<GoogleMapsDisplay items={this.state.places} onSelectMarker={this.selectPlace} reset={this.reset}/>
					}
					{this.state.place &&
						<aside>
							{this.state.place.searchImage && <img src={this.state.place.searchImage.path} alt={this.state.place.name}/>}
							<div>
								<h3>{this.state.place.name}</h3>
								<address>
									<p>{this.state.place.address.street}</p>
									{this.state.place.address.street2 &&
										<p>{this.state.place.address.street2}</p>
									}
									<p>{this.state.place.address.zip} {this.state.place.address.city}</p>
								</address>
							</div>
							<Link to={`/place/${this.state.place.id}`}/>
						</aside>
					}
				</div>
				<div>
					<SeanceList seances={seancesDisplay} displayDay={true}/>
					<Link to="/seance">Voir toutes les séances</Link>
				</div>
			</div>
		);
	}
}

const Feedbacks = () => {
	const [ state, updateState ] = useState({
		step: 'LOADING',
		feedback: []
	});

	const loadFeedBack = () => {
		API.feedbackSearch({
			limit: 40,
			withMessage: true,
			orderBy: 'f.creation_date',
			order: 'desc'
		})
		.then(result => {
			updateState({
				step: 'OK',
				feedback: result.data
			});
		})
		.catch(err => {
			console.error(err);
			updateState({
				step: 'ERROR',
				feedback: [],
				error: err
			});
		});
	};

	useEffect(loadFeedBack, [ ]);
	if (state.step === 'ERROR') return null;
	return (
		<section className="landing-feedback">
			<h2>Les derniers avis</h2>
			{/* <em>(Tous les avis laissés par les clients sont listés et ne sont soumis à aucune modération)</em> */}
			{ state.step === 'LOADING' && <MsgPage type='LOADER' title="Chargement des derniers avis"/>}
			{ state.step === 'OK' && <ul>
				{!!state.feedback && state.feedback.filter( e => e.score >=2 ).filter( (e,i) => i < 3).map((f, i) => (
					<li key={i}>
						<FeedbacksDetails feedback={f} />
					</li>
				))}
			</ul>}
			<div>
				<Link to={'/feedbacks'}>
					<em>Voir tous les avis</em>
				</Link>
			</div>
		</section>
	);
};

const LiveAndReplay = () => {
	const [ show, setShow ] = useState( false );

	const setLiveModal = () => {
		setShow(!show);
	};

	return (
		<li>
			<LiveMoreInfo show={ show } close={ setLiveModal }/>
			<h3>Live & Replay</h3>
			<p>
				Retrouvez en visio notre sélection : Bootcamps dans votre salon, yoga, renforcement musculaire ciblé…
				<span onClick={setLiveModal}>en savoir plus</span>
			</p>
		</li>
	);
};

export default Landing;
