import tf1 from './images/press/tf1.png';
import france2 from './images/press/france-2.png';
import myLittleParis from './images/press/my-little-paris.png';
import merciAlfred from './images/press/merci-alfred.png';
import nouvelObs from './images/press/nouvel-obs.png';
import elle from './images/press/elle.png';
import cNews from './images/press/c-news.png';
import france3 from './images/press/france-3.png';
import france5 from './images/press/france-5.png';
import franceO from './images/press/france-o.png';
import leParisien from './images/press/le-parisien.png';
import biba from './images/press/biba.png';
import telerama from './images/press/telerama.png';
import femmeActuelle from './images/press/femme-actuelle.png';
import cherieFm from './images/press/cherie-fm.png';
import _20minutes from './images/press/20-minutes.png';
import metro from './images/press/metro.png';
import pariscope from './images/press/pariscope.png';
import gq from './images/press/gq.png';
import jogging from './images/press/jogging.png';
import parisCapitale from './images/press/paris-capitale.png';
import madameFigaro from './images/press/madame-figaro.png';
import be from './images/press/be.png';

export default [{
	author: "TF1",
	logoUrl: tf1,
	txt: "Allez au ski sans préparation physique, c'est comme prendre la route avec des pneus dégonflés. Alors commencez les entraînements dès que possible avec Urban Challenge"
}, {
	author: "France 2",
	logoUrl: france2,
	txt: "La plus grande salle de sport du monde, c'est ma ville! On utilise toute forme de support : bancs, trottoirs, escaliers... il n'y a pas de limites !"
}, {
	author: "My Little Paris",
	logoUrl: myLittleParis,
	txt: "Le corps d'un athlète, le cœur sur la main, l'étoffe d'un héros : ces gars là, vous n'avez pas intérêt à les décevoir. Dans les jardins de Bercy, des sapeurs pompiers se transforment en coachs sportifs pour l'été. Au programme, des exercices évolutifs qui galberont peu à peu les cuisses, abdos, fessiers..."
}, {
	author: "Merci Alfred",
	logoUrl: merciAlfred,
	txt: "N’arrivez pas en retard. Les commandos marines n’aiment pas trop ça."
}, {
	author: "L'Obs",
	logoUrl: nouvelObs,
	txt: "Urban Challenge propose une palette de cours collectifs animés par des sportifs de haut niveau, d'anciens pompiers voire d'anciens commandos marins (vous allez en baver). Gainage dans l'herbe, step sur les bancs publics ou assouplissements dans les escaliers, rien n'est oublié, tout en redécouvrant Paris sous un autre angle."
}, {
	author: "Elle",
	logoUrl: elle,
	txt: "Les quatre murs de votre salle de sport vous font broyer du noir ? Il est temps de prendre un bol d’air. C’est l’occasion ou jamais : depuis le 14 août, le groupe Urban Challenge lance l’initiative du sport en extérieur toute l’année dans des lieux atypiques de Paris..."
}, {
	author: "CNews",
	logoUrl: cNews,
	txt: "our commencer l’année sur le bon tempo, les Parisiens ont réinvesti les salles de sport. Les clubs de la capitale ont préparé des nouveautés : Urban Challenge propose des exercices aux amateurs de plein air."
}, {
	author: "France 3",
	logoUrl: france3,
	txt: "Il n'est pas trop tard pour faire de vous un athlète. Des cours de gym sont dispensés dans différents parcs et jardins de la capitale. Rien à voir avec les salles de gym où l'on est seul face à une machine. Ici le coach, qui est pompier, motive les troupes et encadre les élèves."
}, {
	author: "France 5",
	logoUrl: france5,
	txt: "En une heure, on passe en revue tout ce qu'on peut avoir comme besoins physiques (musculature, souffle, cardio-vasculaire), et plus il fait froid, plus on brûle de calories !"
}, {
	author: "France Ô",
	logoUrl: franceO,
	txt: "Le concept du sport en extérieur permet de perdre en moyenne 30 % de calories supplémentaires par rapport à une salle de sport classique de part une meilleure oxygénation."
}, {
	author: "Le Parisien",
	logoUrl: leParisien,
	txt: "Vous avez pris la ferme résolution de vous remettre au sport. Et si vous testiez la méthode naturelle? Ce matin, les sportifs vont découvrir une nouvelle façon de faire du sport sous les arbres du bois de Boulogne (Paris XVIe) et dans d'autres sites privilégiés de la capitale (Champ-de-Mars, jardins de Bercy). La société Urban Challenge lance en effet un concept qui fait un malheur depuis plus de dix ans outre-Manche..."
}, {
	author: "BIBA",
	logoUrl: biba,
	txt: "Faire du sport quand il fait froid dehors, pas question que je renonce! Grâce à Urban Challenge, je peux me dépenser même sous la neige! Mais j'oublie le micro short et j'y vais mollo..."
}, {
	author: "Télérama",
	logoUrl: telerama,
	txt: "En haut des marches de Bercy, je prends une telle goulée d'air que j'ai l'impression de vider Paris de son oxygène. Et ça fait du bien ! [...] Suer en extérieur coûte moins cher qu'en salle : avec les forfaits proposés, le cours est à une dizaine d'euros. Aujourd'hui, à peine plus cher qu'un paquet de cigarettes."
}, {
	author: "Femme Actuelle",
	logoUrl: femmeActuelle,
	txt: "Garder la forme au bois : Plus convivial qu'une salle de sport, Urban Challenge propose des cours de sport en extérieur. On choisit son activité, son niveau, son lieu, et on bénéficie d'un coaching."
}, {
	author: "Chérie FM",
	logoUrl: cherieFm,
	txt: "On se sent un peu à part, comme privilégiée. Ce n'est pas tous les jours qu'on dévale un pont parisien le plus vite possible. Et avec nos maillots jaunes fluo, on est un peu les stars de Bercy."
}, {
	author: "20 Minutes",
	logoUrl: _20minutes,
	txt: "Garder une forme olympique lorsque l'on est citadin, c'est possible. Commando marine, pompier ou championne de France de judo... Le pedigree des coachs d'Urban Challenge ressemble à un encadrement d'élite. Mais rassurez-vous, les cours sont ouverts à tous, du débutant au sportif chevronné."
}, {
	author: "Metro",
	logoUrl: metro,
	txt: "Les coaches suivent, corrigent et boostent non stop les participants. Je peux attester de l'engouement et du rythme bien tonique, maintenu durant une heure. La satisfaction vient du dépassement de soi. La stimulation du groupe invite à ne pas lâcher prise et à s'accrocher."
}, {
	author: "Pariscope",
	logoUrl: pariscope,
	txt: "Urban Challenge est un tout nouveau concept qui vient de débarquer dans la capitale.... il vous est possible de tester le premier cours gratuitement, alors pas d'hésitation !"
}, {
	author: "GQ",
	logoUrl: gq,
	txt: "Si vous trouvez que c'est compliqué de faire du sport à Paris (ou que c'est ennuyeux) alors n'hésitez plus et souriez ! Urban Challenge vous propose des cours de sport en extérieur dans les parcs de Paris. Vous allez transpirer mais comme vous le savez, rien n'est gratuit... heu si car les cours d'Urban Challenge ne coûtent presque rien !"
}, {
	author: "Jogging",
	logoUrl: jogging,
	txt: "Dédié au bien-être et à l’amélioration de sa condition physique, le principe est simple : pratiquer des activités sportives surprenantes en groupe dans les parcs et les espaces urbains parisiens..."
}, {
	author: "Paris Capitale",
	logoUrl: parisCapitale,
	txt: "Tout se fait ici en douceur, chacun à son niveau par cours d'une heure, et un peu plus dès que les progrès se font sentir. Un atelier de sport en extérieur, le rêve !"
}, {
	author: "Madame Figaro",
	logoUrl: madameFigaro,
	txt: "Garder la forme tout en prenant un bol d'air avec Urban Challenge, c'est possible ! Les instructeurs sont des pompiers et des sportifs de haut niveau. Ces deux corps de métiers ont été choisis pour la rigueur et l'entraide qui y règnent. Ce sont les personnes les plus aptes à nous pousser dans l'effort. Une réelle cohésion et communauté a été crée autour d'Urban Challenge."
}, {
	author: "Be",
	logoUrl: be,
	txt: "Les pompiers s'entraînent toute l'année et font profiter quelques chanceuses de leurs exercices.On a de très bons retours de la part de leurs adhérents: on se muscle, on perd vite des kilos et les pompiers sont rassurants."
}];