import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Helmet from 'react-helmet';

import FormField from "./FormField";
import MsgPage from "./utils/MsgPage";

import './ForgotPassword.scss';

export default class ForgotPassword extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);

		let params = queryString.parse(window.location.search);

		this.state = {
			step: 'FORM',
			error: null,
			email: {
				type: 'email',
				autoFocus:  true,
				placeholder: 'adresse@domaine.fr',
				label: 'E-mail de connexion',
				value: params.email || '',
				autoComplete: 'username email'
			}
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.invalid = false;
		this.setState({[event.target.name] : field});
	};

	submit = (event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		let error = false;

		['email'].forEach(k=>{
			newState[k].value = newState[k].value.trim();
			if (newState[k].value === '') {
				newState[k].invalid = true;
				error = true;
			}
		});

		if (error) {
			this.setState(newState);
			return;
		}

		this.setState({
			step: 'ASKING',
			error: null
		});

		this.context.API.passwordRecoveryCreate({
			email: this.state.email.value.trim()
		})
		.then(res => {
			this.setState({
				step: 'OK',
				userMsg: "Vous recevrez d'ici quelques instants un e-mail avec les instructions de réinitialisation de votre mot de passe"
			})
		})
		.catch(e => {
			if (e.restCode === 'resourceNotFound') {
				return this.setState({
					step: 'ERROR',
					userMsg: "Aucun compte lié à cet email"
				});
			}
			this.setState({
				step: 'ERROR',
				userMsg: 'Une erreur est survenue'
			});
			console.error(e);
		});
	};

	retry = (event) => {
		event.preventDefault();
		this.setState({step:'FORM'});
	};

	confirm = (event) => {
		event.preventDefault();
	};

	render() {
		return (
			<div className="ForgotPassword">
				<Helmet key='helmet'>
					<meta name="robots" content='noindex,follow'/>
					<link rel="canonical" href="https://www.urban-challenge.fr/forgotPassword" />
				</Helmet>

				<h1>Mot de passe oublié</h1>
				{this.state.step === 'ASKING' &&
				<MsgPage type="LOADER" title={'Envoi en cours...'}/>
				}
				{this.state.step === 'ERROR' &&
				<MsgPage type="ERROR" retryFunc={this.retry} msg={this.state.userMsg}/>
				}
				{this.state.step === 'OK' &&
				<MsgPage type="SUCCESS"  msg={this.state.userMsg}/>
				}
				{this.state.step === 'FORM' &&
				<form onSubmit={this.submit}>
					<FormField field={this.state.email} name="email" onChange={this.onChange}/>
					<button onClick={this.submit} className='submit-forgot-button'>Réinitialiser mot de passe</button>
				</form>
				}
			</div>
		);
	}
}
