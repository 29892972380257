import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MsgPage from "./utils/MsgPage";

export default class OffersLoader extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API  : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING'
		};
	}

	componentDidMount(){
		this.load();
	}


	load = () => {
		this.setState({step :'LOADING'});
		this.context.API.offerList()
		.then(offers=>{
			offers = this.context.repo.put(offers);
			offers = offers.filter(o=>
				o.expirationDate > moment().format('YYYY-MM-DD') && o.releaseDate <= moment().format('YYYY-MM-DD')
			);
			this.setState({
				step :'OK',
				offers
			});
		})
		.catch(e => {
			console.error(e);
			this.setState({step :'ERROR'});
		})
	};


	render() {

		if(this.state.step === 'LOADING')
			return <MsgPage type='LOADER' title="Chargement des offres" />;
		if(this.state.step === 'ERROR')
			return <MsgPage type='ERROR' title="Une erreur est survenue" />;

		return this.props.children(this.state.offers);
	}
}
