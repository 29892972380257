import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment/locale/fr';

import Modal from "./Modal";
import MsgPage from "./utils/MsgPage";
import Feedback from "./Feedback";

import './PaymentForm.scss';
import './PayBookModal.scss';
import CB from "./icons/card-cb.png";
import visa from "./icons/visa.svg";
import mastercard from "./icons/mastercard.svg";
import FormField from "./FormField";
import cvc from "./icons/cc-cvc.svg";

export default class PayBookModal extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object,
		user: PropTypes.object,
		analytics: PropTypes.func,
		signInNeeded: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING' };
	}

	componentDidMount() {
		this.load();
	}

	cancelFeedbackToDo = (event) => {
		event && event.preventDefault();
		this.setState({feedbackTodo : null});
	};

	load = () => {
		let {API, repo, user} = this.context;
		if ( !user ) return this.setState({ step: 'OK' });

		this.setState({step: 'LOADING'});

		API.bookingList({ presence: true, user_id: user.id, feedback:null })
		.then( bookingWaitingForFeedback => {
			bookingWaitingForFeedback = repo.put(bookingWaitingForFeedback);
			let feedbackTodo = bookingWaitingForFeedback
			.sort((a, b) => b.creation.date - a.creation.date)[0]
			this.setState({ step: 'OK', feedbackTodo });
		})
		.catch( e => {
			this.setState({ step: "OK", feedbackTodo: null, paymentMethod: null });
		})
		;
	};

	cancel = (event) => {
		event && event.preventDefault();
		this.props.onCancel();
	};

	selectPayment = (value, event) => {
		this.setState({ selected: value });
		if (value && !!value.canPay) return this.payBook();
	};

	payBook = (event) => {
		this.setState({ step: 'PENDING' });
		if (!this.context.user)
			return this.context.signInNeeded();
		if (!this.state.selected)
			return this.setState({ step: 'PAYMENT' });

		this.context.API.bookingCreate({
			seance: {
				id: this.props.seance.id
			},
			paymentMethod: {
				id: this.state.selected.id
			}
		})
		.then(resp => {
			this.setState({step : "SUCCESS", feedbackTodo:null});
		})
		.catch(e => {
			let error = e.userMsg || "Une erreur est survenue";
			this.setState({
				step: 'FAILURE',
				error
			})
		});
	};

	goToPayment = (event) => {
		event.preventDefault();
		this.setState({ step: 'PENDING' });
		if (!this.context.user)
			return this.context.signInNeeded();

		this.context.API.paymentMethodList()
		.then(resp => {
			this.setState({step : "PAYMENT", paymentMethod: resp });
		})
		.catch(e => {
			let error = e.userMsg || "Une erreur est survenue";
			this.setState({
				step: 'FAILURE',
				error
			})
		});
	};

	render() {
		let {seance, booking} = this.props;
		let {step, error, feedbackTodo, selected} = this.state;
		const modalProps = !!selected ? { confirmFn: this.payBook, confirmLb: "J'achète" } : {};

		if ( step === 'LOADING') return <MsgPage type='LOADER'/>;

		if ( step === 'PENDING') {
			return (
				<div className='PayBookModal'>
					<Modal
						title="Réservation"
						show={true}
					>
						<MsgPage type='LOADER' title={"En cours..."}/>
					</Modal>
				</div>
			);
		}

		if ( step === 'FAILURE') {
			return (
				<div className='PayBookModal PayBookModal_Failure'>
					<Modal
						title="Réservation"
						show={true}
						className="pay-booking-failure-modal"
					>
						<MsgPage type='ERROR' title="Réservation impossible"
								 msg={error}
								 confirmFunc={this.cancel} confirmLb="Fermer"
						/>
					</Modal>
				</div>
			);
		}

		if ( step === 'SUCCESS') {
			return (
				<div className='PayBookModal PayBookModal_Success'>
					<Modal
						className="pay-booking-modal-success"
						show={true}
						title="Réservation"
						cancelFn={this.props.onSuccess}
						cancelLb="Fermer"
					>
						<div>
							<MsgPage type="SUCCESS" title="Réservation effectuée !" msg="Votre réservation a été effectuée avec succès"/>
							<Link to='/account'>Voir mes réservations</Link>
						</div>
					</Modal>
				</div>
			);
		}

		if ( step === 'PAYMENT') {
			return (
				<div className='PayBookModal'>
					<Modal
						className="pay-booking-modal-success"
						show={true}
						title={"Moyen de paiement"}
						cancelFn={this.props.onCancel}
						cancelLb="Fermer"
						{...modalProps}
					>
						<div className={'pay-booking-modal-payment'}>
							<h4>Votre paiement de {seance.booking_price / 100}€</h4><br/>
							{ !!this.state.paymentMethod.length && <div>
								<p>Utiliser un moyen de paiement enregistré</p>
								{this.state.paymentMethod.map(p => (
									<div key={p.id}
											 className={`registered-cb ${(this.state.selected && this.state.selected.id === p.id) ? 'selected' : null}`}
											 onClick={this.selectPayment.bind(this, p)}>
										<img src={CB} alt='CB'/>
										<span>{p.number.slice(8, p.number.length)} - {p.month}/{p.year}</span>
									</div>
								))}
							</div>
							}
							{ !this.state.selected && <div>
								{ !!this.state.paymentMethod.length ? <p>Utiliser un autre moyen de paiement</p> : <p>Utiliser un moyen de paiement</p> }
								<PayementForm setPaymentCard={this.selectPayment} showCGV/>
							</div>}
						</div>
					</Modal>
				</div>
			);
		}

		let askFeedback = !booking && feedbackTodo;

		return (
			<div className='PayBookModal'>
				{ askFeedback &&
					<Feedback booking={feedbackTodo}
						onCreated	= {this.cancelFeedbackToDo}
						onClose		= {this.cancelFeedbackToDo}
						onInvalid	= {this.cancelFeedbackToDo}
					  	otherSeance = {true}
					/>
				}

				{ !askFeedback &&
					<Modal
						className="pay-booking-modal"
						show={true}
						title={`${seance.booking_price / 100}€`}
						confirmFn={this.goToPayment}
						confirmLb="Suivant"
						cancelFn={this.cancel}
						cancelLb="Annuler"
					>
						<p>
							<strong>{seance.name}</strong>
							<span>{seance.place.name}</span>
							<time dateTime={seance.starts}>{moment(seance.starts).locale('fr').format("dddd DD/MM - HH:mm")}</time>
						</p>
					</Modal>
				}
			</div>
		);
	}
}

class PayementForm extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: "FORM",
			ccOwner: {
				value: "",
				placeholder: "Nom",
				autoFocus: true,
				label: "Titulaire de la carte",
				autoComplete: "cc-name",
				type: "text"
			},
			ccNumber: {
				value: "",
				placeholder: "XXXX XXXX XXXX XXXX",
				type: 'text',
				label: "Numéro de carte",
				autoComplete: "cc-number"
			},
			ccExpiration: {
				value: "",
				placeholder: "MM/AA",
				autoComplete: "cc-exp",
				label: "Exp.",
				maxLength: 5,
				type: "text"
			},
			cvc: {
				value: "",
				placeholder: "XXX",
				label: "Code",
				autoComplete: "cc-csc",
				type: "text",
				maxLength: 3
			},
			cgv: {
				value: false
			}
		};
	}

	componentDidMount() {
		window.scroll(0, 0);
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;
	}

	onSubmit = (event) => {
		event && event.preventDefault();
		let newState = Object.assign({}, this.state);
		let error = false;

		if (this.props.showCGV && !newState.cgv.value) {
			newState.cgv.invalid = true;
			error = true;
		}
		if (newState.cvc.value.length !== 3) {
			newState.cvc.invalid = true;
			error = true;
		}

		if (!newState.ccExpiration.value) {
			newState.ccExpiration.invalid = true;
			error = true;
		} else {
			let exp = newState.ccExpiration.value.split('/');
			let y = new Date().getFullYear();
			if (+exp[0] > 12 || +exp[1] + 2000 < y || +exp[1] + 2000 > y + 20) {
				newState.ccExpiration.invalid = true;
				error = true;
			}
		}
		if (!newState.ccOwner.value.trim()) {
			newState.ccOwner.invalid = true;
			error = true;
		}
		if (newState.ccNumber.value.replace(/[^0-9]/g, '').length !== 16) {
			newState.ccNumber.invalid = true;
			error = true;
		}

		if (error) {
			this.setState(newState);
			return;
		}

		let exp = this.state.ccExpiration.value.split('/');
		let cardData = {
			owner : this.state.ccOwner.value.trim(),
			number : this.state.ccNumber.value.replace(/ /g, ''),
			cvc : this.state.cvc.value.trim(),
			expiration : {
				month : exp[0].trim(),
				year : exp[1].trim()
			},
			app : 'uc',
			unRegister: true
		};

		this.setState({step: 'PENDING'});
		this.context.API.paymentMethodCreate(cardData)
		.then(res => {
			this.props.setPaymentCard({ ...res, canPay: true }, event);
		})
		.catch(e => {
			let msg = e.userMsg || 'La vérification a échouée';
			this.setState({ step:'FORM', error : msg});
		});
	};

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.invalid = false;
		if (event.target.name === 'ccNumber') {
			field.value = this.ccChunk(field.value);
		}
		if (event.target.name === 'ccExpiration') {
			field.value = this.expChunk(field.value);
		}
		if (event.target.name === 'cgv') {
			field.value = event.target.checked;
		}
		this.setState({
			[event.target.name]: field
		});
	};

	ccChunk = (value) => {
		return value.replace(/[^\d]/g, '').slice(0,16).match(/.{0,4}/g).join(' ').replace(/ $/, '');
	};

	expChunk = (value) => {
		return value.replace(/[^\d]/g, '').slice(0,4).match(/.{0,2}/g).join('/').replace(/\/$/, '');
	};

	render() {

		if (this.state.step === 'PENDING') {
			return (
				<MsgPage type="LOADER" title="Vérification de la carte"/>
			);
		}

		return (<div className={'PaymentForm'}>
			<form onSubmit={this.onSubmit}>
				<div className="accepted-cards">
					<img src={visa} alt='Visa'/>
					<img src={mastercard} alt='Mastercard'/>
				</div>

				{this.state.error &&
					<p className='payment-form-error'>{this.state.error}</p>
				}
				{
					['ccOwner', 'ccNumber'].map(f => {
						return <FormField key={f} name={f} field={this.state[f]} onChange={this.onChange}/>
					})
				}
				<div className="other-infos">
					{
						['ccExpiration', 'cvc'].map(f => {
							return <FormField key={f} name={f} field={this.state[f]} onChange={this.onChange}/>
						})
					}
					<img src={cvc} alt="CVC"/>
				</div>

				{this.props.showCGV &&
				<Fragment>
					<label className={'payment-cgv' + (this.state.cgv.invalid ? " invalid" : "")}>
						<input onChange={this.onChange} name="cgv" type="checkbox" checked={this.state.cgv.value}/>
						<span>
						Je confirme avoir lu et accepté les <Link to='/cgv' target="_blank">conditions générales de ventes</Link>.
						</span>
					</label>
				</Fragment>
				}
				{ this.state.ccOwner.value && this.state.ccNumber.value && this.state.ccExpiration.value && this.state.cvc.value && this.state.cgv.value &&
					<button onClick={this.onSubmit} className={'PayButton'}>Utiliser cette carte</button>
				}
			</form>
		</div>);
	}
}
