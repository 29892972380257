export default [{
	title: "Préambule",
	content: [
		"Les présentes Conditions Générales de vente (ci-après dénommées « Conditions Générales ») sont conclues :",
		"ENTRE : Le client, ci-après dénommé l’ « Adhérent »",
		"ET : URBANERS, société par actions simplifiées, au capital social de 16 470,00 euros, immatriculée au Registre du Commerce et des Sociétés de PARIS sous le numéro 522 873 512 00035, dont le siège social est situé au 88 avenue du General Leclerc 92100 Boulogne Billancourt (ci-après dénommée « URBAN CHALLENGE », le nom commercial de la société)",
		"Les CGV s’appliquent aux ventes d’abonnements et services conclues sur le site internet www.urban-challenge.fr. Les CGV sont portées à la connaissance de l’Adhérent préalablement à toute confirmation de commande et de paiement de la formule d’abonnement choisie.",
	]
}, {
	title: "1. Application des conditions générales de vente par internet",
	content: [
		"Les présentes conditions générales de vente (ci-après les « CGV ») s’appliquent aux ventes d’abonnements conclues sur le site internet www.urban-challenge.fr.",
		"Les CGV sont portées à la connaissance de l’Adhérent préalablement à toute confirmation de commande et de paiement de la formule d’abonnement choisie."
	]
}, {
	title: "2. Commandes d’abonnements URBAN CHALLENGE",
	content: [
		"Afin de passer commande sur le site internet www.urban-challenge.fr, l’Adhérent doit effectuer les étapes suivantes :",
		"<ul>",
			"<li>L’Adhérent pour s’inscrire se rend dans l’onglet « se connecter » puis entre son mail professionnel et un mot de passe, coche la case « attestant sur l’honneur ne pas présenter une contre-indication médicale et physique à la pratique d’activités sportives » et la présentation d’un certificat médical d’aptitude si requise par URBAN CHALLENGE puis il ajoute le code entreprise.</li>",
			"<li>L’Adhérent consulte les formules d’abonnements qui lui sont présentées. </li>",
			"<li>Après avoir choisi sa formule d’abonnement, l’Adhérent clique sur le bouton « sélectionner », si l’abonnement est payant, l’Adhérent effectue son paiement par carte bancaire. </li>",
			"<li>L’Adhérent déclare avoir pris connaissance et accepte les conditions générales de vente par internet.</li>",
			"<li>Il est désormais possible d’accéder à l’offre sport de l’entreprise.</li>",
			"<li>La commande est enregistrée et URBAN CHALLENGE accuse réception de la commande en communiquant à l’Adhérent un numéro d’adhésion ainsi qu’un récapitulatif de sa commande par e-mail.</li>",
			"<li>Les renouvellements sont validés dans les mêmes conditions.</li>",
			"<li>Le contrat d’abonnement est strictement individuel, à peine de résiliation immédiate de l’abonnement sans mise en demeure.</li>",
		"<ul>",
	]
}, {
	title:"3.  Les contrats d’abonnements URBAN CHALLENGE",
	content: [
		"Le contrat d’abonnement est conclu pour une durée déterminée à compter de sa souscription. L’Adhérent peut souscrire des abonnements annuels, semestriels, trimestriels, mensuels, carnet de 10 séances sous réserve que ceux-ci soient en vigueur dans le club où se fait l’inscription."
	]
}, {
	title:"4.  Prix et modalités de paiement",
	content: [
		"Le prix de l’abonnement figure sur le site internet www.urban-challenge.fr.",
		"Les prix applicables sont exprimés en Euros et sont ceux en vigueur au jour de l’enregistrement de la commande par l’Adhérent, toutes taxes et participation aux frais de traitement incluses.",
		"Les offres promotionnelles ne sont valables que pour la durée de validité de l’offre concernée et selon les conditions décrites dans ladite offre. Les promotions en cours ne sont pas cumulables entre elles. Elles ne peuvent être échangées contre leur valeur monétaire ou être remboursées en partie ou dans leur totalité. Passé le délai indiqué, la réduction sera définitivement perdue.",
		"Les prix sont susceptibles d’être modifiés à tout moment par URBAN CHALLENGE.",
		"L’Adhérent est seul responsable du paiement effectif de l’abonnement acheté. URBAN CHALLENGE n’est pas responsable des problèmes de paiement liés au fonctionnement du service de commerce électronique.",
		"L’Adhérent dispose à tout moment d'un droit d'accès et de rectification des données le concernant collectées au moment du paiement, à charge pour lui d’en faire la demande par courrier (au siège social de URBAN CHALLENGE) ou par courrier électronique à l’adresse info@urban-challenge.fr.",
	]
}, {
	title:"5.  Résiliation et suspension du contrat d’abonnement",
	content: [
		"L’Adhérent dispose de la faculté de résilier son abonnement à tout moment sans motif, moyennant un préavis de 90 jours, au moyen d’un formulaire ad hoc rempli dans son club.",
		"il est toutefois précisé que l’abonné dispose de la possibilité de résilier son abonnement sans préavis , dans des cas de maladie, d’accident grave, de décès, de grossesse, de licenciement économique, de déménagement pour motif professionnel ou de mutation professionnelle, aux conditions détaillées dans le règlement intérieur édité par le club.",
		"Par ailleurs, l’abonnement peut être résilié par URBAN CHALLENGE de plein droit, à tout moment, sans préavis, ni indemnité, dans les cas suivants : si l’attitude, le comportement ou la tenue de l’Adhérent est contraire aux bonnes mœurs, ou cause un trouble au bon fonctionnement des activités ou à l’ordre public ou occasionne une gêne caractérisée pour les autres adhérents, en cas de non-respect par l’Adhérent des CGV ou du règlement intérieur du club, en cas de non- paiement de l’abonnement."
	]
}, {
	title: "6.  Défaut de paiement de l’Adhérent",
	content: [
		"En cas de non-règlement des sommes dues par l’Adhérent à URBAN CHALLENGE au titre du contrat d’abonnement, l’Adhérent recevra une lettre ou mail de mise en demeure l’invitant à régulariser sa situation et l’informant de la suspension de son contrat jusqu’à régularisation.",
		"L’Adhérent qui n’aura pas régularisé sa situation dans les trente jours suivant la réception de cette lettre ou mail de mise en demeure pourra voir son abonnement résilié, moyennant un préavis complémentaire de soixante jours, et se verra réclamé, sur présentation d’un titre exécutoire, l’indemnisation des frais de recouvrement engagés en supplément des sommes dont il est redevable. Les frais d’impayés bancaires seront forfaitaires d’un montant de 15 euros TTC à la charge de l’adhérent.",
	]
}, {
	title: "7.  Conditions d’accès au club",
	content: [
		"Avant sa première séance et de manière régulière, URBAN CHALLENGE recommande à l’Adhérent de faire vérifier par son médecin traitant son aptitude à la pratique d’une activité sportive.",
		"L’Adhérent s’engage à prendre toute précaution nécessaire à sa sécurité et son hygiène, ainsi qu’à respecter les consignes de URBAN CHALLENGE.",
		"L’adhérent peut profiter des cours proposer par le club en réservant une séance de sport sur le site www.urban-challenge.fr.",
		"L’accès au club est soumis au strict respect du règlement intérieur de club.",
		"L’Adhérent s’engage par conséquent à respecter en toute circonstance et à tout moment les consignes de bonne conduite, d’hygiène et de sécurité qui pourraient, le cas échéant, être rappelées par voie d’affichage ou sur tout document commercial. Dans le cas contraire, l’Adhérent s’expose à un courrier d’avertissement suspensif de son contrat d’abonnement. En outre, l’Adhérent s’expose à une exclusion immédiate, sans préavis, ni mise en demeure, pour tout fait contrevenant aux lois pénales et celles protégeant les droits des tiers (en ce compris notamment, le vol, les violences physiques ou verbales, les atteintes aux bonnes mœurs, aux règles d’hygiène ou de sécurité, la dégradation intentionnelle des installations du club, le coaching d’autres abonnés sous quelque forme que ce soit, l’introduction de tiers dans le club, la consommation ou la mise en circulation de produits dopants).",
		"Le fait de se réinscrire consécutivement à une telle exclusion constitue un motif légitime de réalisation immédiate du nouvel abonnement et de son remboursement prorata temporis, à l’exception des frais de dossier qui reste acquis à URBAN CHALLENGE.",
	]
}, {
	title: "8. Vestiaires",
	content: [
		"Le club met à la disposition des Adhérents des casiers individuels dont l’utilisation est strictement limitée à la durée de la séance sportive. Ces-derniers les utilisent de manière autonome et en assurent la fermeture au moyen de leur propre cadenas.",
		"Il est strictement interdit de laisser ses affaires personnelles à l’intérieur des casiers après avoir quitté le club, et le club se réserve la possibilité de vider chaque soir les casiers qui resteraient occupés.",
		"Il est rappelé expressément aux Adhérents que les vestiaires ne font l’objet d’aucune surveillance spécifique et il est donc recommandé de ne pas y entreposer des objets de valeur.",
	]
}, {
	title: "9.  Responsabilité civile",
	content: [
		"URBAN CHALLENGE est assuré pour les dommages et conséquences pécuniaires engageant sa responsabilité civile et celle de son personnel ainsi que celle de ses adhérents, conformément à l’article L.321- 1 du Code du Sport.",
		"Cette assurance a pour objet de couvrir le club contre les conséquences pécuniaires de la responsabilité civile encourue au titre des dommages causés à autrui du fait de l’exploitation, qu’il s’agisse des dommages corporels et matériels.",
		"La responsabilité civile de URBAN CHALLENGE est couverte par le contrat RCE AXA.",
		"De son côté, l’Adhérent est invité à souscrire une police d’assurance responsabilité civile personnelle, le couvrant de tous les dommages qu’il pourrait causer à des tiers, de son propre fait, pendant l’exercice des activités du club.",
		"Il est strictement interdit de laisser ses affaires personnelles à l’intérieur des casiers après avoir quitté le club, et le club se réserve la possibilité de vider chaque soir les casiers qui resteraient occupés.",
		"Il est rappelé expressément aux Adhérents que les vestiaires ne font l’objet d’aucune surveillance spécifique et il est donc recommandé de ne pas y entreposer des objets de valeur.",
	]
}, {
	title: "10.  Confidentialité et loi informatique et libertés",
	content: [
		"Il appartient à l’Adhérent de conserver la confidentialité du numéro de son adhésion qui lui est attribué, étant entendu que l’Adhérent sera seul responsable au titre de l’utilisation de ces informations et des conséquences résultant de la divulgation de sa part, à l’exception de la perte et du vol.",
		"Il est rappelé à l’Adhérent que le traitement informatique de son dossier dans le cadre de la loi Informatique et Libertés du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, lui ouvre un droit d’accès, de rectification et d’opposition à ses données personnelles collectées et que URBAN CHALLENGE peut être amené à traiter pour ses besoins.",
		"Pour user de cette faculté, l’Adhérent pourra adresser une lettre simple à URBAN CHALLENGE, 88 avenue du General Leclerc 92100 Boulogne Billancourt.",
	]
}, {
	title: "11.  Protection des données",
	content: [
		"Toute commande sur le site internet www.urban-challenge.fr implique la connaissance et l’acceptation par l’Adhérent des caractéristiques du réseau internet, notamment les risques inhérents à toute connexion et transmission sur internet.",
		"Il appartient à l’Adhérent de prendre toutes mesures appropriées pour protéger ses données et/ou logiciels stockés sur son équipement informatique contre toute atteinte."
	]
}, {
	title: "12.  Droit de rétractation",
	content: [
		"En application des dispositions du Code de la consommation, l’Adhérent dispose d’un délai de quatorze jours francs pour exercer son droit de rétractation d’un contrat conclu à distance, à la suite d’un démarchage téléphonique ou hors établissement.",
		"Ce délai court à compter de la confirmation par URBAN CHALLENGE de la prise en compte de l’inscription. Si ce délai expire normalement un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu’au premier jour ouvrable suivant.",
		"L’exercice de ce droit de rétractation implique le remboursement par URBAN CHALLENGE de toutes les sommes dont l’Adhérent aura été éventuellement prélevé ou débité au titre de son abonnement.",
		"La demande de rétractation doit être adressée par lettre recommandée avec accusé de réception à URBAN CHALLENGE, 88 avenue du General Leclerc 92100 Boulogne Billancourt.",
	]
}, {
	title: "13. Clauses Diverses",
	content: [
		"L’inscription à l’une des formules de URBAN CHALLENGE implique la pleine connaissance par le client des présentes conditions générales et son adhésion sans réserve.Elle ne confère aucun droit d’usage de la marque et du logo de URBAN CHALLENGE.Le mode d’inscription électronique est reconnu comme ayant pleine valeur juridique.Toutefois, les éventuels problèmes techniques de fonctionnement ou d’accès au site internet de URBAN CHALLENGE même bloquant une inscription à un abonnement ou à un cours n’ouvre droit à aucun dédommagement, quel qu’en soit la durée.Toute éventuelle résiliation ou le non-renouvellement d’un partenariat avec l’un des prestataires assurant les cours proposés par URBAN CHALLENGE ne constitue pas une cause de résiliation des abonnements ni de dédommagement. URBAN CHALLENGE aura la faculté de modifier ces conditions générales à tout moment sans que cela constitue une cause de résiliation de l’abonnement, sous réserve d’en informer les abonnés sur son site internet.",
	]
}, {
	title: "14.  Loi applicable",
	content: [
		"Toutes les clauses figurant dans les présentes CGV sont soumises au droit français.",
	]
}];

