import React from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect} from 'react-router-dom';

import MsgPage from "./utils/MsgPage";

import './LoginAs.scss';
import FormField from "./FormField";

export default class LoginAs extends React.Component {

	static contextTypes = {
		loginAs: PropTypes.func,
		user: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			step :'VIEW',
			loginAs : {
				type: 'email',
				autoFocus:  true,
				placeholder: 'adresse@domaine.fr',
				label: 'E-mail',
				value: '',
				autoComplete: 'username email',
				error: null
			}
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.error = false;
		this.setState({[event.target.name] : field});
	};

	unlogAs = (event) => {
		event.preventDefault();
		this.setState({ step: 'PENDING' });
		this.context.loginAs(null)
		.then(() => {
			this.setState({ step: 'OK' });
		})
		.catch(e => {
			this.setState({ step: 'ERROR', error: e });
		})
		;
	};

	submit = (event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		let error = false;

		['loginAs'].forEach(k=>{
			newState[k].value = newState[k].value.trim();
			if (newState[k].value === '') {
				newState[k].error = true;
				error = true;
			}
		});

		if (error) {
			this.setState(newState);
			return;
		}

		this.setState({ step: 'PENDING' });
		this.context.loginAs(this.state.loginAs.value)
		.then(() => {
			this.setState({ step: 'OK' });
			//NOTHING: this is handled by loginAs owner
		})
		.catch(e => {
			this.setState({ step: 'ERROR', error: e });
		})
		;
	};

	render() {
		if(!this.context.user.admin && !this.context.user.adminViewer){
			return <Redirect to='/'/>
		}

		if (this.state.step === 'PENDING') {
			return <MsgPage type="LOADER" title="En cours"/>;
		}
		if(this.state.step === 'SUCCESS'){
			return <p>Ok, just refresh</p>
		}
		if(this.state.step === 'ERROR'){
			return <MsgPage type="ERROR" title="Une erreur est survenue"/>;
		}

		return (
			<div className="LoginAs">
				<h1>Admin SignIn</h1>
				{this.context.user.adminViewer ?
				<section>
					<p>
						Vous êtes connecté en tant que
						<strong>{this.context.user.email}</strong>
					</p>
					<button className="signout-as-button" onClick={this.unlogAs}>Se déconnecter</button>
				</section>
				:
				<form className='sign-in' onSubmit={this.submit}>
					{['loginAs'].map(f => {
						return <FormField key={f} name={f} field={this.state[f]} onChange={this.onChange} />
					})}
					<DisplayError e = {this.state.error} email={this.state.loginAs.value}/>
					<input type='submit' className="submit-user-signin" value="Me connecter" />
				</form>
				}
			</div>
		);
	}
}

const DisplayError = ({e, email}) => {
	if (!e) return null;

	if (e.restCode === 'authFailed') {
		if (e.reason === 'badPassword')
			return <p className="form-error">Mot de passe incorrect</p>;
		if (e.reason === 'noSuchLogin')
			return <p className="form-error">Cet e-mail n'est associé à aucun compte, peut-être voulez-vous <Link to={`/signUp?email=${email}`}>créer un compte</Link> ?</p>;
		if (e.reason === 'blockedAccount')
			return <p className="form-error">Ce compte a été désactivé</p>;
	}

	return <p className="form-error">Erreur inattendue. Veuillez réessayer ultérieurement.</p>;
};

