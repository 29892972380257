export default create;

function isArray(i){
	return Array.isArray(i);
}

function isObject(i) {
	return typeof i == 'object';
}


function isEntity(obj) {
	return obj['__ref'] && obj['__state'] && obj['id'];
}


function create(){
	var db = {};

	var repo = {};
	repo.get = (type, id) => {
		if(!db[type]){
			console.warn("There is no collection named ", type);
			return null;
		}
		if(!db[type][id]){
			console.warn("There is no object type", type,'with id =', id);
			//return ref state init
			return null;
		}
		return db[type][id];
	};

	repo.clear = (type) => {
		db[type] = {};
	};

	repo.put = (obj) => {
		return copy(obj);
	};
	return repo;
	
	function copyEntity(e) {
		var collectionName = e['__ref'];
		db[collectionName] = db[collectionName] || {};
		var collection = db[e.__ref];
		var id = e.id;
		if(!collection[id]) {
			return collection[id] = copyObject(e);
		}

		var previous = collection[id];
		if(e['__state'] === "PARTIAL" && previous['__state'] !== "PARTIAL") {
			return previous;
		}
		Object.keys(previous).forEach(k=>delete previous[k]);
		Object.keys(e).forEach(k=>previous[k]=copy(e[k]));
		return previous;
	}


	function copyObject(o) {
		var r = {};
		Object.keys(o).forEach(k=>{
			r[k] = copy(o[k]);
		})
		return r;
	}

	function copy(o){
		if(o === null){
			return null;
		}
		
		if(isArray(o)){
			return o.map(e => copy(e));
		}

		
		if(isObject(o)){
			if(isEntity(o)) return copyEntity(o);

			return copyObject(o);
		}

		return o;
	}
}
