import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import SignIn from './SignIn';

export default class OnlyAdmin extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render = () => {
		if ( !this.context.user) {
			return  <SignIn />;
		}

		if ( !this.context.user.adminViewer && !this.context.user.admin )
			return  <Redirect to={this.props.to} />;

		return this.props.children;
	}
}
