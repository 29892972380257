import React from 'react';
import PropTypes from 'prop-types';

import SignInUpForgot from './SignInUpForgot';
import MsgPage from "./utils/MsgPage";
import loadOnlyPartials from './loadOnlyPartials';

export default class SessionManager extends React.Component {

	static contextTypes = {
		API: PropTypes.object,
		repo : PropTypes.object
	};


	static childContextTypes = {
		user          : PropTypes.object,
		signOut       : PropTypes.func,
		signIn        : PropTypes.func,
		loginAs       : PropTypes.func,
		isSignedIn	  : PropTypes.func,
		signInNeeded  : PropTypes.func,
		signUp        : PropTypes.func,
		refreshUser   : PropTypes.func
	};

	getChildContext = () => {
		return {
			user         : this.state.user,
			signOut      : this.signOut,
			signIn       : this.signIn,
			loginAs      : this.loginAs,
			isSignedIn	 : this.isSignedIn,
			signInNeeded : this.signInNeeded,
			signUp       : this.signUp,
			refreshUser	 : this.refreshUser
		};
	};

	constructor(props, context){
		super(props, context);
		this.state = { step:"LOADING"};
		context.API.onSessionLost(this.onSessionLost);
	}

	componentDidMount(){
		window.scrollTo(0, 0);
		if ( document.cookie.includes('1SignedIn') ) this.refreshUser();
		else this.setState({ step:"OK", user: null });
	}

	onSessionLost = () => {
		this.setState({user:null});
		window.TrackJS && window.TrackJS.configure({ userId : 'guest' });
	};

	refreshUser = () => {
		this.setState({step:"LOADING"});
		this.context.API.getCurrentUser()
		.then(user=>{
			window.TrackJS && window.TrackJS.configure({ userId : user.email });
			return this.loadSubscriptions(user)
			.then( (subscriptions) => {
				return this.setState({
					step:"OK",
					user:{
						...user,
						subscriptions : {
							...subscriptions,
							reload : this.reloadSubscriptions
						}
					}
				});
			})
			.catch(e=>{
				this.setState({step:"ERROR"});
			})
			;
		})
		.catch(e=>{
			this.setState({step:"OK", user:null});
			console.error("[SessionManager]", e);
		})
		;
	};

	reloadSubscriptions = () => {
		this.setState({step:'LOADING_ABO'});
		this.loadSubscriptions(this.state.user)
		.then( subscriptions => {
			return this.setState({
				step : 'OK',
				user : {
					...this.state.user,
					subscriptions : {
						...subscriptions,
						reload : this.reloadSubscriptions
					}
				}
			});
		})
		.catch( e => {
			this.setState({step:'ERROR'});
		});
	};

	loadSubscriptions = (user) => {
		if(! user ){
			return Promise.resolve({
				current : null,
				next : null,
				all : []
			});
		}

		let {API, repo} = this.context;

		return API.subscriptionList()
		.then(subs=>{
			subs = repo.put(subs);
			let loadOffers = loadOnlyPartials(API.offerList, repo, subs.map(s=>s.offer))
			let loadPlaces = loadOnlyPartials(API.placeGet, repo,subs.map(s=>s.restriction.places).flat());
			let loadCorporates =  loadOnlyPartials( API.corporateGet, repo, subs.map(s=>s.corporate).concat(subs.map(s=>s.restriction.corporate)).filter(c=>c) );	

			return Promise.all([loadOffers, loadPlaces, loadCorporates])
			.then( () =>{
				let current = subs.find(s=>s.current);
				let next = null;
				if(current){
					next  = subs.find(s=>s.previous && s.previous.id === current.id);
				}
				return {
					current,
					next,
					all : subs
				};
			})
		})
	};

	signIn = (email, password) => {
		return this.context.API.signIn({email,password})
		.then(res => {
			return this.refreshUser();
		})
	};

	loginAs = (email) => {
		return this.context.API.adminLoginAs(email)
		.then(res => {
			return this.refreshUser();
		})
		.catch(e => {
			return this.setState({ step:"ERROR" });
		})
		;
	};

	signInNeeded = (event) => {
		event && event.preventDefault();
		this.setState({step:"SIGNIN_NEEDED"});
	};

	isSignedIn = () => {
		return this.refreshUser();
	};

	signOut = (event) => {
		event && event.preventDefault();
		this.context.API.signOut()
		.then( () => this.setState({ user: null }) )
		;
	};

	signUp = (params) => {
		return this.context.API.signUp(params);
	};

	render(){
		if(this.state.step === "LOADING")
			return <MsgPage type='LOADER' title="Chargement de l'utilisateur" />;

		if(this.state.step === "LOADING_ABO")
			return <MsgPage type='LOADER' title="Chargement de votre abonnement" />;

		if(this.state.step === 'SIGNIN_NEEDED')
			return <SignInUpForgot/>;

		if(this.state.step === "SIGNIN_IN")
			return <p>SingIn progress</p>;

		return this.props.children;
	}
}
