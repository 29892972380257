import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';

import API from './api/FunctionnalAPI';
import './Feedbacks.scss';
import Img from "./utils/Img";
import challengesImg from "./images/hero.jpg";
import MsgPage from "./utils/MsgPage";
import prevIcon from './icons/chevron-left.svg';
import nextIcon from './icons/chevron-right.svg';
import FeedbacksDetails from "./FeedbacksDetails";

export default () => {
	const [ state, update ] = useState({
		step: 'LOADING',
		feedbacks: [],
		query: {
			limit: 20,
			page: 1,
			withMessage: true,
			orderBy: 'f.creation_date',
			order: 'desc'
		},
		pagination: { max: 1 }
	});

	const load = () => {
		API.feedbackSearch(state.query)
		.then(results => {
			update({
				step: 'OK',
				query: state.query,
				feedbacks: results.data,
				pagination: results.pagination
			});
		})
		.catch(err => {
			console.error(err);
			update({
				step: 'ERROR',
				feedbacks: [],
				query: state.query,
				error: err
			});
		});
	};

	useEffect(load, [ state.query ]);

	const nextPage = () => {
		update({
			step: 'LOADING',
			feedbacks: [],
			query: {
				...state.query,
				page: state.query.page + 1,
			}
		});
	};
	const prevPage = () => {
		update({
			step: 'LOADING',
			feedbacks: [],
			query: {
				...state.query,
				page: state.query.page - 1,
			}
		});
	};

	return (
		<div className="Feedbacks">

			<Helmet>
				<title>Avis | Urban Challenge</title>
			</Helmet>

			<header>
				<Img src={challengesImg} alt="Challenges" lazySrc='TODO' />
				<h1>
					Tous les avis
				</h1>
			</header>

			{ state.step === 'LOADING' && <MsgPage type='LOADER' title="Chargement des avis..."/> }
			{ state.step === 'OK' && <section>
				<ul>
					{state.feedbacks && state.feedbacks.map((f, i) => (
						<li key={i}>
							<FeedbacksDetails feedback={f}/>
						</li>
					))}
				</ul>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
					<button
						disabled={state.query.page <= 1}
						type='submit' className='feedback-button' onClick={prevPage}>
						<Img src={prevIcon} alt="Avis précédent." lazySrc='todo' />
						Avis précédent
					</button>
					<button
						disabled={state.query.page >= state.pagination.max}
						type='submit' className='feedback-button' onClick={nextPage}>
						Avis suivant
						<Img src={nextIcon} alt="Avis suivant." lazySrc='todo' />
					</button>
				</div>
				<em style={{ textAlign: 'center' }}>(Tous les avis laissés par les clients sont listés et ne sont soumis à aucune modération)</em>
			</section> }
		</div>
	);
};
