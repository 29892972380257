import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Helmet from "react-helmet";

import Img from "./utils/Img";

import './Concept.scss';

import conceptImg from "./images/concept-hero-2.jpg";
import betterYourself from "./images/better-yourself-2.jpg";
import warmupImg from "./images/concept/warmup.jpg";
import trainingImg from "./images/concept/training.jpg";
import stretchingImg from "./images/concept/stretching.jpg";
import balanceImg from "./images/concept/balance.jpg";
import abdosImg from "./images/concept/abdos.jpg";
import attackDefenseImg from "./images/concept/attack-defense.jpg";
import brasImg from "./images/concept/bras.jpg";
import fessierImg from "./images/concept/fessier.jpg";
import jambesImg from "./images/concept/jambes.jpg";
import jumpImg from "./images/concept/jump.jpg";

class Concept extends Component {

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <article className="Concept">

                <Helmet>
                    <title>Le concept | Urban Challenge</title>
                    <link rel="canonical" href="https://www.urban-challenge.fr/concept" />
                </Helmet>

                <header>
                    <Img src={conceptImg} lazySrc='TODO' alt="Workout"/>
                    <div>
                        <h1>Le concept</h1>
                    </div>
                </header>
                <section className="BetterYourself">
                    <div>
                        <h2>Devenez une meilleure version de vous-même</h2>
                        <p>
                            Leader du coaching en extérieur, nous avons recruté des coachs issus du corps
                            des Sapeurs-Pompiers et athlètes de haut niveau, pour vous offrir une
                            expérience unique de dépassement de soi et de cohésion en groupe
                        </p>
                    </div>
                    <img src={betterYourself} alt="Better yourself"/>
                    <Link className='callToAction' to='/plans'>Je m'abonne</Link>
                </section>
                <section className="SeanceTiming">
                    <h2>Une séance type Bootcamp</h2>
                    <p>
                        Après un <strong>échauffement</strong>, on alterne, sur le
                        mode du fractionné, course et exercices
                        interactifs pour renforcer l'ensemble des
                        muscles et dynamiser son système
                        cardiovasculaire.
                    </p>
                    <p>
                        <strong>Étirements et relaxation</strong> clôturent la séance
                        pour éviter les courbatures et se sentir
                        pleinement ressourcé.
                    </p>
                    <ol>
                        <li>
                            <img src={warmupImg} alt="Échauffement"/>
                            <article className="warmup">
                                <em>1</em>
                                <b>Échauffement</b>
                                <span>10-15 min</span>
                            </article>
                        </li>
                        <li>
                            <img src={trainingImg} alt="Échauffement"/>
                            <article className="main-training">
                                <em>2</em>
                                <b>Corps de séance</b>
                                <span>35-40 min</span>
                            </article>
                        </li>
                        <li>
                            <img src={stretchingImg} alt="Échauffement"/>
                            <article className="stretching">
                                <em>3</em>
                                <b>Étirements</b>
                                <span>5-10 min</span>
                            </article>
                        </li>
                    </ol>
                    <Link className='callToAction' to='/plans'>Je m'abonne</Link>
                </section>
                <section className="Advantages">
                    <h2>
                        Les plus d'Urban Challenge
                    </h2>
                    <p>
                        Une séance toujours <strong>en déplacement</strong> dans le parc
                    </p>
                    <p>
                        Entrecoupement des exercices par de la <strong>course</strong>
                    </p>
                    <p>
                        <strong>Variation</strong> des exercices d'une séance à l'autre
                    </p>
                    <p>
                        <strong>Objectif commun</strong> du débutant au plus chevronné
                    </p>
                    <p>
                        Travail les <strong>7 familles d'exercice</strong> :
                        <ul>
                            <li>
                                <img src={balanceImg} alt="Equilibre"/>
                                <b>Équilibre</b>
                            </li>
                            <li>
                                <img src={jumpImg} alt="Saut"/>
                                <b>Saut</b>
                            </li>
                            <li>
                                <img src={attackDefenseImg} alt="AttackDefense"/>
                                <b>Attaque / défense</b>
                            </li>
                            <li>
                                <img src={brasImg} alt="Arms"/>
                                <b>Travail des bras</b>
                            </li>
                            <li>
                                <img src={abdosImg} alt="Six pack"/>
                                <b>Abdominaux</b>
                            </li>
                            <li>
                                <img src={jambesImg} alt="Legs"/>
                                <b>Travail des jambes</b>
                            </li>
                            <li>
                                <img src={fessierImg} alt="Fessier"/>
                                <b>Fessiers</b>
                            </li>
                        </ul>
                    </p>
                    <Link className='callToAction' to='/plans'>Je m'abonne</Link>
                </section>
            </article>
        );
    }

}

export default Concept;