import React from 'react';

import './FormField.scss';

class FormField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			readOnly: props.readOnly,
		}
	}

	onFocus = () => {
		this.setState({
			focus: true
		})
	};
	onBlur = () => {
		this.setState({
			focus: false
		})
	};

	render() {
		const field = this.props.field;
		const classNames = [
			"FormField",
			field.value !== '' && 'filled',
			this.state.focus && 'focus',
			field.error && 'invalid',
			field.type === 'checkbox' && ' has-checkbox'
		].filter(e=>e).join(' ');
		return (
			<label className={classNames}>
				<span>{field.label}</span>
				<input type={field.type}
					   name={this.props.name}
					   placeholder={field.placeholder}
					   value={field.value}
					   autoComplete={field.autoComplete}
					   onChange={this.props.onChange}
					   onFocus={this.onFocus}
					   onBlur={this.onBlur}
					   autoFocus={field.autoFocus}
					   maxLength={field.maxLength}
					   readOnly={this.state.readOnly}
				/>
			</label>
		);
	}
}

export default FormField;
