import React, {Component} from 'react';
import PropTypes from 'prop-types';

import MsgPage from "./utils/MsgPage";
import loadOnlyPartials from './loadOnlyPartials';

export default class SeanceLoader extends Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING'};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({step: 'LOADING'});

		let {API, repo, user} = this.context;

		let seanceGet = API.seanceGet(this.props.id)
		.then(seances => {
			if (!seances[0])
				return null;

			let seance = seances[0];
			let placeLoad = loadOnlyPartials(API.placeGet, repo, [seance.place]);
			let corporateLoad = loadOnlyPartials(API.corporateGet, repo, [seance.restriction.corporate, seance.corporate]);
			let coachesLoad = loadOnlyPartials(API.coachGet, repo, seance.coaches);

			return Promise.all([placeLoad, corporateLoad, coachesLoad])
			.then( (results) => {
				seance.reload = this.reload;
				return seance;
			})
			;
		});

		let bookingGet = Promise.resolve()
		.then( () => user && API.bookingList({ seance_id : this.props.id, user_id : user.id}) )
		.then( bookings => {
			if ( !bookings || !bookings.length ) return null;
			return bookings[0];
		});

		let messageSearch = API.messageSearch({seance_id:this.props.id});
		let prom = [seanceGet, bookingGet, messageSearch];

		if (!!user && user.id) {
			prom.push(
				API.watcherSearch({seance: this.props.id })
			);
		}

		Promise.all(prom)
		.then( results => {
			let seance = repo.put(results[0]);
			let booking = repo.put(results[1]);
			let messages = results[2];
			let watcher = !!results[3] ? results[3].values : [];
			this.setState({ step : "OK", seance, booking, messages, watcher });
		})
		.catch( e => {
			console.error(e);
			this.setState({ step : 'ERROR' });
		});
	};

	messagesReload = () => {
		let {API} = this.context;
		
		API.messageSearch({seance_id:this.props.id})
		.then( messages => {
			this.setState({messages});
		});
	};

	render() {
		let {step, seance, booking, messages, watcher} = this.state;

		if ( step === "LOADING")  	return <MsgPage type="LOADER" title="Chargement du cours..."/>;
		if ( step === "ERROR" )		return <MsgPage type='ERROR' title="Erreur au chargement..."/>;

		if ( !seance )				return <MsgPage type="WARNING" title="Ce cours n'existe pas"/>;

		return this.props.children({ seance, booking, messages, messagesReload: this.messagesReload, reload:this.reload, watcher });
	}
}
