import React from 'react';

export default class Facebook extends React.Component {

	componentDidMount(){
		if(!this.props.config)
			return console.warn('[Facebook]', 'no config');
		
		if(!this.props.config.activated)
			return console.debug('[Facebook]', 'not activated');

		if(!this.props.config.pixelCode)
			return console.warn('[Facebook]', 'no pixelCode config');
		

		console.debug('[Facebook]', 'is mounting');

		var ref = window.document.getElementsByTagName("script")[0];
		var script = window.document.createElement("script");

		let text = `!function(f,b,e,v,n,t,s)
		{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)}(window, document,'script',
		'https://connect.facebook.net/en_US/fbevents.js');`
		;
		text += `window.fbq('init', ${this.props.config.pixelCode})`;
		script.text = text;
		ref.parentNode.insertBefore(script, ref);
	}

	render() {
		return this.props.children || null;
	}
}