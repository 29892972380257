import React, {Component} from 'react';
import PropTypes from 'prop-types';

import SignIn from './SignIn';

export default class Private extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render = () => {
		if (this.context.user) {
			return this.props.children;
		}
		return  <SignIn />;
	}
}
