import React from 'react';

import Modal from "./Modal";

import './PaymentForm.scss';

export default class PaymentUseCurrentCardModal extends React.Component {

	componentDidMount() {
		window.scroll(0, 0);
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;
	}


	render() {
		return (
			<Modal
				title="Utiliser ma carte"
				className="PaymentForm"
				confirmLb="J'achète"
				confirmFn={() => this.props.confirm({id:this.props.paymentMethod.id})}
				otherLb="Changer de carte"
				otherFn={this.props.changeCard}
				cancelLb="Changer d'offre"
				cancelFn={this.props.changeOffer}
				show={true}
			>
				<table>
					<tr><td>Numéro</td><td>{this.props.paymentMethod.number}</td></tr>
					<tr><td>Expiration</td><td>{this.props.paymentMethod.month} / {this.props.paymentMethod.year}</td></tr>
				</table>
			</Modal>
		);
	}
}
