import React, {Component} from 'react';
import {button} from 'react-router-dom';

import './Pagination.scss'

export default class Pagination extends Component {

	onClick = (event) => {
		event.preventDefault();
		var page = +(event.target.dataset.page);
		this.props.onPageChanged(page);
	};

	render (){
		const {props} = this;

		let min = +(props.current) - 3;
		let max = +(props.current) + 3;

		while (max > props.max) {
			max--;
			min--;
		}
		while (min < 1) {
			max++;
			min++;
		}
		max = max > props.max ? props.max : max;

		let links = [];
		for (let i = min; i <= max; i++) {
			links.push(i);
		}

		return (
			<div className="Pagination">
				{props.resultsCount !== undefined &&
					<span>{props.resultsCount} résultats</span>
				}
				{props.max > 1 && <nav>
					<ul>
						{min !== 1 && (
							<li key="1">
								<button onClick={this.onClick} data-page={1} className={props.current ===1 ? "active":""}>1</button>
								<span className="pagination-ellipsis--begin">...</span>
							</li>
						)}
						{links.map((l) => {
							return (
								<li key={l}>
									<button onClick={this.onClick} data-page={l} className={props.current === l ? "active":""}>{l}</button>
								</li>
							)
						})}
						{max !== props.max && (
							<li key="max">
								<span className="pagination-ellipsis--end">...</span>
								<button onClick={this.onClick} data-page={props.max} className={props.current ===props.max ? "active":""}>{props.max}</button>
							</li>
						)}
					</ul>
				</nav>}
			</div>
		);
	}
};
