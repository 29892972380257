import React from 'react';

import './BasicSearch.scss'


export default class BasicSearch extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			search: '',
			suggestions: []
		};
	}

	onChange = (e) => {
		let value = e.target.value;

		this.setState({
			search: value,
			suggestions: []
		});

		if(value.length >= (this.props.searchOffset || 2))
			return this.props.search(value)
			.then(suggestions => {
				this.setState({
					suggestions: suggestions
				});
			})
			.catch(console.error);
		return null;
	};

	select = (e) => {
		var id = +e.target.value;
		var place = this.state.suggestions.find(p=>p.id === id);
		this.props.onSelect(place);
	};

	render(){
		return (
			<div className='BasicSearch'>
				<label>
					<span>{this.props.name}</span>
					<input className='BasicSearchInput' autoComplete={this.props.autocomplete || 'on' } name="name" type="text" placeholder={this.props.placeholder || ''} value={this.state.search} onChange={this.onChange} autoFocus/>
				</label>
				<ul>
					{!!this.state.suggestions && this.state.suggestions.map(p=>{
						return <li key={p.id} value={p.id} onClick={this.select}>{p.display}</li>
					})}
				</ul>
			</div>
		);
	}
}
