import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Img from './utils/Img';
import MsgPage from "./utils/MsgPage";
import Planning from './Planning';

import './Place.scss';

import defaultImg from './images/hero.jpg';

class Place extends React.Component {

	static contextTypes = {
		repo: PropTypes.object,
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: "LOADING"
		};
	}

	componentDidMount = () => {
		this.load();
	};

	load = () => {
		this.setState({step: 'LOADING'});

		let {API, repo} = this.context;

		API.placeGet(this.props.match.params.placeId)
		.then(places => {
			places = repo.put(places);
			let place = places.find(p => p.id === +this.props.match.params.placeId);
			if(!place){
				return this.setState({step: 'OK', place:null});	
			}
			
			this.setState({step: 'OK', place});
		})
		.catch(e => {
			this.setState({step: "ERROR"});
		});
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		if (this.state.step === "LOADING")
			return <MsgPage type='LOADER' title="Chargement du lieu" />;

		if (this.state.step === "ERROR")
			return <MsgPage type='ERROR' title="Erreur de chargement"/>;

		if (this.state.step === "OK") {
			if(!this.state.place)
				return <MsgPage type="WARNING" title="Ce lieu n'existe pas"/>;

			let place = this.state.place;

			return (
				<div className="Place">
					<Helmet>
						<title>Lieu : {place.name} | Urban Challenge</title>
						{/*<meta name='description' content="TOFILL"/>*/}
					</Helmet>
					<header>
						<button onClick={this.goBack}>Retour</button>
						<Img lazySrc='todo' src={place.searchImage ? place.searchImage.path : defaultImg} alt="Place"/>
						<h1>{place.name}</h1>
					</header>
					{!!place.practical &&
					<section>
						<h2>Infos pratiques</h2>
						<div className="richText" dangerouslySetInnerHTML={{__html: place.practical}}/>
					</section>
					}
					<section>
						<h2>Planning des cours</h2>
						<Planning places={[place]}/>
					</section>
				</div>
			);
		}
	};
}

export default withRouter(Place);
