import React, {Component} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import MsgPage from "./utils/MsgPage";
import OfferSelector from './OfferSelector';
import Subscribe from './Subscribe';

import "./Plan.scss";

class Plan extends  Component {

	static contextTypes = {
		user: PropTypes.object,
	};

	constructor() {
		super();
		const params = new URLSearchParams(window.location.search);
		this.state = {
			selected: params.get('offer'),
		};
	}

	applyPromo = (initialPrice, promo) => {
		let price = {
			initial: initialPrice,
			after: initialPrice
		};
		if(!promo) return price;

		if(promo.percentage)
			price.after = price.initial * (1 - promo.percentage/100);
		else if(promo.value)
			price.after = price.initial - promo.value;

		price.after = Math.max(0, price.after);
		return price;
	};

	render() {

		let offers = this.props.offers;
		let subscriptions = this.context.user.subscriptions;

		if(!subscriptions.current)
			return <Subscribe applyPromo = {this.applyPromo} offers={offers} history={this.props.history} selected={ this.state.selected }/> ;

		return <ManagePlan applyPromo = {this.applyPromo} offers={offers} /> ;
	}
}



class ManagePlan extends Component {

	static contextTypes = {
		user: PropTypes.object,
		repo : PropTypes.object,
		API : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {step: 'DISPLAY'};
	}

	manageOffer = (event) => {
		event.preventDefault();
		this.setState({step: 'MANAGE'});
	};
	stopTrial = (event) => {
		event.preventDefault();
		this.setState({ step : 'LOADING'});
		this.context.API.subscriptionUpdate(this.context.user.subscriptions.current.id, {
			offer: this.context.user.subscriptions.current.offer ? { id: this.context.user.subscriptions.current.offer.id } : null,
			cancelation: true
		})
		.then(res => {
			this.context.user.subscriptions.reload();
		})
		.catch(err => {
			console.error(err, "error when stopTrial");
		})
	};

	onSelect = (offer) => {
		this.setState({ step : 'LOADING'});

		this.context.API.subscriptionUpdate(this.context.user.subscriptions.next.id, {offer: offer ? {id: offer.id} : null})
		.then( () => {
			this.context.user.subscriptions.reload();
		})
		.catch( e => {
			if(e.restCode === 'paymentMethodRequired')
				return this.setState({ step: 'ERROR_NO_CB', errorMsg:"Vous n'avez pas rentré de moyen de payment" });

			console.error(e);
			this.setState({
				step: 'ERROR',
				errorMsg: e.userMsg || 'Une erreur est survenue'
			});
		});
	};

	updateCb = (event) => {
		event && event.preventDefault();
		this.setState({step: "EDIT_CB"});
	}

	filterOffers = (choices) => {
		let {current, next} = this.context.user.subscriptions;

		let filtered = this.props.offers
		.filter( o => !o.restrictions.firstSubOnly)
		.filter( o => next.cancelation || o.id !== next.offer.id)
		.filter( o => {
			if(!o.restrictions.corporate) return true;
			return current && current.promo && current.promo.corporate
				&& o.restrictions.corporate.id === current.promo.corporate.id;
		})
		.filter( o => {
			if(!current || !current.promo || !current.promo.restrictions.offers) return true;
			return current.promo.restrictions.offers.some(r => +r.id === +o.id )
		})
		;

		return filtered;
	};

	render(){
		if(this.state.step === 'EDIT_CB')
			return <Redirect to = '/account/profile#UPDATE_PAYMENT' />

		let current = this.context.user.subscriptions.current;
		let next = this.context.user.subscriptions.next;

		if (this.state.step === 'ERROR_NO_CB')
			return <MsgPage type='ERROR' msg={this.state.errorMsg} confirmFunc={this.updateCb} confirmLb="Mettre à jour"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type='ERROR' msg={this.state.errorMsg}/>;

		if (this.state.step === 'LOADING')
			return <MsgPage type='LOADER' title="Mis à jour de votre pass" />;

		if(this.state.step === 'DISPLAY')
			return (
				<div>
					<div className="CurrentPlan">
						<article>
							<h2>Période actuelle</h2>
							<section>
								<time>Du {moment(current.starts).format('DD/MM/YYYY')} au {moment(current.ends).add(-1,'day').format('DD/MM/YYYY')}</time>
								<strong>Formule {current.offer.name}</strong>
								<p dangerouslySetInnerHTML={{__html: current.offer.description}} />

								{!!current.commitment &&
								<p>Engagement: reste {current.commitment} mois</p>
								}
								{current.corporate && (
									<div>
										<strong>Offre entreprise</strong>
										<p>{current.corporate.name}</p>
									</div>
								)}
								{current.promo && (
									<div style={{marginTop:'1rem'}}>
										<strong>Code {current.promo.code}</strong>
										{!!current.promo.percentage && <p style={{marginLeft:'1rem'}}>- {current.promo.percentage}%</p>}
										{!!current.promo.value && <p style={{marginLeft:'1rem'}}>- {current.promo.value/100}€</p>}
									</div>
								)}
								{ (current.restriction.corporate || current.restriction.places) && (
									<div>
										<strong>Limitations</strong>

										{current.restriction.corporate && (
											<p>Séances de {current.restriction.corporate.name}</p>
										)}

										{current.restriction.places && <div>
											<p>Lieux</p>
											<ul>
												{current.restriction.places.map(p => {
													return <li key={p.id}><Link to={`/place/${p.id}`}>{p.name}</Link></li>
												})}
											</ul>
										</div>}
									</div>
								)}
								{/*{current.offer.id === 11 && current.restriction.app === 'uc' &&*/}
								{/*<button onClick={this.stopTrial}>Arreter ma période d'éssai</button>*/}
								{/*}*/}
							</section>
						</article>

						{next &&
						<article>
							<h2>Période suivante</h2>
							{!next.offer &&
								<section>
									Pass arrété
									{ current.restriction.app === 'uc' &&
										<button onClick={this.manageOffer}>Gérer ma formule</button>
									}
								</section>
							}
							{next.offer &&
							<section>
								<time>Du {moment(next.starts).format('DD/MM/YYYY')} au {moment(next.ends).add(-1,'day').format('DD/MM/YYYY')}</time>
								<strong>Formule {next.offer.name}</strong>
								<p dangerouslySetInnerHTML={{ __html: next.offer.description }} />
								{next.promo && (
									<div style={{marginTop:'1rem'}}>
										<strong>Code {next.promo.code}</strong>
										{!!next.promo.percentage && <p style={{marginLeft:'1rem'}}>- {next.promo.percentage}%</p>}
										{!!next.promo.value && <p style={{marginLeft:'1rem'}}>- {next.promo.value/100}€</p>}
									</div>
								)}
								{next.corporate && (
									<div>
										<strong>Offre entreprise</strong>
										<p>{next.corporate.name}</p>
									</div>
								)}
								{ (next.restriction.corporate || next.restriction.places) && (
									<div>
										<strong>Limitations</strong>

										{next.restriction.corporate && (
											<p>Séances de {next.restriction.corporate.name}</p>
										)}

										{next.restriction.places && <div>
											<p>Lieux</p>
											<ul>
												{next.restriction.places.map(p => {
													return <li key={p.id}><Link to={`/place/${p.id}`}>{p.name}</Link></li>
												})}
											</ul>
										</div>}
									</div>
								)}
								{next.commitment === 0 && current.restriction.app === 'uc' &&
									<button onClick={this.manageOffer}>Gérer ma formule</button>
								}
							</section>
							}
						</article>
						}
					</div>
					<Link className='planSeanceSearch' to='/seance'>Chercher une séance</Link>
				</div>
			);

		if (this.state.step === 'MANAGE') {
			let nextOffer = !next.cancelation && next.offer;
			let choices = this.filterOffers(this.props.offers)

			return (
				<div className="ManagePlan">
					<h1>Formules</h1>
					<OfferSelector current={nextOffer} promo={null} applyPromo={this.props.applyPromo} choices={choices} onSelect={this.onSelect}/>
				</div>
			);
		}
	}
}

export default withRouter(Plan);
