import React, { useState, useEffect, useCallback } from 'react';

import API from './api/FunctionnalAPI';

import Modal from "./Modal";
import MsgPage from "./utils/MsgPage";

const SeanceWatcher = ({ seance, user, signInNeeded, onSuccess, onCancel }) => {
	const [state, change] = useState("LOADING");
	const [watcher, setWatcher] = useState([]);

	const loadWatcher = useCallback(() => {
		change("LOADING");
		return API.watcherSearch({ seance: seance.id })
		.then(watcher => {
			setWatcher(watcher.values.length <= 0 ? "Creation de la notification" : "Suppression de la notification");
			return watcher.values;
		})
		.then(watch => {
			if (watch.length <= 0) {
				API.watcherCreate({
					seance: seance.id
				})
				.then(() => {
					change("DONE");
					setWatcher("C'est bien noté, vous serez averti·e par mail si une place est de nouveau disponible.");
				}).catch(console.error);
			} else {
				API.watcherDelete(watch[0].id)
				.then(() => {
					change("DONE");
					setWatcher("C'est bien noté, vous ne serez plus averti·e par mail lorsqu'une place se libère.");
				}).catch(console.error);
			}
		});
	}, [ seance ]);

	useEffect(() => {
		if (state !== "LOADING") return;
		if (!user)
			return signInNeeded();

		loadWatcher().catch(console.error);
	}, [ state, loadWatcher, user, signInNeeded ]);

	const modalAccept = () => {
		onCancel();
		setTimeout(onSuccess, 100);
	};

	return (
		<div>
			{state === 'LOADING' && <Modal cancelLb={"Fermer"} cancelFn={modalAccept} show={true}>
				<MsgPage type='LOADER' msg={"Recherche de notif"}/>
			</Modal>}
			{state === 'DONE' && <Modal cancelLb={"Fermer"} cancelFn={modalAccept} show={true}>
				<MsgPage type='SUCCESS' title={' '} msg={watcher}/>
			</Modal>}
		</div>
	);
};

export default SeanceWatcher;
