import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './MedicalCheck.scss';
import MsgPage from "./utils/MsgPage";

let questions = {
	has_cardio: "Avez-vous déjà eu des problèmes cardiaques ?",
	faint: "Avez-vous déjà ressenti, pendant ou après un effort, un malaise ou une perte de connaissances ?",
	chestPain: "Avez-vous déjà ressenti, pendant ou après un effort, des douleurs thoraciques ou palpitations (cœur irrégulier) ?",
	breathing: "Êtes-vous sujet(e) à des problèmes d'asthme ou des difficultés respiratoires ?",
	diabiete: "Souffrez-vous de diabète ou d'épilepsie ?",
	pregnant: "Êtes-vous enceinte ?",
	osteo: "Êtes-vous sujet à des problèmes de dos ou osseux sérieux ?"
};

export default class MedicalCheck extends Component {

	static contextTypes = {
		API: PropTypes.object,
		user: PropTypes.object
	};
	
	constructor(props, context) {
		super(props, context);
		this.state = { step : 'CHECK' };
	}

	componentDidMount() {
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "visible";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1000;
		this.skipIfExists();
	}

	skipIfExists = () => {
		this.setState({ step : 'CHECK' });
		let {API, user} = this.context;
		
		API.healthCheckupSearch({ creation_by:user.id, _recent: true })
		.then( results => {
			if (results.length) return this.props.after();
			this.setState({step : 'FORM'})
		})
		.catch(e => {
			console.error(e);
			this.props.after();
		})
	};

	onChange = (event) => {
		let {name, value} = event.target;
		this.setState({
			[name]: value
		});
	};

	submit = (content) => {
		this.setState({ step : 'UPLOADING'});

		this.context.API.healthCheckupCreate({content})
		.then( () => {
			let body = document.getElementsByTagName('body')[0];
			body.style.overflow = "hidden";
			let header = document.getElementsByClassName('Header')[0];
			header.style.zIndex = 1;
			this.props.after();
		})
		.catch( e => {
			console.error(e);
			this.props.after();
		});
	};

	render(){

		let {step} = this.state;

		return (
			<div className="MedicalCheck">
				<h1>Questionnaire médical</h1>
				{step === 'CHECK' &&
				<MsgPage type="LOADER" title="Vérification informations médicales..."/>
				}
				{step === 'UPLOADING' &&
				<MsgPage type="LOADER" title="Téléchargement en cours..."/>
				}
				{step === 'FORM' &&
				<MedicalCheckForm onSubmit={this.submit}/>
				}
			</div>
		);
	}
}

class MedicalCheckForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	onChange = (event) => {
		let {name, value} = event.target;
		this.setState({
			[name]: value
		});
	};

	submit = (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state);
	};

	render(){

		let submittable = !Object.keys(questions).some( k => !this.state[k]);

		return (
			<form onSubmit={this.submit}>
				{Object.keys(questions).map(k =>
					<div key = {k}>
						<p>{questions[k]}</p>
						<div>
							<label>
								<input name={k} type='radio' value='Y' checked={this.state[k] ==='Y'} onChange={this.onChange}/>
								<span>Oui</span>
							</label>
							<label>
								<input name={k} type='radio' value='N' checked={this.state[k] ==='N'} onChange={this.onChange}/>
								<span>Non</span>
							</label>
						</div>
					</div>
				)}
				<input type="submit" disabled={!submittable} value="Envoyer"/>
			</form>
		);
	}
}