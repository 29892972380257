import React, {Component} from 'react';
import './MsgPage.scss';

import errorIcon from '../icons/error.svg';
import warningIcon from '../icons/warning.svg';
import successIcon from '../icons/success.svg';
import emptyIcon from '../icons/empty.svg';

class MsgPage extends Component {

	render() {
		const types = {
			LOADER: {
				title: 'Chargement...'
			},
			ERROR: {
				icon: errorIcon,
				title: 'Erreur.'
			},
			WARNING: {
				icon: warningIcon,
				title: 'Attention !'
			},
			SUCCESS: {
				icon: successIcon,
				title: 'Terminé !'
			},
			EMPTY: {
				icon: emptyIcon,
				title: 'Vide...!'
			}
		};
		let {type, title, msg} = this.props;

		return (
			<div className="MsgPage">
				{type === 'LOADER' ?
					<div className="loader">
						<div className="spinner"/>
						<div className="spinner"/>
					</div>
					:
					<img src={types[type].icon} alt={type}/>
				}
				<strong>{title || types[type].title}</strong>
				{msg && <p>{msg}</p>}
				{this.props.retryFunc &&
				<button onClick={this.props.retryFunc}>{this.props.retryLb || "Réessayer"}</button>
				}
				{this.props.confirmFunc &&
				<button onClick={this.props.confirmFunc}>{this.props.confirmLb || "Continuer"}</button>
				}
			</div>
		);
	}

}


export default MsgPage;