import React, {Component, Fragment, useState } from 'react';
import { Link, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import SeanceList from "./SeanceList";
import Img from "./utils/Img";
import MsgPage from "./utils/MsgPage";
import loadOnlyPartials from './loadOnlyPartials';

import './Dashboard.scss';

import editProfileIcon from './icons/settings--light.svg';
import editPlanIcon from './icons/edit--light.svg';
import FProfilePic from './images/avatar-female--colored.png';
import MProfilePic from './images/avatar-male--colored.png';
import emailSvg from './icons/email.svg';
import copySvg from './icons/copy.svg';
import Plan from "./Plan";
import OffersLoader from "./OffersLoader";

const ProfilePreview = ({user, offer, promo}) => {
	return (
		<div className="ProfilePreview">
			{user.avatar ?
			<Img src={user.avatar.path} alt={user.firstname}/>
			:
			<Img src={user.gender === 'F' ? FProfilePic : MProfilePic} alt={user.firstname}/>
			}
			<p>
				<Link to='/account/profile' className="user-profile-link">
					<strong>{user.firstname} {user.lastname}</strong>
					<Img src={editProfileIcon} alt="Modifier"/>
				</Link>
				{offer &&
				<Link to='/account/plan' className="user-offer-link">
					Formule
					<strong>
						{offer.name}
						<Img src={editPlanIcon} alt="Modifier"/>
					</strong>
				</Link>
				}
				{promo &&
					<span style={{flexDirection:'row'}}>Code &nbsp;<strong>{promo.code}</strong></span>
				}
			</p>
		</div>
	);
};

class Dashboard extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render() {
		let p = this.props.match.path;
		let user = this.context.user;

		let current = this.context.user.subscriptions.current;
		let hasCorporateOffer = !!current && !!current.offer ? current.offer.restrictions.corporate : null;

		return (
			<div className='Dashboard'>
				<header>
					<ProfilePreview user={user} offer={current?.offer} promo={current?.promo}/>

					{!!current && <nav className='DashboardMenu'>
						<ul>
							<li>
								<NavLink to={`${p}/bookings`} activeClassName='active'>Mes réservations</NavLink>
							</li>
							{/*{ !!hasCorporateOffer &&*/}
							{/*<li>*/}
							{/*	<NavLink to={`/teacher`} activeClassName='active' >Coach Individuel</NavLink>*/}
							{/*</li>*/}
							{/*}*/}
							{!hasCorporateOffer &&
								<li>
									<NavLink to={`${p}/referral`} activeClassName='active'>Parrainage = 10€</NavLink>
								</li>
							}
						</ul>
					</nav>}
				</header>

				<Switch>
					<Route exact path={`${p}/bookings`} component={ () => BookingsView({current}) }  />
					<Route exact path={`${p}/plan`} component={
						() =>
							<OffersLoader>{
								(offers) => (<Plan offers={offers}/>)
							}</OffersLoader>
					} />
					{ !!current && !current.corporate &&
						<Route exact path={`${p}/referral`} component={ () => ReferralView({current}) } />
					}
					<Redirect from="*" to={`${p}/${!!current ? 'bookings' : 'plan'}`} />
				</Switch>

			</div>
		);
	}
}

const ReferralView = ({ current }) => {
	const canReferral = current.offer.price >= 3500;

	return (
		<section className="ReferralView">
			{ !canReferral &&	<p>Pour gagner 10€ et faire bénéficier de 10€ vos filleuls vous devez posséder un abonnement mensuel ou une carte de 10 cours</p> }
			{ canReferral && <ReferralLoader current={current} >
				{({referral, code}) => <ReferralPreview referral={referral} code={code} /> }
			</ReferralLoader> }
		</section>
	);
};

const ReferralPreview = ({code, referral}) => {
	const [copied, setCopy ] = useState(false);
	let referralText = `Hello,`
		+` je t'offre 10€ sur Urban Challenge (lien sur le site) avec le code ${code.code},`
		+` inscris toi et nous pourrons chacun profiter de 10€ de remise`;

	const copyToClipboard = (e) => {
		e.preventDefault();
		setCopy(false);
		referralText.select();
		setCopy( referralText, e.target, document.execCommand('copy') );
	};

	return (
		<div className="ReferralPreview">
			<h2>Parrainage -10€</h2>
			<strong>Obtenez une réduction de 10€ sur votre prochain paiement</strong><br/>
			<p>
				Utilisez votre code dédié pour obtenir 10€ de remise pour chaque parrainage effectif.
			</p>
			<p>
				Votre filleul obtiendra grâce à vous une remise de 10€ sur son premier abonnement illimité ou carte 10 cours.
			</p>

			<div className='referral-code'>
				<input ref={elem => referralText = elem} defaultValue={code.code} readOnly={true} />
				<div>
					<strong>Partager votre code</strong>
					<button onClick={ copyToClipboard } className="clipboard-copy-code">
						<img src={copySvg} alt="Copie"/>
						{copied ? "Copié !" : "Copier"}
					</button>
					<ReferralEmailLink code={code.code}/>
				</div>
			</div>

			{!referral.length &&
			<MsgPage type="EMPTY" title="Aucun filleul pour le moment"/>
			}
			{!!referral.length &&
			<ul>
				{referral.map(r =>
				<li key={r.id}>
					<ReferralDetails referral={r}/>
				</li>
				)}
			</ul>
			}
		</div>
	);
};

const ReferralDetails = ({referral}) => {
	return (
		<article className="ReferralDetails">

			<p>
				<b>{referral.name}</b>
				<span>Formule {referral.offer.name}</span>
			</p>
			<strong>{moment(referral.sub.startDate).format('DD MMM YYYY')}</strong>

		</article>
	);
};

class ReferralLoader extends Component {
	static contextTypes = {
		API  : PropTypes.object,
		repo : PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step : "LOADING" };
	}

	componentDidMount() {
		this.loadCode();
	}

	loadReferral = () => {
		this.setState({step: 'LOADING'});
		this.context.API.referralsList()
		.then(referral => {
			this.setState({
				step: 'OK',
				referral: referral
			});
		})
		.catch(e => {
			console.error(e);
			this.setState({ step: 'ERROR' });
		});
	};

	loadCode = () => {
		this.setState({step: 'LOADING'});
		this.context.API.referralGetCode(this.props.current.user.id)
		.then(code => {
			this.setState({
				code: code
			});
		})
		.then( () => {
			this.loadReferral();
		})
		.catch(e => {
			console.error(e);
			this.setState({ step: 'ERROR' });
		});
	};

	render = () => {
		if(this.state.step === 'LOADING'){
			return  <MsgPage type='LOADER' title="Chargement de vos filleuls"/> ;
		}

		if(this.state.step === 'ERROR'){
			return  <MsgPage type="ERROR"/>;
		}
		return this.props.children({referral: this.state.referral, code: this.state.code });
	}
}

class ReferralEmailLink extends React.Component {
	render() {
		let subj = "Urban-Challenge - Parrainage : 10€ offerts";
		let body = "Hello,\n\n" +
			"Je t'invite à te (re)mettre au sport en prenant un abonnement ou une carte 10 cours sur Urban-Challenge.\n\n" +
			`Grâce à mon parrainage tu as le droit à 10€ offerts sur le premier mois avec le code : ${this.props.code}\n\n` +
			"Rejoins moi vite !";

		return (
			<a href={`mailto:?subject=${encodeURIComponent(subj)}&body=${encodeURIComponent(body)}`}>
				<img src={emailSvg} alt="Email"/>
				Email
			</a>
		)
	}
}

const BookingsView = ({ current }) => {

	const countMoyen = (bookings) => {
		const present =  bookings.filter(b => !b.cancelation && !!b.presence.value).length;
		const groupByMonth = Object.values(bookings.filter(b => !b.cancelation && !!b.presence.value).reduce(function (r, a) {
			let seanceStartMonth = moment(a.seance.starts).format("YYYY.MM");
			r[seanceStartMonth] = r[seanceStartMonth] || [];
			r[seanceStartMonth].push(a);
			return r;
		}, Object.create(null))).length;

		return {
			present: present,
			duration: groupByMonth,
			moyen: (present / groupByMonth).toFixed(1)
		};
	};

	return (
		<BookingLoader>
			{({bookings}) => {
				let stats = countMoyen(bookings);
				let counter = bookings
				.filter( b => b.seance.ends < Date.now() && b.subscription && b.subscription.id === current.id)
				.length;

				return (
					<section className="BookingsPreview">
						<Bookings bookings={bookings}/>
						<div className={'stats-uc'}>
							<PastBookingCounter counter={counter} max={current.bookingsMax} subStart={current.starts} />
							<TotalBookingCounter stats={stats} />
							<StatsBookingCounter stats={stats} />
						</div>
					</section>
				);
			}}
		</BookingLoader>
	);
};

class Bookings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showPast: false
		};
	}

	togglePast = () => {
		this.setState({
			showPast: !this.state.showPast
		});
	};

	render() {
		let {bookings} = this.props;
		let upcomings = bookings.filter(b => b.seance.ends >= Date.now()).sort((a, b) => a.seance.starts - b.seance.starts);
		let past = bookings.filter(b => b.seance.ends < Date.now()).sort((a, b) => b.seance.starts - a.seance.starts);

		return (
			<Fragment>
				<button onClick={this.togglePast} className="show-past-seance">
					{this.state.showPast ? "Masquer" : "Afficher"} les séances passées
				</button>
				{!upcomings.length ?
					<div className="bookings-empty">
						<MsgPage type="EMPTY" title="Aucune réservation à venir"/>
						<Link to="/seance">Chercher une séance</Link>
					</div>
					:
					<SeanceList key='UpcomingSeanceList' seances={upcomings.map(b => b.seance)} displayDay={true}
								addCalendar={true}/>
				}
				{this.state.showPast &&
				<SeanceList key='PastSeanceList' seances={past.map(b => b.seance)} displayDay={true} addCalendar={false}
							className="past"/>
				}
			</Fragment>
		);
	}
}

class PastBookingCounter extends Component {
	render() {
		return (
			<div className="credits-uc" style={{alignSelf:'center'}}>
				<p>
					<strong>{this.props.counter}{!this.props.max ? "" : ` / ${this.props.max}`}</strong>
					<span>
						{`séance${this.props.counter > 1 ? "s" : ""}`}
						<br/>
						{`depuis le`}
						<br/>
						{`${moment(this.props.subStart).format('DD/MM/YY')}`}
					</span>
				</p>
			</div>
		);
	}
}

class StatsBookingCounter extends Component {
	render() {
		return (
			<div className="credits-uc" style={{alignSelf:'center'}}>
				<p>
					<strong>{this.props.stats.moyen > 0 ? this.props.stats.moyen : 0 }</strong>
					<span>
						{`séance${this.props.stats.moyen > 1 ? "s" : ""} en`}
						<br/>
						{`moy / mois`}
					</span>
				</p>
			</div>
		);
	}
}

class TotalBookingCounter extends Component {
	render() {
		return (
			<div className="credits-uc" style={{alignSelf:'center'}}>
				<p>
					<strong>{this.props.stats.present > 0 ? this.props.stats.present : 0 }</strong>
					<span>
						{`séance${this.props.stats.present > 1 ? "s" : ""}`}
						<br/>
						{`totale${this.props.stats.present > 1 ? "s" : ""}`}
					</span>
				</p>
			</div>
		);
	}
}

class BookingLoader extends Component {
	static contextTypes = {
		API  : PropTypes.object,
		repo : PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step : "LOADING" };
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({step: 'LOADING'});
		this.context.API.bookingList({ user_id : this.context.user.id })
		.then(bookings => {
			bookings = this.context.repo.put(bookings).filter(b => !b.cancelation);

			loadOnlyPartials(this.context.API.seanceGet, this.context.repo, bookings.map(b=>b.seance))
			.then(() => {
				let places = bookings.map(b=>b.place)
					.concat( bookings.map(b=>b.seance && b.seance.place));
				return loadOnlyPartials(this.context.API.placeGet, this.context.repo, places)
			})
			.then(() => {
				let coaches = [];
				bookings.map(b => Object.values(b.seance.coaches).forEach(c => coaches.push(c)) );
				return loadOnlyPartials(this.context.API.coachGet, this.context.repo, coaches);
			})
			.then(() => {
				this.setState({
					step: 'OK',
					bookings: bookings
				});
			});
		})
		.catch(e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	render = () => {
		if(this.state.step === 'LOADING'){
			return  <MsgPage type='LOADER' title="Chargement de vos réservations"/> ;
		}

		if(this.state.step === 'ERROR'){
			return  <MsgPage type="ERROR"/>;
		}
		return this.props.children({bookings: this.state.bookings});
	}
}

export default Dashboard;
