import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Modal from "./Modal";
import FormField from "./FormField";

import './SignUp.scss';

export default class SignUp extends React.Component {

	static contextTypes = {
		signUp: PropTypes.func,
		signIn: PropTypes.func,
		analytics: PropTypes.func
	};

	constructor(props, context) {
		super(props, context);

		let params = {};
		props.location && props.location.search.replace(/^\?/,'').split('&').forEach(p => {
			params[p.split('=')[0]] = p.split('=')[1]
		});

		this.state = {
			step: 'INIT',

			gender: {
				type: 'radio',
				label: 'Je suis',
				choices: {
					M: 'un homme',
					F:'une femme'
				},
				value: '',
				error: null
			},
			firstname: {
				type: 'text',
				autoFocus: true,
				placeholder: 'Votre prénom',
				label: 'Prénom',
				value: params.firstname || '',
				autoComplete: 'given-name',
				error: null
			},
			lastname: {
				type: 'text',
				placeholder: 'Votre nom de famille',
				label: 'Nom',
				value: params.lastname || '',
				autoComplete: 'family-name',
				error: null
			},
			phone: {
				type: 'tel',
				placeholder: '06 XX XX XX XX',
				label: 'Portable',
				value: '',
				autoComplete: 'tel',
				error: null
			},
			email: {
				type: 'email',
				placeholder: 'adresse@domaine.fr',
				label: 'E-mail',
				value: params.email || '',
				autoComplete: 'email',
				error: null
			},
			password: {
				type: 'password',
				label: 'Mot de passe',
				placeholder: '*****',
				value: '',
				autoComplete: 'new-password',
				error: null
			},
			newsletter_urban: {
				type: 'checkbox',
				label: "J'accepte de recevoir la newsletter et les offres de UrbanChallenge",
				value: false
			},
			rgpd: {
				type: 'checkbox',
				label: <span style={{display:'inline'}}>J’ai lu et compris <a target='_blank' href="/confidentialite">la politique de confidentialité</a> et j'accepte que mes données personnelles soient traitées par le personnel habilité de URBAN CHALLENGE aux fins de prendre contact avec moi et de délivrer une réponse correspondant au plus près de vos besoins. Vous avez un droit d’accès, de modification, de suppression et de portabilité de vos données, mais également avez la possibilité demander la limitation ou vous opposer au traitement de vos données. Pour en savoir plus sur la gestion de vos données et vos droits, vous pouvez consulter notre politique de confidentialité, ou nous contacter à rgpd@urban-challenge.fr</span>,
				value: false
			}
		};
	}

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		if(field.type === 'checkbox')
				field.value = !field.value;
		else
			field.value = event.target.value;
		field.error = false;
		this.setState({[event.target.name] : field, error:null, alreadyExistingUser :false});
	};

	signUp = (event) => {
		event.preventDefault();

		if( !this.state.rgpd.value) {
			let newState = Object.assign({}, this.state);
			newState.pending = false;
			newState.error = null;
			newState.rgpd.error = 'true';
			newState.step = 'INIT';
			this.setState(newState);
			return;
		}

		this.setState({step:"PENDING", error:null, alreadyExistingUser:false});
		var params = {};
		['gender', 'firstname', 'lastname', 'phone', 'email', 'password', 'newsletter_urban'].forEach(k=>{
			params[k] = this.state[k].value;
			if(k === 'phone'){
				params[k] = params[k].replace(/ /g, '');
			}
		});

		params['creation_from'] = 'uc';

		this.context.signUp(params)
		.then(() => {
			this.context.analytics('signUp');
			return this.context.signIn(params.email, params.password)
		})
		.catch(e => {
			if (e.restCode === 'paramsValidation') {
				let newState = Object.assign({}, this.state);
				newState.pending = false;
				newState.error = null;
				Object.keys(e.errors).forEach(k=>{
					newState[k].error = e.errors[k];
				});

				if (e.errors && e.errors['email'] && e.errors['email'].code === 'unique') {
					newState.alreadyExistingUser = true;
				}
				newState.step = 'INIT';
				this.setState(newState);
				return;
			}
			this.setState({
				step: 'ERROR',
				error: e
			});
		});
	};

	hideModal = (e) => {
		e.preventDefault();
		this.setState({alreadyExistingUser: false});
	};


	render() {

		return (
			<div className="SignUp">
				<Helmet key='helmet'>
					<meta name="robots" content='noindex,follow'/>
					<link rel="canonical" href="https://www.urban-challenge.fr/signUp" />
				</Helmet>
				<h1>Inscription</h1>
				<form>
					<div className={"choice" + (this.state.gender.error ? ' invalid' : '')}>
						<p>{this.state.gender.label}</p>
						{Object.keys(this.state.gender.choices).map(c=>{
							return(
								<label key={c} className='has-checkbox'>
									<span>{this.state.gender.choices[c]}</span>
									<input name="gender" type='radio' value={c} checked = {c === this.state.gender.value} onChange={this.onChange} />
								</label>
							);
						})}
					</div>

					{['firstname', 'lastname', 'phone','email', 'password', 'rgpd', 'newsletter_urban'].map(f => {
						return <FormField key={f} field={this.state[f]} name={f} onChange={this.onChange}/>
					})}

					<button onClick={this.signUp} disabled={!this.state.rgpd.value || this.state.step === 'PENDING'}>{this.state.step === 'PENDING' ? 'Inscription en cours' : "Je crée mon compte"}</button>

					{this.state.alreadyExistingUser &&
						<Modal
							className="existing-email-modal"
							show={this.state.alreadyExistingUser}
							title="Email existant"
						>
							<p>Il existe déjà un compte avec cet email, est-ce vous ?</p>
							<footer>
								<Link className="modal-confirm-button" to={`/account?email=${this.state.email.value}`} onClick={this.props.signIn}>Je me connecte</Link>
								<button className="modal-cancel-button" onClick={this.hideModal}>Changer mon email</button>
							</footer>
						</Modal>
					}
				</form>
			</div>
		);
	}
}