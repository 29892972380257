import React from "react";
import PropTypes from 'prop-types';

import MapType from './utils/map-type';

import pin from './icons/map-pin@2x.png';
import loc from './icons/map-loc@2x.png';

import './GoogleMapsDisplay.scss';

class GoogleMapsDisplay extends React.Component {

	static contextTypes = {
		geolocation: PropTypes.object,
		googleMaps: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			selected: {
				place: null,
				display: ''
			}
		}
	}

	componentDidMount() {
		this.updateMap();
	}

	updateMap = () => {
		let zoom = this.context.geolocation.current ? 15 : 12;
		let lat = 48.8588536;
		let lng = 2.3120407;

		if (this.context.geolocation.current) {
			lat = this.context.geolocation.current.lat;
			lng = this.context.geolocation.current.lng;
		}

		const styledMapType = new this.context.googleMaps.maps.StyledMapType(MapType);


		const mapConfig = Object.assign({}, {
			center: {lat, lng},
			zoom: zoom,
			zoomControl: false,
			zoomControlOptions: {
				position: this.context.googleMaps.maps.ControlPosition.RIGHT_CENTER
			},
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			mapTypeControlOptions: {
				mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map']
			},
			clickableIcons: false
		});

		let map = new this.context.googleMaps.maps.Map(document.getElementById('gMap'), mapConfig);

		if (this.context.geolocation.current) {
			new this.context.googleMaps.maps.Marker({
				position: {
					lat: this.context.geolocation.current.lat,
					lng: this.context.geolocation.current.lng
				},
				map: map,
				icon: {
					url: loc,
					size: new this.context.googleMaps.maps.Size(20, 20),
					scaledSize: new this.context.googleMaps.maps.Size(20, 20),
					anchor: new this.context.googleMaps.maps.Point(10, 10)
				}
			})
		}

		this.props.items
		.filter( i => i.address.geo && i.address.geo.lat && i.address.geo.lng && i.on_map )
		.forEach(i => {
			let marker = new this.context.googleMaps.maps.Marker({
				position: {
					lat: i.address.geo.lat,
					lng: i.address.geo.lng
				},
				map: map,
				icon: {
					url: pin,
					size: new this.context.googleMaps.maps.Size(32, 32),
					scaledSize: new this.context.googleMaps.maps.Size(32, 32),
					anchor: new this.context.googleMaps.maps.Point(16, 32)
				}
			});

			marker.addListener("click", () => {
				this.props.onSelectMarker(i);
			});
		});

		let _this = this;
		map.addListener("click", function() {
			_this.props.reset();
		});

		if (this.props.onDragMap) {
			map.addListener("dragstart", function () {
				_this.props.onDragMap();
			});
		}

		map.mapTypes.set('styled_map', styledMapType);
		map.setMapTypeId('styled_map');
	};

	render() {
		return (
			<section className="GoogleMapsDisplay" id="gMap" />
		);
	}
}

export default GoogleMapsDisplay;
