import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';

export default class Guest extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render = () => {
		if (!this.context.user) {
			return this.props.children;
		}
		return  <Redirect to={this.props.to} />;
	}
}
