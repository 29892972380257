import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import OnlyAdmin from './OnlyAdmin';
import Private from './Private';
import Guest from './Guest';
import Landing from './Landing';
import OffersLoader from './OffersLoader';
import Place from './Place';
import PlaceMap from './PlaceMap';
import SearchPage from './SearchPage';
import SearchTeacherPage from './SearchTeacherPage';
import Seance from './Seance';
import SignUp from './SignUp';
import Dashboard from './Dashboard';
// import Plan from './Plan';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import PasswordChange from './PasswordChange';
import Coach from './Coach';
import CoachList from './CoachList';
import Challenge from './Challenge';
import ChallengeList from './ChallengeList';

import Coaching from './Coaching';
import Contact from './Contact';
import Plans from './Plans';
import Press from './Press';
import Enterprise from "./Enterprise";
import PartnerOfferList from "./PartnerOfferList";
import CGV from './CGV';
import Confidentialite from './Confidentialite';
import ActivateSub from "./ActivateSub";
import ActivateSubSuccess from "./ActivateSubSuccess";
import WebApp from "./WebApp";
import Concept from "./Concept";
import Legal from "./Legal";
import challenges from "./challenges.js";
import LoginAs from './LoginAs';
import SanitaryCrisis from "./SanitaryCrisis";
import Feedbacks from "./Feedbacks";
import PersonnalTeacher from "./PersonnalTeacher";
import PropTypes from "prop-types";

const routes = () => (
	<Switch>
		<Route exact path='/' component={Landing}/>

		<Route path='/contact' component={Contact}/>
		<Route path='/place' component={Places}/>
		<Route path='/seance' component={Seances}/>
		<Route path='/teacher' component={PersonnalTraining}/>
		<Route path='/coach' component={Coachs}/>
		<Route path='/plans' render={(props) =>
			<OffersLoader>{
				(offers) => (<Plans offers={offers}/>)
			}</OffersLoader>
		}/>
		<Route path='/plans2' render={(props) =>
			<OffersLoader>{
				(offers) => (<Plans offers={offers}/>)
			}</OffersLoader>
		}/>

		<Route exact path='/activateSub' 	component={ActivateSub}/>
		<Route exact path='/activateSub-success' 	component={ActivateSubSuccess}/>
		<Route exact path='/signUp' 		render={ (props) => <Guest to='/account'><SignUp {...props}/></Guest> }/>
		<Route exact path='/forgotPassword' render={ (props) => <Guest to='/account'><ForgotPassword {...props}/></Guest> }/>
		<Route path='/passwordChange' 		render={ (props)=> <Guest to='/account'><PasswordChange {...props}/></Guest> } />

		<Route path='/loginAs' render={(props)=> <OnlyAdmin to='/'><LoginAs {...props}/></OnlyAdmin>} />
		<Route path='/coaching' render={(props)=> <Private><Coaching {...props}/></Private> } />
		<Route path='/challenge' render={(props) => <Challenges {...props}/>} />
		<Route path='/account' render={(props)=> <Private><Account {...props}/></Private>} />

		<Route path='/covid19' component={SanitaryCrisis} />
		<Route path='/feedbacks' component={Feedbacks} />

		<Route path='/enterprise' component={Enterprise}/>
		<Route path='/press' component={Press}/>
		<Route path='/partneroffer' component={PartnerOfferList}/>
		<Route path='/terms' component={CGV}/>
		<Route path='/confidentialite' component={Confidentialite}/>
		<Route path='/legal' component={Legal}/>
		<Route path='/concept' component={Concept}/>
		<Route path='/webapp' component={WebApp}/>
		{challenges.map(c=>
			<Route key={`challenge_${c.id}`} exact path={c.oldPath} render={ (props) => {
				let match = Object.assign({}, props.match);
				match.params.challengeId = c.id;
				return <Challenge match={match} />
			}} />
		)}

		<Redirect from='/fr/conditions-generales.html' to = '/terms'/>

		<Redirect from='/fr/tarifs.html' to='/plans' />
		<Redirect from='/fr/abonnez-vous-tarifs.html' to='/plans'/>

		<Redirect from ='/fr/on-parle-de-nous.html' to='/press' />

		<Redirect from='/fr/prof/*' to ='/coach' />
		<Redirect from='/fr/les-instructeurs.html' to ='/coach' />
		<Redirect from='/fr/le-staff.html' to ='/coach' />

		<Redirect from='/fr/ville/*' to ='/place' />
		<Redirect from='/fr/nos-sites.html' to='/place'/>

		<Redirect from='/fr/detail/*' to='/seance' />
		<Redirect from='/fr/nos-horaires.html' to='/seance' />

		<Redirect from='*' to='' />;
	</Switch>
);

class Coachs extends Component {
	render(){
		return (
			<Switch>
				<Route path={`${this.props.match.path}/:coachId`}	component={Coach}/>
				<Route path={this.props.match.path}					component={CoachList}/>
				<Redirect from='*' to={this.props.match.path} />
			</Switch>
		);
	}
}

class Challenges extends Component {
	render() {
		return (
			<Switch>
				<Route path={`${this.props.match.path}/:challengeId`} component={Challenge} />
				<Route exact path={`${this.props.match.path}`} component={ChallengeList} />
				<Redirect from='*' to={this.props.match.path} />
			</Switch>
		);
	}
}

class Seances extends Component {
	render() {
		return (
			<Switch>
				<Route path={`${this.props.match.path}/:seanceId`} component={Seance} />
				<Route exact path={this.props.match.path}				 component={SearchPage}/>
				<Redirect from='*' to={this.props.match.path} />
			</Switch>
		);
	}
}

class PersonnalTraining extends Component {

	static contextTypes = {
		user: PropTypes.object
	};

	render() {
		let { user } = this.context;
		if (!user || !user.subscriptions.current || !user.subscriptions.current.offer.restrictions.corporate) {
			return <Redirect from='*' to={'/seance'} />
		}
		return (
			<Switch>
				<Route path={`${this.props.match.path}/:teacherId`} component={PersonnalTeacher} />
				<Route exact path={this.props.match.path}				 component={SearchTeacherPage}/>
				<Redirect from='*' to={this.props.match.path} />
			</Switch>
		);
	}
}

class Places extends Component {
	render(){
		return (
			<Switch>
				<Route path={`${this.props.match.path}/:placeId`} 	component={Place}/>
				<Route path={this.props.match.path} 				component={PlaceMap}/>
				<Redirect from='*' to={this.props.match.path} />
			</Switch>
		);
	}
}

class Account extends Component {
	render(){
		return (
			<Switch>
				<Route path={`${this.props.match.path}/profile`} component={Profile}/>
				<Route path={this.props.match.path}	render={(props) => <Dashboard {...props}/>}/>
				<Redirect from='*' to= {this.props.match.path} />
			</Switch>
		);
	}
}

export default routes;
