import React from 'react';

export default function ActivateSubSuccess(){
	return (
		<div style={{margin:"1rem auto auto auto", paddingTop:'3rem', align:'center', textAlign:'center'}}>
			<h1>Votre subscription a bien été créée</h1>
			<p style={{margin:'4rem 0rem'}}>Vous pouvez dès à présent effectuer des réservations</p>
			<a href='/seance' className='button-primary' style={{margin:'1rem'}}>Je réserve un cours</a>
		</div>
	)
}