import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Helmet} from 'react-helmet';

import Img from "./utils/Img";
import challenges from "./challenges.js";

import './ChallengeList.scss';

import challengesImg from "./images/hero.jpg"
import usersIcon from './icons/users.svg';
import emailIcon from "./icons/email.svg";
import telIcon from "./icons/tel.svg";
import separatorImg from "./images/challenges/running.jpg";

class ChallengeList extends Component {

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<div className="ChallengeList">
				<Helmet>
					<title>Challenges et Team Building | Urban Challenge</title>
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<header>
					<Img src={challengesImg} alt="Challenges" lazySrc='TODO'/>
					<h1>
						Team buildings & Events
					</h1>
				</header>
				<aside>
					<p>
						Organisez un événement exceptionnel et original au service de votre entreprise,
						le tout encadré par une équipe unique composée de Sapeurs-pompiers, commandos, et sportifs de
						haut niveau.
					</p>
					<a href="#contact">Contactez-nous</a>
				</aside>
				<section>
					<ul>
						{challenges.map(c =>
							<li key={c.id}>
								<ChallengePreview challenge={c}/>
							</li>
						)}
					</ul>
				</section>
				<section>
					<div>
						<p>
							Rien de tel que le sport pour véhiculer des valeurs de cohésion, de dépassement de soi et
							de convivialité ! C’est pourquoi Urban Challenge vous propose d’organiser des évènements
							sur-mesure pour vos team building, aéminaires et animations.
						</p>
						<p>
							Les Sapeurs-Pompiers de Paris ou commandos qui vous encadreront lors de votre événement
							véhiculent  des valeurs fortes telles que la cohésion et l’entraide. De par leur expérience,
							ils sauront vous pousser à dépasser vos limites dans la bonne humeur et la convivialité
							grâce à leur enthousiasme.
						</p>
						<p>
							Parce que nous comprenons que chaque entreprise est unique, les team buildings que nous
							proposons sont largement modulables et peuvent s'adapter selon vos besoins, pour une
							prestation livrée clé en main.
						</p>
						<p><em>Possibilité de repas pour tous les Challenges</em></p>
					</div>
					<Link to="/enterprise" className="challenge-enterprise-link">Voir nos cours entreprise</Link>
				</section>
				<Img src={separatorImg} alt="Bootcamp" lazySrc="TODO" className="separator-img"/>
				<section className="challenges-contact">
					<span id="contact"/>
					<h2>Contactez-nous</h2>
					<div>
					</div>
					<div>
						<a href="mailto:info@urban-challenge.fr">
							<Img src={emailIcon} alt="email" lazySrc="todo"/>
							info@urban-challenge.fr
						</a>
						<a href="tel:0184743200">
							<Img src={telIcon} alt="tel" lazySrc="todo"/>
							01 84 74 32 00
						</a>
					</div>
				</section>
			</div>
		);
	}
}

export default ChallengeList;

class ChallengePreview extends Component {

	render() {
		let {challenge} = this.props;
		return (
			<article className="ChallengePreview">
				<header>
					<Img src={challenge.coverPic} alt={challenge.name}/>
				</header>
				<section>
					<h3>{challenge.name}</h3>
					<ul>
						{challenge.goalsList.map((g, i) =>
							<li key={i}>{g}</li>
						)}
					</ul>
					<p>
						<Img src={usersIcon} alt="Participants"/>
						{challenge.minimumAttendees}{challenge.maximumAttendees ? ` - ${challenge.maximumAttendees}` : '+'}
					</p>
				</section>
				<Link to={`/challenge/${challenge.id}`} aria-label={`En savoir plus sur ${challenge.name}`}/>
			</article>
		);
	}
}
