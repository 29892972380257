import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Analytics from './analytics/Analytics';
import Header from './Header';
import Footer from './Footer';
import Routes from './Routes';
import GeolocationStore from './GeolocationStore';
import IntercomContainer from './utils/IntercomContainer';
import GoogleMaps from './GoogleMaps';

import SessionManager from './SessionManager';
import API from './api/CacheAPI';
import repoCreate from './repo';
import './styles/app.scss';


var repo = repoCreate();

const analyticsConfig = JSON.parse(process.env.REACT_APP_ANALYTICS || '{}');
const trackJSConfig = JSON.parse(process.env.REACT_APP_TRACKJS || '{}');

class TrackJS extends React.Component {

	componentDidMount(){
		if ( !trackJSConfig || !trackJSConfig.token || !trackJSConfig.application) return console.debug('[TrackJS]', 'skip');

		function configure(){
			if (!window.TrackJS) return console.warn("Skip TrackJS");
			let config = window.document.createElement("script");
			let configContent = document.createTextNode(`TrackJS.install({ token: "${trackJSConfig.token}", application: "${trackJSConfig.application}"});`);
			config.appendChild(configContent);
			head.appendChild(config);
			console.debug('[TrackJS]', 'Configuration ok');
		}

		let head = window.document.getElementsByTagName("head")[0];
		
		let api = window.document.createElement("script");
		api.src = "https://cdn.trackjs.com/agent/v3/latest/t.js";
		api.async = false;
		api.defer = false;
		head.appendChild(api);

		if(api.readyState) {  // only required for IE <9
			api.onreadystatechange = function() {
				if ( api.readyState === "loaded" || api.readyState === "complete" ) {
					api.onreadystatechange = null;
					configure();
				}
			};
		} else {  //Others
			api.onload = function() {
				configure();
			};
		}
	}

	render () {
		return null;
	}
}

class App extends React.Component {

	static childContextTypes = {
		repo : PropTypes.object,
		API : PropTypes.object
	};

	getChildContext = () => {
		return { repo, API };
	};

	render() {
		return (
			<Analytics config={analyticsConfig}>
				<TrackJS/>
				<div style={{"display": "none"}} id="gmapHidden"/>
				<GoogleMaps browserKey='AIzaSyD4HXgxILjI6m4bwERSabp1QN2PvEcZqdU'>
					<BrowserRouter>
						<Helmet>
							<title>Le sport en extérieur à Paris | Urban Challenge</title>
							<meta name='description' content="Urban Challenge propose un concept de pratique sportive en groupe et en extérieur, appelé Urban Training, à Paris, encadrés par des instructeurs issus du corps des sapeurs-pompiers, commandos ou étant athlètes de haut niveau"/>
						</Helmet>
						<SessionManager>
							<GeolocationStore>
								<IntercomContainer>
									<Header/>
									<main>
										<Routes/>
									</main>
									<Footer/>
								</IntercomContainer>
							</GeolocationStore>
						</SessionManager>
					</BrowserRouter>
				</GoogleMaps>
			</Analytics>
		);
	}
}

export default App;
