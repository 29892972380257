import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Intercom from 'react-intercom';

export default class IntercomContainer extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	static contextTypes = {
		user: PropTypes.object
	};

	render() {
		let hideIntercom = false;
		let user = null;

		if(this.context.user)
			user = {
				id: this.context.user.id,
				name: this.context.user.firstname,
				email: this.context.user.email,
				phone: this.context.user.phone
			};

		return (
			<div>
				<Intercom appID="o5kga08b" {...user} hide_default_launcher={hideIntercom} custom_launcher_selector={'.chat-button'}/>
				{this.props.children}
			</div>
		);
	}
}
