import React, {Component} from 'react';

import SeanceSearch from "./SeanceSearch";

import './SearchPage.scss';

class SearchPage extends Component {
	render = () => {
		return (
			<div className="SearchPage">
				<SeanceSearch/>
			</div>
		);
	};
}

export default SearchPage;
