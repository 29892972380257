import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';

import Img from './utils/Img';

import './SeanceList.scss';
import appIcon from './images/app-logo.svg';
import addCalendarIcon from "./icons/calendar-add.svg";
import PropTypes from "prop-types";

class SeanceList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			displayDay: null
		}
	}

	render() {
		let {seances} = this.props;

		if (!seances.length) {
			return null;
		}

		let day = null;
		return (
			<ul className={`SeanceList ${this.props.className}`}>
				{seances.map(s => {
					const d = moment(s.starts).format("YYYY-MM-DD");
					let addDay = (day !== d);
					day = d;
					return (
						<Fragment key={s.id}>
							{this.props.displayDay && addDay &&
							<li className="seance-list-day">
								<strong>{moment(day).format("dddd DD MMMM")}</strong>
							</li>
							}
							<li>
								<SeancePreview seance={s} addCalendar={this.props.addCalendar}/>
							</li>
						</Fragment>
					);
				})}
			</ul>
		);
	}
}

class SeancePreview extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	stopPropa = (e) => {
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
	};

	render() {
		let {seance} = this.props;
		let duration = moment.duration(moment(seance.ends).diff(moment(seance.starts)));
		let dateFormat = 'HH:mm';
		let pastSeance = false;
		const getCoachName = (coach) => !coach.notUniq ? coach.firstname : `${coach.firstname} ${coach.lastname.slice(0,2)}.`;
		if(seance.starts < moment().toDate().getTime())
			pastSeance = true;

		return (
			<article className={`SeancePreview ${(this.props.addCalendar ? " has-action" : "")} ${pastSeance ? "past-seance" : ""}`}>
				<header className={seance.coaches.length > 1 ? "multiple-coaches" : ""}>
					{!!seance.coaches.length && seance.coaches.map((c, index) => {
						if (index < 2) {
							return (<Img key={c.id} src={c.avatar || appIcon} alt="Avatar" lazySrc="todo"/>);
						}
						return null;
					}
					)}
					{!seance.coaches.length &&
						<Img src={appIcon} alt="no avatar" lazySrc="todo"/>
					}
				</header>
				<section>
					<div>
						<span>{moment(seance.starts).format(dateFormat)} - {duration / (60 * 1000) + "min"}</span>
						<h3>{seance.name}</h3>
						{seance.event === 1 && <div className="EventPreview"><span>event</span></div> }
					</div>
					<div>
						<strong>{seance.place.name}</strong>
						{!!seance.coaches.length &&
						<span>{seance.coaches.map((c, index) => {
							if(index === 0) {
								if(seance.coaches.length > 1)
									return `${getCoachName(c)} & `;
								else return `${getCoachName(c)}`;
							}
							if(index === 1)
								return `${getCoachName(c)}`;
							if(index === 2)
								return ' + ' + (seance.coaches.length - 2);
							return null;
						})}</span>
						}
						{!seance.coaches.length &&
						<span><em>(pas de coach)</em></span>
						}

					</div>
				</section>
				{this.props.addCalendar &&
				<a href={this.context.API.getUrl(`/calendar/${seance.id}`, {appName:'uc'})} download data-seance-id={seance.id} className="add-seance-calendar" onClick={this.stopPropa}>
					<img src={addCalendarIcon} alt="Calendrier"/>
				</a>
				}
				{seance.booking_price > 0 && <div className="PricePreview">{seance.booking_price / 100}€</div>}
				{!!seance.stage && <div className="StagePreview">Stage</div>}
				<Link to={`/seance/${seance.id}`} aria-label={`Voir la séance ${seance.id}`} className="link-to-seance"/>
			</article>
		);
	}
}

export default SeanceList;
