import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';

import FormField from './FormField';
import Modal from "./Modal";

import './PaymentForm.scss';

import visa from './icons/visa.svg';
import mastercard from './icons/mastercard.svg';
import cvc from './icons/cc-cvc.svg';

export default class PaymentFormCompo extends React.Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);

		this.state = {
			step: "FORM",
			type: {
				value: '',
				invalid:true,
			},
			ccOwner: {
				value: '',
				placeholder: "Nom",
				autoFocus: true,
				label: "Titulaire de la carte",
				autoComplete: "cc-name",
				type: "text"
			},
			ccNumber: {
				value: '',
				placeholder: "XXXX XXXX XXXX XXXX",
				type: 'text',
				label: "Numéro de carte",
				autoComplete: "cc-number"
			},
			ccExpiration: {
				value: '',
				placeholder: "MM/AA",
				autoComplete: "cc-exp",
				label: "Exp.",
				maxLength: 5,
				type: "text"
			},
			cvc: {
				value: '',
				placeholder: "XXX",
				label: "Code",
				autoComplete: "cc-csc",
				type: "text",
				maxLength: 3
			},
			cgv: {
				value: false
			}
		};
	}

	componentDidMount() {
		window.scroll(0, 0);
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "hidden";
		let header = document.getElementsByClassName('Header')[0];
		header.style.zIndex = 1;
	}

	onSubmit = (event) => {
		event.preventDefault();

		let newState = Object.assign({}, this.state);
		
		if (this.props.showCGV && !newState.cgv.value) {
			newState.cgv.invalid = true;
			this.setState(newState);
			return;
		}

		let error = false;
		if (newState.cvc.value.length !== 3) {
			newState.cvc.invalid = true;
			error = true;
		}

		if (!newState.ccExpiration.value) {
			newState.ccExpiration.invalid = true;
			error = true;
		} else {
			let exp = newState.ccExpiration.value.split('/');
			let y = new Date().getFullYear();
			if (+exp[0] > 12 || +exp[1] + 2000 < y || +exp[1] + 2000 > y + 20) {
				newState.ccExpiration.invalid = true;
				error = true;
			}
		}
		if (!newState.ccOwner.value.trim()) {
			newState.ccOwner.invalid = true;
			error = true;
		}
		if (newState.ccNumber.value.replace(/[^0-9]/g, '').length !== 16) {
			newState.ccNumber.invalid = true;
			error = true;
		}

		if (newState.type.value === '' ) {
			newState.type.invalid = true;
			error = true;
		}

		if (error) {
			this.setState(newState);
			return;
		}
		let exp = this.state.ccExpiration.value.split('/');
		let cardData = {
			type: this.state.type.value,
			owner : this.state.ccOwner.value.trim(),
			number : this.state.ccNumber.value.replace(/ /g, ''),
			cvc : this.state.cvc.value.trim(),
			expiration : {
				month : exp[0].trim(),
				year : exp[1].trim()
			},
			app : 'uc'
		};

		this.props.onSubmit(cardData);
	};

	onChange = (event) => {
		let field = Object.assign({}, this.state[event.target.name]);
		field.value = event.target.value;
		field.invalid = false;
		if (event.target.name === 'ccNumber') {
			field.value = this.ccChunk(field.value);
		}
		if (event.target.name === 'ccExpiration') {
			field.value = this.expChunk(field.value);
		}
		if (event.target.name === 'cgv') {
			field.value = event.target.checked;
		}
		this.setState({
			[event.target.name]: field
		});
	};

	ccChunk = (value) => {
		return value.replace(/[^\d]/g, '').slice(0,16).match(/.{0,4}/g).join(' ').replace(/ $/, '');
	};

	expChunk = (value) => {
		return value.replace(/[^\d]/g, '').slice(0,4).match(/.{0,2}/g).join('/').replace(/\/$/, '');
	};

	render() {
		const cardTypeStyle = {};
		if( this.state.type.invalid ) {
			cardTypeStyle.color = 'red';
		}
		return (
			<Modal
				title="Moyen de paiement"
				className="PaymentForm"
				confirmFn={this.onSubmit}
				confirmLb="J'achète"
				cancelFn={this.props.back}
				cancelLb="Changer d'offre"
				show={true}
			>
				<form>
					{this.state.error &&
						<p className='payment-form-error'>{this.state.error}</p>
					}

					<label style={cardTypeStyle}>Type de carte</label>
					<div className="accepted-cards">
						<img src={visa} alt='Visa' onClick={e=>this.setState({type: {value:'visa', invalid:false}})} style={{border: this.state.type.value==='visa' && '1px solid green'}}/>
						<img src={mastercard} alt='Mastercard' onClick={e=>this.setState({type: {value:'mastercard', invalid:false}})} style={{border: this.state.type.value==='mastercard' && '1px solid green'}}/>
					</div>
					{
						['ccOwner', 'ccNumber'].map(f => {
							return <FormField key={f} name={f} field={this.state[f]} onChange={this.onChange}/>
						})
					}
					<div className="other-infos">
						{
							['ccExpiration', 'cvc'].map(f => {
								return <FormField key={f} name={f} field={this.state[f]} onChange={this.onChange}/>
							})
						}
						<img src={cvc} alt="CVC"/>
					</div>

					{this.props.showCGV &&
					<Fragment>
						<label className={'payment-cgv' + (this.state.cgv.invalid ? " invalid" : "")}>
							<input onChange={this.onChange} name="cgv" type="checkbox" checked={this.state.cgv.value}/>
							<span>
							Je confirme avoir lu et accepté les <Link to='/cgv' target="_blank">conditions générales de ventes</Link>.
							</span>
						</label>
						{!!this.props.offer.renewable && <p className="payment-cgv-disclaimer">
							En cliquant sur “J’achète ma Formule”, vous autorisez Urban Challenge <strong>à vous débiter tous les mois</strong> jusqu’à ce que
							vous annuliez votre abonnement.&nbsp;
							{!this.props.offer.commitment && "Sans engagement d’un mois sur l’autre."}
							{!!this.props.offer.commitment && `Avec un engagement de ${this.props.offer.commitment} mois.`}
						</p>}

					</Fragment>
					}
				</form>
			</Modal>
		);
	}
}
