import React, {Component, Fragment, useState, useEffect} from 'react';
import {Switch, Route, Redirect, NavLink, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/fr';

import FormField from "./FormField";
import MsgPage from "./utils/MsgPage";
import Pagination from "./utils/Pagination";
import loadOnlyPartials from './loadOnlyPartials';
import Img from './utils/Img';
import API from './api/FunctionnalAPI';

import './Coaching.scss';
import calendarIcon from "./icons/calendar.svg";
import defaultFemaleAvatar from "./images/avatar-female--colored.png";
import defaultMaleAvatar from "./images/avatar-male--colored.png";
import messageIcon from "./icons/message-square.svg";
import MessageList from "./MessageList";
import sendIcon from "./icons/send--light.svg";

const intensityList = {
	'-easy' : 'Trop facile',
	'=good' : 'Bonne intensité',
	'+hard' : 'Trop intense',
};

export default class Coaching extends Component {

	static contextTypes = {
	  user: PropTypes.object
  };

	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	render() {
		let p = this.props.match.path;

		return (
			<div className="Coaching">
				<header>
					<h1>Espace coach</h1>
					<nav className='CoachingMenu'>
						<ul>
							<li><NavLink to={`${p}/incoming`} activeClassName="active">Cours</NavLink></li>
							<li><NavLink to={`${p}/full`} activeClassName="active">Historique</NavLink></li>
							<li><NavLink to={`${p}/invoices`} activeClassName="active">Relevé</NavLink></li>
							<li><NavLink to={`${p}/feedback`} activeClassName="active">Feedback</NavLink></li>
							<li><NavLink to={`${p}/bonus`} activeClassName="active">Bonus</NavLink></li>
							<li><NavLink to={`${p}/params`} activeClassName="active">Paramètres</NavLink></li>
							{/*	TODO uncomment next line to enable personnal coaching */}
							{/*<li><NavLink to={`${p}/personnal`} activeClassName="active">Individuel</NavLink></li>*/}
						</ul>
					</nav>
				</header>
				<Switch>
					<Route path={`${p}/incoming`} component={CoachingIncoming} />
					<Route path={`${p}/personnal`} component={ CoachingPersonnal } />
					<Route path={`${p}/full`} component={CoachingPast} />
					<Route path={`${p}/invoices`} component={CoachingInvoices} />
					<Route path={`${p}/feedback`} component={CoachingFeedback} />
					<Route path={`${p}/bonus`} component={CoachingBonus} />
					<Route path={`${p}/params`} component={CoachingParams} />
					<Redirect from='*' to={`${p}/incoming`} />
				</Switch>
			</div>
		);
	}
}

class CoachingParams extends Component {
	static contextTypes = {
		API: PropTypes.object,
		user: PropTypes.object
	};
	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING',
			coach: this.context.user
		};
	}
	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ form: { ...this.state.form, [name]: value } });
	}
	saveChange = () => {
		if (
			(!this.state?.form.coach_siren && !this.state.coach.coach_siren) ||
			(this.state?.form.coach_siren === '' && !!this.state.coach.coach_siren)
		) return this.setState({ error: 'required' });

		this.context.API.patchCurrentUser(this.state.form)
		.then(res => {
			window.location.reload();
		})
		.catch(err => console.error(err));
	};

	render() {
		return (
			<div className="CoachingParams">
				<h4>Paramètres :</h4>
				<FormField field={{
					value: this.state.coach.coach_siren,
					label: "Numéro de Siren (requis)",
					type: 'text',
					error: this.state.error
				}} name={"coach_siren"} onChange={this.handleChange} />
				<FormField field={{
					value: this.state.coach.coach_number_tva,
					label: "Numéro de TVA (laisser vide si, non soumis à la TVA)",
					type: 'text'
				}} name={"coach_number_tva"} onChange={this.handleChange} />
				<input type='submit' className={'SubmitButton'} value="Sauvegarder" onClick={this.saveChange} />
			</div>
		);
	}
}

class CoachingBonus extends Component {

	static contextTypes = {
	  API: PropTypes.object,
	  user: PropTypes.object
  };

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING',
			coachConversions: []
		};
	}
	// call to api for bonuses or call in parent component
	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({
			step: 'LOADING'
		});

		this.context.API.coachConversionSearch()
		.then(res => {
			this.setState({
				step: 'OK',
				coachConversions: res
			});
		})
		.catch(e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	render() {
		return (
			<div>
				<div className="CoachingBonusLabel">Calcul de votre bonus : 10€ par séance d’essai convertie en achat d’une formule de plus de 15€ dans les 30 jours.</div>
				<ul className="CoachingIncomingList">
					{this.state.coachConversions.map(cc => {
						return (<li key={cc.id}>
							<article className={"CoachingBonusPreview"}>
								<p>
									<b>{cc.user.firstname} {cc.user.lastname}</b>
									<span>{cc.offer.name} - {moment(cc.newSub.startDate).format('DD/MM/YYYY')}</span>
								</p>
								<Link to={`/coaching/full/${cc.seance.id}`}>
									<span>{cc.seance.name} à {cc.seance.place.name}</span>
									<em>{moment(cc.seance.startDate).format('DD/MM/YYYY à HH:mm')}</em>
								</Link>
								<strong>10€</strong>
							</article>
						</li>)
					})}
				</ul>
			</div>
		);
	}
}

class CoachingFeedback extends Component {

	static contextTypes = {
		API: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING',
			feedback: [],
			feedbackDisplayed: [],
			stats: {
				percent: 0,
				score1: { total:0, pond: 0 },
				score2: { total:0, pond: 0 },
				score3: { total:0, pond: 0 },
				total: 0
			},
			filter: {
				viewOnlyCommented: true,
				selectOnly: null
			}
		};
	};

	componentDidMount() {
		this.load();
	};

	calculateStats = () => {
		let stats = {
			percent: 0,
			score1: { total:0, pond: 0 },
			score2: { total:0, pond: 0 },
			score3: { total:0, pond: 0 },
			total: 0
		};
		this.state.feedback.forEach(f => {
			if (f.score === 3) {
				stats.score3.pond += 1;
				stats.score3.total++;
			}
			else if (f.score === 2) {
				stats.score2.pond += 0.5;
				stats.score2.total++;
			}
			else if (f.score === 1) {
				stats.score1.pond += 0.3;
				stats.score1.total++;
			}
		});
		stats.percent = Math.round(
			( (stats.score3.pond + stats.score2.pond + stats.score1.pond) / this.state.feedback.length) * 100
		);
		this.setState({ stats });
	};

	filterMyFeedBack = (feedback) => {
		return feedback.filter( f => {
			if (!!f.coach && f.coach === this.context.user.id) return f;
			else if (!f.coach) return f;
			else return null;
		}).filter(c => c);
	};

	load = () => {
		this.setState({ step: 'LOADING' });

		this.context.API.feedbackSearch()
		.then(feedback => {
			this.setState({
				step: 'OK',
				feedback: this.filterMyFeedBack(feedback.data)
			});
		})
		.then(() => this.calculateStats() )
		.then(() => this.applyFilter() )
		.catch(console.error)
		;
	};

	applyFilter = () => {
		let tmp = this.state.feedback.map(f => {
			if (this.state.filter.selectOnly === f.score && this.state.filter.selectOnly !== null) {
				if (this.state.filter.viewOnlyCommented && !!f.other) return f;
				else if (!this.state.filter.viewOnlyCommented) return f;
				else return null;
			} else if (!this.state.filter.selectOnly) {
				if (this.state.filter.viewOnlyCommented && !!f.other) return f;
				else if (!this.state.filter.viewOnlyCommented) return f;
				else return null;
			}
			return null;
		}).filter(e=>e);

		this.setState({ ...this.state, feedbackDisplayed: tmp });
	};

	setSelectOnly = (name, e) => {
		e.preventDefault();
		if (!this.state.filter.selectOnly || this.state.filter.selectOnly !== name)
			this.setState({
				filter: {
					...this.state.filter,
					selectOnly: name
				}
			}, this.applyFilter);
		else
			this.setState({
				filter: {
					...this.state.filter,
					selectOnly: null
				}
			}, this.applyFilter);
	};

	viewOnlyCommented = () => {
		this.setState({
			filter: {
				...this.state.filter,
				viewOnlyCommented: !this.state.filter.viewOnlyCommented
			}
		}, this.applyFilter);
	};

	render() {
		if (this.state.step === 'LOADING')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR"/>;

		if (!this.state.feedback.length) {
			return <MsgPage type="EMPTY" title={"Aucun feedback pour le moment"} />;
		}

		let comment = 0;

		this.state.feedback.forEach(f => {
			if (!!f.other) comment++;
		});

		return (
			<div>
				<div className={"CoachingFeedback"}>
					<aside>

						<div>
							<strong>{this.state.stats.percent}%</strong>
							<span>de satisfaction</span>
							<em>({this.state.feedback.length} avis)</em>
						</div>

						<div>
							<ul>
								<li>
									<strong>Au top !</strong>
									<button className={
										(this.state.filter.selectOnly === 3 ? 'selected' : '') + (!this.state.stats.score3.total ? ' empty' : '')
									} onClick={this.setSelectOnly.bind(this, 3)} >
										{!!this.state.stats.score3.total &&
											<span style={{ width: `${((this.state.stats.score3.total / this.state.feedback.length) * 100)}%` }}>{this.state.stats.score3.total}</span>
										}
									</button>
								</li>
								<li>
									<strong>C'était bien</strong>
									<button className={
										(this.state.filter.selectOnly === 2 ? 'selected' : '') + (!this.state.stats.score2.total ? ' empty' : '')
									} onClick={this.setSelectOnly.bind(this, 2)} >
										{!!this.state.stats.score2.total &&
											<span style={{ width: `${((this.state.stats.score2.total / this.state.feedback.length) * 100)}%` }} >{this.state.stats.score2.total}</span>
										}
									</button>
								</li>
								<li>
									<strong>Vraiment bof</strong>
									<button className={
										(this.state.filter.selectOnly === 1 ? 'selected' : '') + (!this.state.stats.score1.total ? ' empty' : '')
									} onClick={this.setSelectOnly.bind(this, 1)} >
										{!!this.state.stats.score1.total &&
											<span style={{ width: `${((this.state.stats.score1.total / this.state.feedback.length) * 100)}%` }}>{this.state.stats.score1.total}</span>
										}
									</button>
								</li>
							</ul>
							<label>
								<input type="checkbox" name="viewOnlyCommented" defaultChecked={ this.state.filter.viewOnlyCommented } onClick={ this.viewOnlyCommented } />
								Voir uniquement avis avec commentaire ({ comment })
							</label>
						</div>

					</aside>

				</div>
				<ul className="CoachingIncomingList">
					{this.state.feedbackDisplayed.map(f => {
						return (
							<li key={f.id}>
								<article className={"CoachingFeedbackPreview"}>
									<Link to={`/coaching/full/${f.seance.id}`}>
										<span>{f.seance.name} - {f.seance.place.name} - {moment(f.seance.start_date).format('HH:mm DD/MM/YYYY')}</span>
									</Link>

									{ !!f.other && <p>{f.other}</p> }

									<div>
										<span>{f.score} / 3</span>
										{ !!f.difficulty && <span>{intensityList[f.difficulty]}</span> }
									</div>
								</article>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

}

class CoachingPast extends Component {

	render(){
		let {match} = this.props;
		let p = match.path;
		let fromDate =  moment().add(-1,'year').toDate().getTime();
		let toDate = moment().toDate().getTime();

		return (
			<Switch>
				<Route exact path={`${p}`}>
					<CoachingLoader from={fromDate} to ={toDate} revertOrder ={true}>
						{({seances}) =>
						<CoachingIncomingList seances={seances} match={match} noSeanceTitle="Rien pour l'instant"/>
						}
					</CoachingLoader>
				</Route>
				<Route exact path={`${p}/:seanceId`} component={CoachingIncomingOne}/>
				<Redirect from='*' to = {p} />
			</Switch>
		);
	}
}

const CoachingPersonnal = ({ match }) => {
	const [ state, update ] = useState({ step : "INIT", teaching: [] });

	const loadTeacher = () => {
		if (["OK","ERROR"].indexOf(state.step) >= 0) return;
		update({ ...state, step: "LOADING" });
		API.teacherStudentGet({})
		.then(teachers => {
			update({
				step: 'OK',
				teaching: teachers,
			});
		})
		.catch(err => {
			if (err.statusCode === 404 && err.restCode === "resourceNotFound") return update({ step: 'OK', teaching: [] });

			update({
				step: 'ERROR',
				teaching: [],
				error: err
			});
		});
	};
	useEffect(loadTeacher, []);

	if (state.step === 'INIT' || state.step === 'LOADING') return <MsgPage type='LOADER' title="Chargement des cours particuliers"/>;
	if (state.step === 'ERROR') return <MsgPage type='ERROR' error={ state.error }/>;

	return (
		<Switch>
			<Route exact path={`${match.path}`}>
				<ul className={'CoachingIncomingList'}>
					{!state.teaching.length && <MsgPage type="ERROR" title="Aucune demandes de cours particulier"/> }
					{!!state.teaching.length && state.teaching.map(t => (
						<CoachingPersonnalList key={t.id} teaching={ t } match={ match } />
					))}
				</ul>
			</Route>
			<Route exact path={`${match.path}/:userId`} component={ CoachingPersonnalOne } />
			<Redirect from='*' to = {match.path} />
		</Switch>
	);
};

const CoachingPersonnalList = ({ match, teaching }) => {
	return (
		<li>
			<Link to={`${match.path}/${teaching.id}_${teaching.teacher}_${teaching.creation.user.id}`}>
				<article>
					<h3>{teaching.creation.user.firstname} {teaching.creation.user.lastname}</h3>
					<div>
						<span>{teaching.lesson}</span>
					</div>
				</article>
			</Link>
		</li>
	);
};

class CoachingPersonnalOne extends Component {

	static contextTypes = {
		API: PropTypes.object,
		user: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		const params = props.match.params.userId.split('_');
		this.state = {
			step: "LOADING",
			firstMessage: params[0],
			teacher: params[1],
			recipient: params[2],
			messages: [],
			text: ''
		};
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		this.setState({ step: 'LOADING' });
		this.context.API.getPersonnalMessage({
			recipient: this.state.recipient,
			teacher_id: this.state.teacher
		})
		.then(messages => {
			this.setState({
				step: 'OK',
				messages: messages
			});
		})
		.catch(e => {
			console.error(e);
			this.setState({ step:'ERROR', error: e });
		});
	};

	handleChange = (e) => {
		this.setState({ text: e.target.value	});
	};
	sendMessage = () => {
		this.context.API.sendPersonnalMessage({
			recipient: { id: this.state.recipient },
			teacher: { id: this.state.teacher },
			lesson: this.state.messages[0].lesson,
			message: this.state.text
		})
		.then(() => {
			this.setState({ text: '' });
			this.load();
		})
		.catch(e => {
			console.error(e);
			this.setState({ step:'ERROR', error: e });
		});
	};

	render() {
		if (this.state.step === 'LOADING') return <MsgPage type='LOADER' title="Chargement de la discussion" />
		if (this.state.step === 'ERROR') return <MsgPage type='ERROR' error={ this.state.error } />
		return (
			<ul className={'CoachingIncomingList'}>
				<MessageList messages={this.state.messages} />
				<div className={"CoachingNewMessage"}>
					<textarea value={this.state.text} onChange={this.handleChange} onSubmit={this.sendMessage} />
					<button onClick={this.sendMessage} disabled={this.state.text === ''}>
						<img src={sendIcon} alt="Envoyer"/>
					</button>
				</div>
			</ul>
		);
	}
}

class CoachingInvoices extends Component {

	static contextTypes = {
	  API: PropTypes.object,
	  user: PropTypes.object
  };

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: "LOADING",
			page: 1
		};
	}

	componentDidMount() {
		this.load();
	}

	sortByCreationDate = (a, b) => {
		if (a.creationDate > b.creationDate)
			return -1;
		if (a.creationDate < b.creationDate)
			return 1;
		return 0;
	};

	// TODO Vincent à remplacer par l'appel API + gestion d'erreur
	load = () => {
		this.setState({step: 'LOADING'});
		this.context.API.coachInvoiceSearch(this.context.user.id)
		.then(invoices => {
			this.setState({
				step:'OK',
				invoices: invoices.sort( this.sortByCreationDate )
			});
		})
		.catch(e=>{
			console.error(e);
			this.setState({step:'ERROR'});
		});
	};

	onPageChanged = (page) => {
		this.setState({page: page});
	};

	render () {
		if (this.state.step === 'LOADING') {
			return <MsgPage type="LOADER" title="Chargement des factures..."/>
		}

		if (this.state.step === 'ERROR') {
			return <MsgPage type="ERROR"/>
		}

		let {invoices} = this.state;
		let nbPerPage = 5;
		let first = (this.state.page - 1) * nbPerPage;
		let last = first + nbPerPage;
		let pageMax = Math.ceil(invoices.length / nbPerPage);

		invoices = invoices.slice(first, last);
		return (
			<div className="CoachingInvoices">
				{!!invoices.length ?
				<Fragment>
				<ul>
					{invoices.map(i =>
					<li key={`inv_${i.id}`}>
						<CoachingInvoice invoice={i}/>
					</li>
					)}
				</ul>
				<Pagination current={this.state.page} max={pageMax} onPageChanged={this.onPageChanged}/>
				</Fragment>
				:
				<MsgPage type="EMPTY" title="Aucun relevé pour l'instant" msg="Vos relevés apparaîtront ici à la fin de chaque période"/>
				}
			</div>
		)
	}
}

class CoachingInvoice extends Component {
	render() {
		let {invoice} = this.props;
		return (
			<article className="CoachingInvoice">
				<strong>
					<span>{moment(invoice.month).format('MMMM YYYY')}</span>
					<span>{invoice.value/100}€</span>
				</strong>
				{/*Keep the target="_blank" attribute to handle some inferior browsers*/}
				{invoice.url &&
				<a href={invoice.url} download={"UrbanChallenge_Facture_"+ invoice.id} target="_blank" rel="noopener noreferrer">
					<span>Télécharger</span>
				</a>
				}
			</article>
		)
	}
}

class CoachingIncoming extends Component {

	render() {
		let {match} = this.props;
		let p = match.path;
		let fromDate = moment().add(-3,'days').toDate().getTime();
		return (
			<Switch>
				<Route exact path={`${p}`}>
					<CoachingLoader from = {fromDate}>
						{({seances}) =>
						<CoachingIncomingList seances={seances} match={match} />
						}
					</CoachingLoader>
				</Route>
				<Route exact path={`${p}/:seanceId`} component={CoachingIncomingOne}/>
				<Redirect from='*' to = {p} />
			</Switch>
		)
	}
}

class CoachingLoader extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING',
			reload: this.reload
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({
			step: 'LOADING'
		});

		return this.context.API.seanceSearch({coach_id: this.context.user.id, from : this.props.from, to:this.props.to})
		.then(res => {
			let seances = this.context.repo.put(res);
			if(this.props.revertOrder)
				seances.sort((a,b) => b.starts - a.starts);
			else
				seances.sort((a,b) => a.starts - b.starts);
			return loadOnlyPartials(this.context.API.placeGet, this.context.repo, seances.map(s=>s.place) )
			.then( () => {
				this.setState({
					step: 'OK',
					seances
				});
			})
			;
		})
		.catch(e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	render() {
		if (this.state.step === 'LOADING')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR"/>;

		return this.props.children(this.state);
	}
}

class CoachingIncomingList extends Component {

	render() {
		if (!this.props.seances.length) {
			let title = this.props.noSeanceTitle || "Aucune séance à venir";
			return <MsgPage type="EMPTY" title={title} />;
		}

		return (
			<ul className="CoachingIncomingList">
				{this.props.seances.map(s => {
					let className = "CoachingIncomingPreview";
					if (moment(s.ends).isBefore(moment())) className += " past";

					return (
						<li key={s.id}>
							<article className={className}>
								<Link to={`${this.props.match.path}/${s.id}`}/>
								<h3>{s.__ref === 'Seance' ? '':'*'} {s.name}</h3>
								<div>
									<span>{s.place.name}</span>
									<span>Le {moment(s.starts).format('DD/MM/YYYY')} de {moment(s.starts).format('HH:mm')} à {moment(s.ends).format('HH:mm')}</span>
								</div>
							</article>
						</li>
					);
				})}
			</ul>
		);
	}
}

class CoachingIncomingOne extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step: 'LOADING', reload:this.reload };
	}

	componentDidMount(){
		this.reload();
	}

	stopPropa = (e) => {
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
	};

	reload = () => {
		this.setState({step: 'LOADING'});
		this.context.API.seanceGet(this.props.match.params.seanceId)
		.then(res => {
			if (!res[0])
				return this.setState({
					step: 'OK',
					seance: null
				});
			let seance = this.context.repo.put(res)[0];

			return loadOnlyPartials(this.context.API.placeGet, this.context.repo, [seance.place])
			.then(() => {
				return this.context.API.bookingList({seance_id: seance.id})
			})
			.then(bookings => {
				bookings = bookings.filter(b => !b.cancelation);
				bookings = this.context.repo.put(bookings);
				return loadOnlyPartials(this.context.API.userGet, this.context.repo, bookings.map(b => b.user))
				.then(() => {
					return this.setState({
						step: 'OK',
						seance,
						bookings
					});
				})
			})
		})
		.catch(e => {
			console.error(e);
			this.setState({step: 'ERROR'});
		});
	};

	changePresence = (event, bId, presence) => {
		event.preventDefault();

		this.setState((state, props) => {
			let b = state.bookings.find(b => b.id === bId);
			b.presence.pending = true;
			b.presence.error = false;
			return {bookings: state.bookings};
		}, () => {
			this.context.API.bookingUpdate(bId, {presence_value: presence})
			.then(() => {
				this.setState((state, props) => {
					let b = state.bookings.find(b => b.id === bId);
					b.presence.value = presence;
					b.presence.pending = false;
					b.presence.error = false;
					return {
						bookings: state.bookings
					};
				});
			})
			.catch(e => {
				this.setState((state, props) => {
					let b = state.bookings.find(b => b.id === bId);
					b.presence.value = presence;
					b.presence.pending = false;
					b.presence.error = true;
					return {
						bookings : state.bookings
					};
				});
			});
		});
	};

	render() {
		if (this.state.step === 'LOADING')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR"/>;

		if (!this.state.seance) {
			return <MsgPage type="WARNING" title="Cette séance n'existe pas."/>;
		}
		let s = this.state.seance;
		let owned = this.state.seance.coaches.some( c => +c.id === this.context.user.id );

		return (
			<article className='CoachingIncomingOne'>
				{!owned &&
				<strong className="not-yours">Vous n'êtes pas le coach attitré !</strong>
				}
				<header>
					<h2>{s.name}</h2>
					<span>
						<time dateTime={s.starts}>Le {moment(s.starts).format('DD/MM/YYYY')} de {moment(s.starts).format('HH:mm')} à {moment(s.ends).format('HH:mm')}</time>
						<a href={this.context.API.getUrl(`/calendar/${s.id}`, {appName:'uc'})} download data-seance-id={s.id} className="add-seance-calendar" onClick={this.stopPropa}>
							<img src={calendarIcon} alt="Calendrier"/>
						</a>
					</span>
					<span>{s.place.name}</span>
					<span>{this.state.bookings.length} participants</span>
					<AddUserByCoach style={{justifyContent:'center'}} seanceId = {s.id} onSuccess={this.reload}/>
					{!!s.place.coachPractical && <div className="coach_practical">
						<strong>Infos pratiques coach</strong>
						<div className="richText" dangerouslySetInnerHTML={{__html: s.place.coachPractical}}/>
					</div>
					}
					{!!s.place.practical && <div className="practical">
						<strong>Infos pratiques</strong>
						<div className="richText" dangerouslySetInnerHTML={{__html: s.place.practical}}/>
					</div>
					}
				</header>
				<AttendeeMessage seanceId={s.id}/>
				<ul className="CoachingBookingList">
					{this.state.bookings.map(b =>
						<li key={b.id}>
							<Booking booking={b} changePresence={this.changePresence}/>
						</li>
					)}
				</ul>
			</article>
		)
	}
}

class AddUserByCoach extends Component {

	static contextTypes = {
		API: PropTypes.object,
		repo: PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = { step: 'REDUCED' };
	}

	goForm = (event) => {
		event.preventDefault();
		this.setState({ step: 'FORM', email: '' });
	};

	onChange = (event) => {
		this.setState({ [event.target.name] : event.target.value });
	};

	submit = (event) => {
		event.preventDefault();
		this.setState({step:'PENDING'});
		this.context.API.bookingCreate({
			seance : {id:this.props.seanceId},
			for:{email:this.state.email}
		})
		.then( resp => {
			this.setState({step:'SUCCESS'});
		})
		.catch(e => {
			let errorMsg = e.userMsg || 'Une erreur est survenue';
			this.setState({step:'ERROR', errorMsg});
		})
	};

	cancel = (event) => {
		event.preventDefault();
		this.setState({ step: 'REDUCED' });
	};

	render() {
		let {step, errorMsg} = this.state;

		if( step === 'REDUCED') return <span><button onClick={this.goForm}>Ajouter un participant</button></span>
		if( step === 'PENDING') return <MsgPage type="LOADER" title='Ajout en cours' confirmFunc={this.cancel} confirmLb='Fermer'/>;
		if( step === 'ERROR') return <MsgPage type="ERROR" title='Erreur' msg={errorMsg} confirmFunc={this.cancel} confirmLb='Fermer'/>;
		if( step === 'SUCCESS') return <MsgPage type="SUCCESS" title='Ajout réussi' confirmFunc={this.props.onSuccess} confirmLb='Fermer'/>;
		if( step === 'FORM') return (
			<form onSubmit={this.submit} style={{align:'center', border:'1px solid black', padding:'1rem'}}>
				<input type='email' name='email' placeholder='Adresse email' value = {this.state.email} onChange={this.onChange} />
				<div style={{flexDirection:'row', justifyContent:'space-around'}}>
					<button type='submit' onClick={this.submit}>Confirmer</button>
					<button onClick={this.cancel}>Annuler</button>
				</div>
			</form>
		);
	}
}

class AttendeeMessage extends Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.state = {step: 'INIT'};
	}

	componentDidMount(){
		this.reload();
	}

	reload = () => {
		this.setState({step: 'INIT'});
		this.context.API.messageSearch({seance_id: this.props.seanceId})
		.then(messages => {
			this.setState({
				step: 'REDUCED',
				messages
			});
		})
		.catch(e => {
			this.setState({step: 'ERROR'});
		})
	};
	send = (event) => {
		event.preventDefault();
		this.setState({
			step: 'SEND',
			txt: '',
			error: false
		});
	};

	reduce = (event, mustReload = false) => {
		event.preventDefault();
		!mustReload ? this.setState({step: 'REDUCED'}) : this.reload();
	};


	list = (event) => {
		event.preventDefault();
		this.setState({step: 'LISTING'});
	};


	render() {
		if (this.state.step === 'INIT')
			return <MsgPage type="LOADER"/>;

		if (this.state.step === 'ERROR')
			return <MsgPage type="ERROR"/>;

		if (this.state.step === 'REDUCED')
			return (
				<div className="AttendeesInteractions">
					<button onClick={this.list} className="message-bloc">
						Voir les messages
						<div className='message-button'>
							<img src={messageIcon} alt="Messages"/>
							<span>{this.state.messages.length < 10 ? this.state.messages.length : '9+'}</span>
						</div>
					</button>
					<button onClick={this.send}>Parler aux participants</button>
				</div>
			);

		if(this.state.step === 'SEND')
			return <AttendeeMessageSend seanceId = {this.props.seanceId} hide = {this.reduce} />;

		if(this.state.step === 'LISTING')
			return (
				<div className="AttendeesInteractions">
					<ol>
						{!this.state.messages.length && <li>Aucun message actuellement</li> }
						{this.state.messages.sort((a,b)=>b.creation.date - a.creation.date).map( m => {
							return (
								<li key = {m.id} style={{border:'1px solid gray'}}>
									<em>{moment(m.creation.date).format('[Envoyé le] YYYY-MM-DD [à] HH:mm')} par {m.creation.user.firstname} {m.creation.user.lastname}</em>
									<p style={{whiteSpace: 'pre'}}>{m.text}</p>
								</li>
							);
						})}
					</ol>
					<button onClick={this.reduce}>Fermer</button>
					<button onClick={this.send}>Parler aux participants</button>
				</div>
			);
	}
}

class AttendeeMessageSend extends Component {

	static contextTypes = {
		API: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = { step : 'FORM' };
	}

	onChange = (event) => {
		if(this.state.step !== 'FORM') return;
		this.setState({ text : event.target.value });
	};

	send = (event) => {
		event.preventDefault();
		if(this.state.step !== 'FORM') return;
		this.setState({ step : 'SENDING' });
		this.context.API.messageCreate({seance:{id:this.props.seanceId}, message:this.state.text})
		.then( () => {
			this.setState({ step : 'SUCCESS', error:false })
		})
		.catch( e => {
			this.setState({ step : 'FORM', error:true })
		})
		;
	};

	close = (event) => {
		this.props.hide(event, true);
	};

	render(){
		if (this.state.step === 'FORM' || this.state.step === 'SENDING'){
			let d = this.state.step !== 'FORM';

			return (
				<form className="CoachingMessageForm">
					<textarea disabled={d} placeholder='Votre message' value={this.state.text} onChange={this.onChange}/>
					<div>
						<button disabled={d} type='submit' onClick={this.send} className="coaching-message-send">Envoyer</button>
						<button disabled={d} onClick={this.props.hide} className="coaching-message-cancel">Annuler</button>
					</div>
					{this.state.error && <p>Une erreur est survenue</p>}
				</form>
			);
		}

		if( this.state.step === 'SUCCESS' ) {
			return (
				<div>
					<p>Votre message a bien été envoyé</p>
					<div style={{whiteSpace: 'pre'}}>{this.state.text}</div>
					<button  onClick={this.close}>Fermer</button>
				</div>
			);
		}
	}
}

const Booking = (props) => {
	let b = props.booking;
	let firstTimeEver = b.user.special.bookingDoneCount < 1;
	let userAvatar = b.user.avatar ? b.user.avatar.path : b.user.gender === 'F' ? defaultFemaleAvatar : defaultMaleAvatar;
	let level = b.user.level;
	switch (level) {
		case 'white' :
			level = 'RankOne';
			break;
		case 'green' :
			level = 'RankTwo';
			break;
		case 'black' :
			level = 'RankThird';
			break;
		default :
			level = '';
			break;
	}
	return (
		<article className='CoachingBooking' key={b.id}>
			<header>
				<Img className={level} src={userAvatar} alt={b.user.firstname} lazySrc='TODO'/>
				<div>
					<h3>
						<strong>{b.user.firstname} {b.user.lastname}</strong>
						{firstTimeEver &&
							<span>New</span>
						}
					</h3>
					{b.user.phone ?
						<a href={`tel:${b.user.phone}`}>{b.user.phone}</a>
						:
						<p>Tel non renseigné</p>
					}
					<p>Inscrit depuis {moment(b.user.date.creation).format("MMMM YYYY")}</p>
					<p>{b.user.special.bookingDoneCount} séance{b.user.special.bookingDoneCount > 1 ? "s" : ""} effectuée{b.user.special.bookingDoneCount > 1 ? "s" : ""}</p>
					<p>{b.user.special.moyen} séance{b.user.special.moyen > 1 ? "s" : ""} en moy / mois</p>
				</div>
			</header>
			<footer>
				<span>S'est présenté{b.user.gender === 'F' ? 'e' : ''} ?</span>
			{!b.presence.pending ?
				<div className="presence-selector">
					<button className={"present" + (b.presence.value === true ? ' active' : '')}
							onClick={e => b.presence.value !== true && props.changePresence(e, b.id, true)}
					>
						Oui
					</button>
					<button className={"absent" + (b.presence.value === false ? ' active' : '')}
							onClick={e => b.presence.value !== false && props.changePresence(e, b.id, false)}
					>
						Non
					</button>
				</div>
				:
				<p className='pending'>...</p>
			}
			{b.presence.error && <p className='error'>ERROR</p>}
			</footer>
		</article>
	);
};
