import React, {Component} from 'react';

import Img from "./utils/Img";
import presses from "./presses.js";

import './Press.scss';

class Press extends Component {

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<div className="Press">
				<h1>
					Presse
					<small>Ils parlent de nous</small>
				</h1>
				<ul>
					{presses.map((p, i) =>
					<li key={i}>
						<PressArticle press={p}/>
					</li>
					)}
				</ul>
			</div>
		);
	}

}

export default Press;

class PressArticle extends Component {
	render() {
		let {press} = this.props;
		return (
			<article className="PressArticle">
				<header>
					<Img src={press.logoUrl} alt={press.author} lazySrc="TODO"/>
				</header>
				<section>
					<blockquote>
						<p>{press.txt}</p>
						<footer>— <cite>{press.author}</cite></footer>
					</blockquote>
				</section>
			</article>
		);
	}
}