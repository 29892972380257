import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";

import API from './api/FunctionnalAPI';

import appIcon from "./images/app-logo.svg";

import { ReactComponent as TelIcon } from './icons/tel.svg';
import { ReactComponent as CameraIcon } from './icons/video.svg';
import { ReactComponent as RightChevronIcon } from './icons/chevron-right.svg';

import Img from "./utils/Img";
import MsgPage from "./utils/MsgPage";
import './PersonnalTeachers.scss';
import BasicSearch from "./utils/BasicSearch";

export default () => {
	const [state, update] = useState({step : "INIT", teacher: [] });
	const [searchValue, updateSearch] = useState('');
	const [showFilter, setViewFilter] = useState(false);

	const handleSearchChange = (e) => {
		updateSearch(e);
	};
	const SearchChange = (entry) => {
		const params = {};

		if (entry.length > 0)
			params.search = entry;

		return API.searchTeacher(params)
		.then(res => {
			update({ step: 'OK', teacher: res });
		})
		.catch(err => {
			if (err.statusCode === 404) return update({ step : 'EMPTY', teacher: [] });
			update({ step : 'ERROR', error: err, teacher: [] });
		});
	};
	const showFilters = () => {
		setViewFilter(!showFilter);
	};
	useEffect(() => {
		if (["OK", "ERROR", "EMPTY"].indexOf(state.step) >= 0) return ;

		API.searchTeacher()
		.then(res => {
			update({ step: 'OK', teacher: res });
		})
		.catch(err => {
			if (err.statusCode === 404) return update({ step : 'EMPTY', teacher: [] });
			update({ step : 'ERROR', error: err, teacher: [] });
		});
	}, [ state, searchValue ]);

	if (state.step === 'ERROR') {
		return (
			<MsgPage type="ERROR" title="Une erreur est survenue." error={ state.error }/>
		);
	}

	return (
		<div className="Teachers">

			<div className={"teacherActions"}>
				<button className='showSeanceFilters' onClick={showFilters}>Filtres</button>
			</div>
			<section className={"teacherActions"}>
				{showFilter &&
				<div className='TeacherFilter'>
					<BasicSearch
						name="Coach / Spécialité"
						autocomplete="off"
						search={SearchChange}
						onSelect={handleSearchChange}
						searchOffset="2" />
				</div>
				}
			</section>

			<div>
				<em>Les séances se font par téléphone ou en visio</em>
				<TelIcon />
				<CameraIcon />
			</div>

			{state.step === 'EMPTY' &&
			<Fragment>
				<ul className={'TeacherList'}>
					<MsgPage type={"EMPTY"} title={"Pas de coach correspondant disponible."}/>
				</ul>
			</Fragment>
			}
			{state.step === 'OK' &&
				<Fragment>
					<ul className={'TeacherList'}>
						{ state.teacher.length ?
							<TeacherList teachers={state.teacher} />
							:
							<MsgPage type={"EMPTY"} title={"Pas de coach disponible."}/>
						}
					</ul>
				</Fragment>
			}
		</div>
	);
};

const TeacherList = ({teachers}) => {
	return (
		<ul>
			{ teachers.map((t, i) => {
				return (<li key={i}>
					<TeacherPreview teacher={t} />
				</li>);
			}) }
		</ul>
	);
};

const TeacherPreview = ({ teacher }) => {
	return (
		<article className={"TeacherPreview"}>
			<header>
				{teacher.avatar ?
					<Img className={''} src={teacher.avatar || appIcon} alt="Avatar" lazySrc="todo"/> :
					<Img src={appIcon} alt="no avatar" lazySrc="todo" />
				}
			</header>
			<section>
				<div>
					<h3>{teacher.firstname}&nbsp;{teacher.lastname}</h3>
					<span>{teacher.speciality}</span>
					<span>{teacher.jobs}</span>
				</div>
			</section>
			<div>
				<RightChevronIcon />
			</div>
			<Link to={`/teacher/${teacher.id}`} aria-label={`Voir les cours ${teacher.firstname}`} className="link-to-teacher"/>
		</article>
	);
};
