import React, { useState, useEffect } from 'react';

import MsgPage from "./utils/MsgPage";
import MessageList from "./MessageList";
import Img from "./utils/Img";
import API from './api/FunctionnalAPI';

import './PersonnalTeacher.scss';
import coverImg from './images/corporate/corporate-cover.jpg';
import sendIcon from "./icons/send--light.svg";
import appIcon from "./images/app-logo.svg";

export default ({ match, ...props }) => {
	const [ state, update ] = useState({  step: 'INIT', teacher: null, messages: [] });
	const [ lesson, updateLesson ] = useState(null);
	const [ message, updateMsg ] = useState('');

	const goBack = () => {
		if (!lesson) props.history.goBack();
		else updateLesson(null);
	};
	const setLesson = (e) => {
		updateLesson(e.target.name);
		if (state.messages.length <= 0)
			updateMsg(`Bonjour ${state.teacher.user.firstname}, j'ai choisi la séance ${e.target.name} quand seriez vous disponible ? de mon côté voici mes préférences : `);
	};
	const handleChange = (e) => {
		updateMsg(e.target.value);
	};
	const sendMessage = () => {
		API.sendPersonnalMessage({
			teacher: { id: match.params.teacherId },
			recipient: { id: state.teacher.user.id },
			lesson: lesson,
			message: message
		})
		.then(() => {
			updateMsg("");
			update({ step: "INIT", teacher: null, messages: [] });
		})
		.catch(err => {
			console.log(err);
		});
	};
	const load = () => {
		let tmp = {};
		if (!!state.teacher || [ 'OK', 'ERROR' ].indexOf(state.step) >= 0) return;
		API.getTeacher(match.params.teacherId)
		.then(res => {
			tmp = res;
			return API.getPersonnalMessage({ teacher_id: match.params.teacherId });
		})
		.then(msg => {
			if (msg.length) updateLesson(msg[msg.length - 1].lesson);
			update({ step: 'OK', teacher: tmp, messages: msg });
		})
		.catch(err => {
			if (err.statusCode === 404) return update({ step : 'EMPTY', error: err, teacher: [] });
			update({ step: 'ERROR', error: err, teacher: null });
		});
	};
	useEffect( load, [ state, match ]);

	if (state.step === 'ERROR') {
		return (
			<MsgPage type="ERROR" title="Une erreur est survenue."/>
		);
	}
	if (state.step === 'LOADING' || state.step === 'INIT') {
		return (
			<MsgPage type="LOADER" title="Chargement en cours..."/>
		);
	}
	if (state.step === 'EMPTY') {
		return (
			<MsgPage type="ERROR" title={state.error.userMsg} />
		);
	}

	return (
		<div className="TeacherDisplay">
			<header>
				<button onClick={goBack}>Retour</button>
				<Img src={coverImg} lazySrc='TODO' alt="Workout"/>
				<h1>
					<Img src={state.teacher.avatar || appIcon} lazySrc='TODO' alt="TeacherAvatar"/>
					{state.teacher.user.firstname}&nbsp;{state.teacher.user.lastname}
					<small>{state.teacher.jobs} / {state.teacher.speciality}</small>
				</h1>
			</header>

			{!lesson && <aside>
				<div className="richText" dangerouslySetInnerHTML={{__html: state.teacher.description}}/>
			</aside>}

			{!lesson && <section>
				<h4>Choisissez votre séance</h4>
				<ul>
					{ !state.teacher.lesson.length && <li>
						<button className={"LessonButton"} disabled>Aucun cours disponible actuellement</button>
					</li>}
					{ !!state.teacher.lesson.length && state.teacher.lesson.map((l, i) => (
						<li key={i}>
							<button className={"LessonButton"} onClick={setLesson} name={l}>{l}</button>
						</li>
					))}
				</ul>
			</section>}

			{lesson && <section>
				<h4>Contactez votre coach bien-être</h4>
				<MessageList messages={state.messages} />
				<div className={"NewMessage"}>
					<textarea value={message} onChange={handleChange} onSubmit={sendMessage} />
					<button onClick={sendMessage} disabled={message === ''}>
						<img src={sendIcon} alt="Envoyer"/>
					</button>
				</div>
			</section>}
		</div>
	);
};
