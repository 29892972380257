import parcoursCombattant from './images/challenges/parcours-combattant.jpg';
import pilates from './images/challenges/pilates.jpg';
import courseOrientation from './images/challenges/course-orientation.jpg';
import vtt from './images/challenges/vtt.jpg';
import bootcamp from './images/challenges/bootcamp.jpg';
import commando from './images/challenges/mission-commando.jpg';

export default [{
	id: 1,
	name: "Parcours du Combattant",
	brief: "Tout militaire qui se respecte n’échappe pas à l’incontournable « parcours du combattant » lors de sa formation.\n" +
		"En tenue de camouflage, chaussé de vos rangers et de votre paquetage sur le dos, vous devrez effectuer un parcours semé d’obstacles variés : ramper, franchir un mur à bout de bras, traverser une rivière à l’aide de cordes, grimper sur une échelle flottante et ainsi de suite… en réalisant le meilleur chrono !",
	goalsTxt: "Développer l’esprit d’équipe et le dépassement de soi",
	goalsList: ["sensation", "originalité"],
	description: "En pleine nature, équipées de vêtements couvrant intégralement les bras et les jambes, les recrues franchiront ensemble une grande série d’obstacles à travers les bois et devront rester groupées pour se soutenir dans la difficulté.",
	minimumAttendees: 50,
	duration: .5,
	pics: [], //soon
	coverPic: parcoursCombattant,
	oldPath: "/fr/nos-challenges-parcours-du-combattant.html",
	newPath: "/challenge/1",
	metaDescription: "Développer l'esprit d'équipe et le dépassement de soi lors d'un parcours du combattant encadré par un commando très exigeant ! Urban Challenge, leader du coaching sportif en extérieur et en groupe, vous propose de découvrir un teambuilding très salissant : un parcours du combattant digne des plus grands films de guerre ! Alors n'ayez plus peur de vous salir..."
}, {
	id: 2,
	name: "Course d'Orientation",
	brief: "La course d’orientation, ancienne forme d’entraînement militaire qui a vu le jour chez les scandinaves est depuis plus d’un siècle une activité reconnue pour faire appel à des qualités physiques et intellectuelles. Quelle soit contre la montre, dans un temps limité ou par confrontation, la course promet d’être mémorable !",
	goalsTxt: "Solliciter votre capacité d’adaptation en associant la réflexion, le mental et la capacité physique. Le calme et la réactivité seront vos plus grands atouts pour être performant.",
	goalsList: ["solidarité", "réflexion"],
	description: "Munis d’une carte topographique et d’une boussole, vous participerez à une course contre la montre en pleine forêt, à la recherche de différentes balises positionnées par notre spécialiste Michel, champion de France de la discipline.",
	minimumAttendees: 30,
	duration: .5,
	pics: [],
	coverPic: courseOrientation,
	oldPath: "/fr/nos-challenges-course-d-orientation.html",
	newPath: "/challenge/2",
	metaDescription: "En équipe, munis d'une carte topographique et de votre boussole, vous participerez à une course contre la montre en pleine forêt, à la recherche de différentes balises. Solliciter votre capacité d'adaptation en associant la réflexion, le mental et la capacité physique. Urban Challenge, leader du coaching sportif en extérieur et en groupe, vous propose ce jeu de piste ou le calme et la réactivité seront vos plus grands atouts pour être performant. Un teambuilding animé par l'esprit d'équipe et qui fait appel à vos qualités physiques et intellectuelles."
}, {
	id: 3,
	name: "VTT",
	brief: "De l’enduro jusqu’au Cross-country, découvrez le Challenge VTT, un Team Building particulièrement ludique et intense le long d’un parcours sur mesure et préparé en amont.\n" +
		"Nos instructeurs vous feront passer par tous les endroits possibles et inimaginables ! Mais n’ayez crainte, vous aurez toutes les armes en main pour profiter d’une aisance totale quel que soit le terrain.",
	goalsTxt: "Intégrer les nouveaux membres de l’équipe et consolider le groupe.",
	goalsList: ["sport", "nature"],
	description: "Sur une demi-journée ou plus, faites preuve d’endurance et parcourez des dizaines de km à vélo en alternant routes, sentiers et chemins plus ou moins déjà arpentés.",
	minimumAttendees: 10,
	maximumAttendees: 25,
	duration: .5,
	pics: [],
	coverPic: vtt,
	oldPath: "/fr/nos-challenges-challenge-vtt.html",
	newPath: "/challenge/3",
	metaDescription: "enduro jusqu'au Cross-country, découvrez le Challenge VTT, un Team Building teambuilding   ludique et intense le long d'un parcours sur mesure et préparé en amont instructeurs vous feront passer par tous les endroits possibles et inimaginables  aisance totale quel que soit le terrain Intégrer les nouveaux membres de l'équipe et consolider le groupe endurance et parcourez des dizaines de km à vélo en alternant routes, sentiers et chemins plus ou moins déjà arpentés Urban Challenge leader du coaching sportif en extérieur et en groupe dépassement de soi esprit d'équipe cyclisme champion Tour de France roue col montée Alpes Pyrénées vélo"
}, {
	id: 4,
	name: "Pilates",
	brief: "La méthode Pilate consiste à travers une série d'exercices variés, à apprendre à activer les muscles faibles et à relâcher les muscles trop tendus dans le but d'équilibrer sa musculature. C'est une prise de conscience de son corps par le biais d’une gymnastique douce qui conviendra à tout type de public !",
	goalsTxt: "Se revitaliser et se détendre pour une reprise du travail en pleine forme.",
	goalsList: ["relaxation", "souplesse"],
	description: "En groupe, notre coach vous initiera aux enchainements fondamentaux du pilates. \n" +
		"Pour oublier le stress quotidien, vous serez invités à vous reconcentrer sur vous-même, sur votre respiration et sur votre esprit en laissant ainsi place à la sérénité.\n" +
		"Les effets du Pilates sont appréciables dès la première séance !",
	minimumAttendees: 10,
	duration: .5,
	pics: [],
	coverPic: pilates,
	oldPath: "/fr/nos-challenges-pilates.html",
	newPath: "/challenge/4",
	metaDescription: "En groupe, notre coach vous initiera aux enchainements fondamentaux du pilates. Pour oublier le stress quotidien, vous êtes invités à développer votre calme et votre sérénité lors du séance en extérieur. Urban Challenge, leader du coaching sportif en extérieur et en groupe, vous fait prendre conscience de votre corps par le biais d'une gymnastique douce. Un teambuilding doux, qui fait redescendre la pression du quotidien"
}, {
	id: 5,
	name: "BootCamp",
	brief: "Et c’est parti pour une séance de sport ! Oui mais pas comme les autres. Encadrés par un Commando ou un Sapeur-Pompier, vous vivrez pendant un moment l’intensité d’un entrainement militaire… que nos amis anglo-saxons appellent : le Boot Camp !\n" +
		"Mais pas de panique… tous les niveaux sont pris en compte ; le but étant de passer un bon moment !",
	goalsTxt: "Permettre aux participants de renforcer leurs facultés à travailler en équipe.",
	goalsList: ["dépassement", "solidarité"],
	description: "La séance de Team Building se déroule en 3 phases, l’échauffement, le corps de séance où l’on alterne course et divers exercices de renforcement musculaire, et la période de récupération (étirements et sophrologie). L’ensemble de votre corps sera sollicité à travers huit familles d’exercices, le plus souvent interactifs. ",
	minimumAttendees: 15,
	duration: .5,
	pics: [],
	coverPic: bootcamp,
	oldPath: "/fr/nos-challenges-le-boot-camp.html",
	newPath: "/challenge/5",
	metaDescription: "En groupe, encadrés par un Commando ou un Sapeur-Pompier, vous vivrez pendant un moment l'intensité d'un entrainement militaire… que nos amis anglo-saxons appellent le Boot Camp ! 3 phases le compose : l'échauffement, le corps de séance où l'on alterne course et divers exercices de renforcement musculaire, et la période de récupération (étirements et sophrologie). L'ensemble de votre corps sera sollicité à travers huit familles d'exercices. Urban Challenge, leader du coaching sportif en extérieur et en groupe, vous propose ce teambuilding basé sur des exercices ludiques et variés afin de développer l'esprit d'équipe ainsi que le dépassement de soi."
}, {
	id: 6,
	name: "Mission Commando",
	brief: "Se mettre dans la peau d’un commando le temps d’une mission spéciale dans un environnement inconnu et angoissant est un exercice parfait pour resserrer les liens de votre équipe !\n" +
		"La mission, si vous l’acceptez, pourra durer jusqu’à 24h au travers d’une multitude d’épreuves : initiation au camouflage, embuscade, self-défense, progression de nuit, bivouac, topographie ainsi qu’observations et renseignements.",
	goalsTxt: "Analyser une situation en équipe en faisant preuve de cohésion et d’ingéniosité.",
	goalsList: ["dépassement", "cohésion"],
	description: "Sur le terrain vous disposerez d’un temps imparti pour venir à bout de la mission qui sera confiée à votre équipe. En évoluant avec le matériel nécessaire, vous devrez faire preuve de sang-froid, d’esprit d’équipe et de résistance afin d’éviter les obstacles mis en place par l’ennemi pour vous repousser dans vos derniers retranchement.",
	minimumAttendees: 50,
	duration: 1,
	pics: [],
	coverPic: commando,
	oldPath: "/fr/nos-challenges-mission-commando.html",
	newPath: "/challenge/6",
	metaDescription: "Mission commando, Urban Challenge, leader du coaching sportif en extérieur et en groupe, dépassement de soi, esprit d'équipe, militaire, nitiation au camouflage, embuscade, self-défense, progression de nuit, bivouac, topographie ainsi qu'observations et renseignements, analyser une situation en équipe en faisant preuve de cohésion et d'ingéniosité, teambuilding"
}];
