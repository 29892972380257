export default (loader, repo, items) => {
	let ids = [...new Set(items.filter(e => e && e.__state === 'PARTIAL').map(e=>e.id))];

	if(!ids.length)
		return Promise.resolve();

	return loader(ids)
	.then( results => {
		return repo.put(results);
	})
	;
};
