import React, {Component} from 'react';
import Helmet from 'react-helmet';

import Img from "./utils/Img";
import partnerOffers from "./partnerOffers";

import './PartnerOfferList.scss';

class PartnerOfferList extends Component {

	componentDidMount() {
		window.scroll(0, 0);
	}

	render() {
		return (
			<div className="PartnerOfferList">
				<h1>
					Offres Partenaires
				</h1>
				<Helmet key='helmet'>
					{/*<meta name='description' content="TOFILL"/>*/}
				</Helmet>
				<ul>
					{partnerOffers.map((o, i) =>
						<li key={i}>
							<PartnerOfferArticle offer={o}/>
						</li>
					)}
				</ul>
			</div>
		);
	}
}

export default PartnerOfferList;

class PartnerOfferArticle extends Component {
	render() {
		let {offer} = this.props;
		return (
			<article className="PartnerOfferArticle">
				<header>
					<Img src={offer.logoUrl} alt={offer.partner} lazySrc="TODO"/>
					<div>
						-{offer.promo.value}{offer.promo.type === 'PERCENT' ? '%' : '€'}
					</div>
				</header>
				<section>
					<h3>{offer.partner}</h3>
					<p>{offer.txt}</p>
				</section>
			</article>
		);
	}
}