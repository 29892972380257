import React from 'react';

import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';

export default class SignInUpForgot extends React.Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			step : 'SIGN_IN'
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = "visible";
	}

	forgot = (event) => {
		event.preventDefault();
		this.setState({step:'FORGOT'});
	};

	signUp = (event) => {
		event.preventDefault();
		this.setState({step:'SIGN_UP'});
	};

	signIn = (event) => {
		event.preventDefault();
		this.setState({step:'SIGN_IN'});
	};

	render() {
		if(this.state.step === 'SIGN_IN') return <SignIn forgot = {this.forgot} signIn={this.signIn} signUp = {this.signUp}/>
		if(this.state.step === 'FORGOT')  return <ForgotPassword forgot = {this.forgot} signIn={this.signIn} signUp = {this.signUp}/>
		if(this.state.step === 'SIGN_UP') return <SignUp forgot = {this.forgot} signIn={this.signIn} signUp = {this.signOut}/>
	}
}
