import React, {Component, Fragment} from 'react';

import MsgPage from "./utils/MsgPage";

import './WebApp.scss';

import logo from './images/app-logo.svg';
import actionIcon from './icons/action.svg';
import miscIcon from './icons/more.svg';

class WebApp extends Component {

	constructor(props) {
		super(props);
		this.state = {
			step: 'LOADING'
		}
	}

	componentDidMount() {
		this.load();
		window.scroll(0, 0);
	}

	load = () => {
		this.setState({
			step: 'LOADING'
		});
		let mobileOS = this.getMobileOS();
		this.setState({
			step: 'OK',
			mobileOS
		})
	};

	getMobileOS = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/windows phone/i.test(userAgent)) {
			return "unknown";
		}
		if (/android/i.test(userAgent)) {
			return "Android";
		}
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}
		return "unknown";
	};

	render() {

		if (this.state.step === 'LOADING') {
			return (
				<MsgPage type='LOADER' title='Détection du système...'/>
			);
		}

		let {mobileOS} = this.state;

		return (
			<div className="WebApp">
				<h1>Application web</h1>
				<section>
					<img src={logo} alt="Urban Challenge app"/>
					<p>
						Installez l'application web sur votre page d'accueil pour un accès rapide où que vous soyez.
					</p>
					{(mobileOS === 'iOS' || mobileOS === 'unknown') &&
						<Fragment>
							<h2>Apple iOS</h2>
							<div>
								<p>
									Tapotez sur l'icone <img src={actionIcon} alt="Action"/> puis "Sur l'écran d'accueil"
								</p>
							</div>
						</Fragment>
					}
					{(mobileOS === 'Android' || mobileOS === 'unknown') &&
						<Fragment>
							<h2>Google Android</h2>
							<div>
								<p>
									Tapotez sur l'icone <img src={miscIcon} alt="Action"/> puis "Ajouter à l'écran d'accueil"
								</p>
							</div>
						</Fragment>
					}
				</section>
			</div>
		);
	}
}

export default WebApp;