import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import Image from "./utils/Img";

import './Enterprise.scss';

import hero from './images/corporate/corporate-cover.jpg';
import corporateLogo from "./images/uc-corporate--light.svg";
import closeIcon from "./icons/close--light.svg";
import cardioImg from "./images/corporate/cardio.jpg";
import renfoImg from "./images/corporate/renfo.jpg";
import zenImg from "./images/corporate/zen.jpg";
import yogaPic from "./images/corporate/zen.jpg";
import bootcampPic from "./images/corporate/bootcamp.jpg";
import combattantPic from "./images/corporate/combattant.jpg";
import orientationPic from "./images/corporate/orientation.jpg";
import confPic from "./images/corporate/conference.jpg";
import hermesLogo from "./images/corporate/hermes.svg";
import asoLogo from "./images/corporate/aso.svg";
import orangeLogo from "./images/corporate/orange.svg";
import garminLogo from "./images/corporate/garmin.svg";
import ipsenLogo from "./images/corporate/ipsen.svg";
import totalLogo from "./images/corporate/total.svg";
import generalElectricLogo from "./images/corporate/general-electric.svg";
import bnpParibasLogo from "./images/corporate/bnp-paribas.svg";
import airbusLogo from "./images/corporate/airbus.svg";
import lillyLogo from "./images/corporate/lilly.svg";
import vinciLogo from "./images/corporate/vinci.svg";
import societeGeneraleLogo from "./images/corporate/societe-generale.svg";
import indeedLogo from "./images/corporate/indeed.svg";
import groupamaLogo from "./images/corporate/groupama.svg";
import contact from "./images/contact.png";
import coachPic1 from "./images/corporate/coach-1.jpg";
import coachPic2 from "./images/corporate/coach-2.jpg";
import brushPattern1 from "./images/brush--green.svg";
import brushPattern2 from "./images/brush--pink.svg";
import brushPattern3 from "./images/brush--yellow.svg";

const coachingTypes = [{
	title: "Cardio",
	className: "cardio",
	image: cardioImg,
	courses: [
		"Bootcamp en extérieur",
		"Cardio-training",
		"Cardio-boxing",
		"Zumba",
		"Running",
		"Corde à sauter"
	]
}, {
	title: "Renfo",
	className: "renfo",
	image: renfoImg,
	courses: [
		"Pilates",
		"Abdos-fessiers",
		"Circuit-training",
		"Body sculpt",
		"Yoga ashtanga"
	]
}, {
	title: "Zen",
	className: "zen",
	image: zenImg,
	courses: [
		"Yoga Vinyasa",
		"Yin yoga",
		"Hatha yoga",
		"Méditation",
		"Qi Gong"
	]
}];
const teamBuildings = [{
	title: "Bootcamp",
	className: "bootcamp",
	image: bootcampPic,
	description: "Entrainement militaire intensif",
	duration: "2h",
	minimumAttendees: 15,
	price: 49
}, {
	title: "Parcours du combattant",
	className: "combattant",
	image: combattantPic,
	description: "Multitude d'épreuves à la \"Full Metal Jacket\"",
	duration: "Demi-journée",
	minimumAttendees: 25,
	price: 89
}, {
	title: "Course d'orientation",
	className: "orientation",
	image: orientationPic,
	description: "Partez en équipe à la recherche de balises, indices et défis ludiques",
	duration: "Demi-journée",
	minimumAttendees: 25,
	price: 89
}];
const referencesList = [{
	name: "Hermès",
	path: hermesLogo
}, {
	name: "A.S.O.",
	path: asoLogo
}, {
	name: "Orange",
	path: orangeLogo
}, {
	name: "Garmin",
	path: garminLogo
}, {
	name: "Ipsen",
	path: ipsenLogo
}, {
	name: "Total",
	path: totalLogo
}, {
	name: "General Electric",
	path: generalElectricLogo
}, {
	name: "BNP Paribas",
	path: bnpParibasLogo
}, {
	name: "Airbus",
	path: airbusLogo
}, {
	name: "Lilly",
	path: lillyLogo
}, {
	name: "Vinci",
	path: vinciLogo
}, {
	name: "Société Générale",
	path: societeGeneraleLogo
}, {
	name: "Indeed",
	path: indeedLogo
}, {
	name: "Groupama",
	path: groupamaLogo
}];

const contacts = [{
	pic: contact,
	linkedInLink: "https://www.linkedin.com/in/antoine-deschamps-b99809135",
}];

class Enterprise extends Component {

	componentDidMount() {
		window.scroll(0, 0);
		window.hbspt.forms.create({region: "na1",portalId: "9030020",formId: "69818ff9-5a1a-4ab5-a76e-f4bb8332babb", target:'#hb-form'});
	}

	render() {
		return (
			<div className="Enterprise">
				<Helmet>
					<title>Coaching & Team building en entreprise | Urban Challenge</title>
				</Helmet>
				<header>
					{/* <img src={hero} alt="Hero"/> */}
					<video playsInline autoPlay muted loop>
						<source src={`${process.env.PUBLIC_URL}/corporate.mp4`} type='video/mp4'/>
					</video>
					<div>
						<h1>
							<img src={corporateLogo} alt="UC Corporate"/>
							<small>
								Au service du<br/>
								Sport & Bien-être<br/>
								en entreprise
							</small>
						</h1>
						<a href="#contact">Contactez-nous</a>
					</div>
				</header>
				<section className="enterprise-marketing">
					<header>
						<h2>
							Qui sommes nous ?
						</h2>
						<p>
							Société créée en 2010, Urban Challenge est un des précurseurs en France
							du sport en entreprise et bénéficie d'un positionnement unique
						</p>
					</header>
					<ul className="enterprise-stats">
						<li>
							<strong>
								60<sup>%</sup>
							</strong>
							<p>
								des coachs sont<br/>
								d'anciens pompiers<br/>
								et militaires
							</p>
						</li>
						<li>
							<strong>
								400
							</strong>
							<p>
								cours grand public<br/>
								organisés chaque mois<br/>
								B2C
							</p>
						</li>
						<li>
							<strong>
								40
							</strong>
							<p>
								entreprises<br/>
								clientes<br/>
								B2B
							</p>
						</li>
					</ul>
					<p>
						Notre offre a été conçue pour s'adresser au plus grand nombre de collaborateurs,
						quel que soit leur niveau sportif, et repose sur quatre piliers :
					</p>
					<ul className="enterprise-pillars">
						<li>
							<strong>Cohésion</strong>
						</li>
						<li>
							<strong>Confiance</strong>
						</li>
						<li>
							<strong>Énergie</strong>
						</li>
						<li>
							<strong>Dépassement de soi</strong>
						</li>
					</ul>
				</section>
				<section className="enterprise-offers">
					<h2>Nos offres Sport & Bien-être</h2>
					<ul>
						<li>
							<header>
								<h3>
									Coachs en entreprise
								</h3>
								<p>
									Choisissez vos séances hebdomadaires dans vos locaux ou à l'extérieur.
								</p>
							</header>
							<p>
								Bootcamp, yoga, pilates, running, cardio-boxing, corde à sauter, abdos-fessiers,
								méditation...
							</p>
						</li>
						<li>
							<header>
								<h3>
									Ateliers
								</h3>
								<p>
									Organisez l'année autour de quelques temps forts.
								</p>
							</header>
							<p>
								<strong>Ateliers Bien-être</strong>
								<span>Initiation à la sophrologie, ateliers huiles essentielles, méditation, nutrition...</span>
							</p>
							<p>
								<strong>Ateliers "TOP"</strong>
								<span>Gestion du stress, sommeil & récupération, gestion des conflits, préparation avant un challenge...</span>
							</p>
						</li>
						<li>
							<header>
								<h3>
									Team buildings
								</h3>
								<p>
									Soudez vos équipes avec les challenges sportifs – tous niveaux possibles.
								</p>
							</header>
							<p>
								Parcours du combattant, bootcamp, course d'orientation
							</p>
						</li>
						<li>
							<header>
								<h3>
									Pass CSE
								</h3>
								<p>
									Proposez à vos collaborateurs nos abonnements à tarifs privilégiés sur tous les cours Grand Public Urban Challenge.
								</p>
							</header>
							<p>
								400+ par mois à Paris<br/>
								Voir les prochains cours <a href="/seance">ici</a>
							</p>
						</li>
					</ul>
					<Link to='/contact' className='cta'>Découvrir l'offre</Link>
				</section>
				<section className="enterprise-coaching">
					<header>
						<h2>Coaching sportif</h2>
						<p>Nos cours en entreprise ou à l'extérieur</p>
					</header>
					<ul>
						{coachingTypes.map((ct, i) =>
						<li key={`coachingType_${i}`}>
							<DisplayCoaching coachingType={ct}/>
						</li>
						)}
					</ul>
				</section>
				<section className="enterprise-workshops">
					<h2>Nos ateliers</h2>
					<div>
						<figure>
							<img src={yogaPic} alt="Bien-être"/>
						</figure>
						<div>
							<h3>Ateliers bien-être</h3>
							<ul>
								<li>
									<strong>Atelier auto-massage</strong>
									<span>20min</span>
								</li>
								<li>
									<strong>Gestes & postures</strong>
									<span>1h</span>
								</li>
								<li>
									<strong>Initiation au yoga</strong>
									<span>1h</span>
								</li>
								<li>
									<strong>Atelier sophrologie</strong>
									<span>1h30</span>
								</li>
								<li>
									<strong>Initiation à la méditation</strong>
									<span>30min</span>
								</li>
								<li>
									<strong>Atelier huiles essentielles par saison</strong>
									<span>1h</span>
								</li>
								<li>
									<strong>Relaxation</strong>
									<span>30min</span>
								</li>
								<li>
									<strong>Atelier "manger en pleine conscience"</strong>
									<span>1h</span>
								</li>
							</ul>
							<p>
								Ateliers proposés en petit groupe de 10 à 20 participants
							</p>
						</div>
					</div>
					<Link style={{margin:'0 auto'}} to='/contact' className='cta'>Parler avec un expert</Link>
				</section>
				<section className="enterprise-team-building">
					<header>
						<h2>Team buildings</h2>
					</header>
					<ul>
						{teamBuildings.map((ct, i) =>
							<li key={`teamBuilding_${i}`}>
								<DisplayCoaching coachingType={ct}/>
							</li>
						)}
					</ul>
				</section>
				<section className="enterprise-references">
					<h2>Ils nous font confiance</h2>
					<ul>
						{referencesList.map((r, i) =>
						<li key={`ref_${i}`}>
							<img src={r.path} alt={r.name}/>
						</li>
						)}
					</ul>
				</section>
				<section className="enterprise-contact" id="contact">
					<h2>Vous êtes une entreprise?</h2>
					<ul>
						{contacts.map((c, i) =>
						<li key={`contact_${i}`}>
							<p style={{textAlign:'center'}}>Vous avez une idée ou même un projet sport et bien-être pour prendre soin de vos salariés ? Notre équipe reprendra contact avec vous très rapidement.</p>
						</li>
						)}
					</ul>
				</section>
				<section id='hb-form'></section>
			</div>
		);
	}
}

class DisplayCoaching extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	toggleModal = () => {
		let body = document.getElementsByTagName('body')[0];
		body.style.overflow = (this.state.showModal ? "visible" : "hidden");
		this.setState({showModal: !this.state.showModal});
	};

	render() {
		const {coachingType} = this.props;

		return (
			<>
				<article>
					<Image src={coachingType.image} alt="Cardio"/>
					<div className={coachingType.className} onClick={this.toggleModal}>
						<strong>{coachingType.title}</strong>
					</div>
				</article>
				<div className={"modal-mask" + (this.state.showModal ? " active" : "")}/>
				<aside open={this.state.showModal} className={this.state.showModal ? "active" : ""}>
					<div>
						<h3>{coachingType.title}</h3>
						{coachingType.description &&
						<p>{coachingType.description}</p>
						}
						{coachingType.duration &&
						<strong>{coachingType.duration}</strong>
						}
						{coachingType.minimumAttendees &&
						<span>min. {coachingType.minimumAttendees} participants</span>
						}
						{coachingType.courses && !!coachingType.courses.length &&
						<>
							<ul>
								{coachingType.courses.map((c, i) =>
									<li key={i}>{c}</li>
								)}
							</ul>
							<p>Et de nombreuses activités à discuter ensemble...</p>
						</>
						}
						{/*{coachingType.price &&*/}
						{/*<p>*/}
						{/*	<span>à partir de</span>*/}
						{/*	<strong>{coachingType.price}€ HT</strong>*/}
						{/*	<span>par participant</span>*/}
						{/*</p>*/}
						{/*}*/}
					</div>
					<img src={coachingType.image} alt={coachingType.title}/>
					<button type="button" onClick={this.toggleModal}>
						<img src={closeIcon} alt="X"/>
					</button>
				</aside>
			</>
		);
	}
}

export default Enterprise;
