import React, {Component} from 'react';

import PersonnalTeachers from "./PersonnalTeachers";

import './SearchTeacherPage.scss';

class SearchTeacherPage extends Component {
	render = () => {
		return (
			<div className="SearchTeacherPage">
				<PersonnalTeachers/>
			</div>
		);
	};
}

export default SearchTeacherPage;
